@import "module/scanintro";

.camera_invoer-index-index {
    #cart-products {
        padding: 0;
    }

    .scandit .scandit-logo {
        top: 5%;
        right: auto;
        left: 50%;
        max-width: 35%;
        max-height: 12.5%;
        transform: translateX(-50%) !important;
        bottom: auto;
    }

    .product-page {
        .product-back-button {
            width: 97px;
            height: 97px;
            background: #EFF0F4;
            border-radius: 50%;
            left: -34px;
            top: -34px;
            border: 0;
            font-size: 0;

            svg {
                position: absolute;
                top: 47px;
                right: 25px;
            }

        }

        img {
            width: calc(100vw - 40px);
            margin-left: 20px;
            margin-top: 20px;
        }

        h2.product-name {
            font-size: 20px;
            text-transform: uppercase;
            font-weight: 900;
            margin-top: 100px;
            text-align: center;
            width: 100%;
            padding: 0 20px;
            box-sizing: border-box;
        }

        .add-to-cart-button {
            background: $primary__color__darker;
            color: #fff;
            border-radius: 90px;
            position: absolute;
            right: 20px;
            bottom: 16px;
            width: calc(100% - 162px);
            height: 50px;
            border: 0;
            font-size: 15px;
            text-transform: uppercase;
            font-weight: 900;
        }

        .qty-input-container {
            width: 102px;
            height: 50px;
            border: 1px solid $primary__color__darker;
            position: absolute;
            left: 20px;
            bottom: 16px;
            border-radius: 90px;
            background: #fff;
            box-sizing: border-box;

            .minus-button {
                width: 30px;
                height: 100%;
                border: 0;
                background: none;
                padding: 0;

                span {
                    width: 8px;
                    height: 2px;
                    display: block;
                    background: $primary__color__darker;
                    position: relative;
                    margin-top: 1px;
                    left: 50%;
                    transform: translateX(-50%);
                }
            }

            input {
                width: calc(100% - 60px);
                border: 0;
                height: 100%;
                left: 30px;
                font-size: 18px;
                padding: 0;
                text-align: center;
                font-weight: 900;
            }

            .plus-button {
                width: 30px;
                height: 100%;
                border: 0;
                background: none;
                padding: 0;
                left: auto;
                right: 0;
                font-weight: 800;
                font-size: 15px;
                color: $primary__color__darker;
            }
        }
    }


    .cart-page {
        position: absolute;
        left: 50%;
        width: 25%;
        height: 100%;
        z-index: 21;
        background: #fff;
        overflow: hidden;

        .totals {
            margin-bottom: 180px;
            text-align: center;
        }

        .scanner-cart-products tbody tr {
            display: grid;
            grid-template-columns: 106px 60px 86px 60px calc(100% - 312px);
            grid-template-rows: 0.57fr 24px 30px;

            @media only screen and (max-width: 359px) {
                grid-template-columns: 106px 60px 50px 60px calc(100% - 286px);
            }

            @media only screen and (max-width: 330px) {
                grid-template-columns: 90px 60px 50px 60px calc(100% - 260px);
            }


            margin-bottom: 10px;
            padding: 15px 15px;

            &:nth-child(odd) {
                background: #f0f1f5;
            }

            td {
                padding: 0;
                display: block;
                grid-column-start: 2;
                grid-row-start: 1;
                grid-row-end: 1;

                font-size: 13px;
                font-weight: 400;
                color: $primary__color__darker;

                .name {
                    font-weight: 600;
                    height: 18px;
                    line-height: 18px;
                    overflow: hidden;
                }

                &:nth-child(3) {
                    grid-row-start: 1;
                    grid-row-end: 1;
                    grid-column-start: 2;
                    grid-column-end: 6;
                }

                &.qty {
                    grid-row-start: 3;
                    grid-row-end: 3;
                    grid-column-start: 3;
                    grid-column-end: 3;
                    text-align: center;
                    line-height: 30px;

                    &.highlight {
                        color: $primary__color;
                    }

                }

                &:nth-child(5) {
                    grid-row-start: 3;
                    grid-row-end: 3;
                    grid-column-start: 2;
                    grid-column-end: 2;
                }

                &:nth-child(5),
                &:nth-child(6) {
                    button {
                        width: 60px;
                        height: 30px;
                        background: $primary__color__darker;
                        border-radius: 90px;
                        font-size: 13px;
                        color: #fff;
                        border: 0;
                    }
                }

                &:nth-child(6) {
                    grid-row-start: 3;
                    grid-row-end: 3;
                    grid-column-start: 4;
                    grid-column-end: 4;
                }

                &:nth-child(7) {
                    grid-row-start: 3;
                    grid-row-end: 3;
                    grid-column-start: 5;
                    grid-column-end: 5;
                    text-align: right;

                    button {
                        border: 2px solid #D04545;
                        border-radius: 50%;
                        width: 30px;
                        height: 30px;
                        color: #D04545;
                        font-size: 14px;
                        background: none;
                        font-weight: 900;
                        padding: 0;
                        margin-left: 7px;
                        margin-right: 7px;
                    }
                }

                &.sku {
                    grid-row-start: 2;
                    grid-row-end: 3;
                    grid-column-start: 2;
                    grid-column-end: 6;

                    &:before {
                        content: 'SKU: ';
                        font-weight: 600;
                    }
                }

                &.image {
                    grid-column-start: 1;
                    grid-row-start: 1;
                    grid-row-end: 3;

                    img {
                        width: 95px;
                        border: 1px solid $primary__color__darker;
                        border-radius: 5px;

                        @media only screen and (max-width: 359px) {
                            width: 75px;
                        }
                    }
                }
            }
        }

        h2 {
            font-size: 32px;
            text-transform: uppercase;
            font-weight: 900;
            margin: 15px 0 30px 92px;
        }

        .cart-back-button {
            width: 97px;
            height: 97px;
            background: #EFF0F4;
            border-radius: 50%;
            left: -34px;
            top: -34px;
            border: 0;
            font-size: 0;

            svg {
                position: absolute;
                top: 47px;
                right: 25px;
            }
        }

        .scrollable-cart {
            height: calc(100vh - 80px);
            overflow: auto;
        }
    }

    .settings-page {
        position: absolute;
        left: 0;
        width: 25%;
        height: 100%;
        z-index: 21;
        background: #fff;
        overflow: hidden;

        .logout-button {
            position: absolute;
            border: 0;
            background: none;
            color: $primary__color__darker;
            font-size: 20px;
            padding: 0;
            bottom: 20px;
            left: 15px;
            line-height: 40px;
            font-weight: 400;

            svg {
                position: relative;
                top: 2px;
            }
        }

        h2 {
            font-size: 32px;
            text-transform: uppercase;
            font-weight: 900;
            margin: 15px 0 30px 15px;
        }

        .settings-back-button {
            width: 97px;
            height: 97px;
            background: #EFF0F4;
            border-radius: 50%;
            right: -34px;
            top: -34px;
            border: 0;

            svg {
                position: absolute;
                top: 47px;
                left: 25px;
            }
        }

        label {
            height: 66px;
            width: 100%;
            display: block;
            border-bottom: 1px solid #F0F1F5;
            line-height: 66px;
            box-sizing: border-box;
            padding: 0 15px;

            input {
                height: 0;
                width: 0;
                position: absolute;
                overflow: hidden;
                visibility: hidden;
                opacity: 0;
            }

            span {
                width: 46px;
                height: 26px;
                display: block;
                background: #EFF0F4;
                position: relative;
                border-radius: 90px;
                float: left;
                top: 19px;
                margin-right: 10px;
                transition: background 0.2s ease-out;

                &:before {
                    background: #fff;
                    content: '';
                    position: absolute;
                    left: 2px;
                    top: 2px;
                    height: 22px;
                    width: 22px;
                    border-radius: 50px;
                    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.16);
                    transition: left 0.2s ease-out;
                }
            }

            input:checked ~ span {
                background: rgba(75, 215, 98, 1);

                &:before {
                    left: 22px;
                }
            }

            &.quickscan-checkbox {
                margin-top: 30px;
                border-top: 1px solid #F0F1F5;
            }
        }

    }


    .page-main {
        padding: 0;
        width: 100%;
        height: 100% ;
        max-width: none;
    }

    .block-customer-mini-login {
        margin: 0 !important;
        top: 60%;
        z-index: 1;
        position: fixed;
        padding: 0;
        width: calc(100% - 40px);
        left: 50%;
        box-sizing: border-box;
        transform: translate(-50%, -50%);
        max-width: 450px;

        .forgot-links {
            margin-top: 30px;
            text-align: center;
            a span{
                font-size: 15px;
                font-weight: normal;
                text-decoration: underline;
            }

            div {
                font-size: 15px;
                font-weight: normal;
                margin: 20px 0;
            }

            a.remind:after {
                content: none;
            }
        }


        .actions-toolbar {
            float: none;
            margin: 0;

            button {
                height: 50px;
                border-radius: 90px;
                width: 100%;
                background: $primary__color__darker;
                border: 2px solid $primary__color__darker;

                svg {
                    position: relative;
                    top: 2px;
                }

                span {
                    font-size: 15px;
                    font-weight: 900;
                    text-transform: uppercase;
                    color: #fff;
                    margin-left: 5px;
                }


                &:before,
                &:after {
                    content: none;
                }
            }
        }

        .custom-login-title {
            font-size: 38px;
            text-transform: uppercase;
            font-weight: 900;
            margin-bottom: 10px;
        }

        .fieldset > .field {
            &.email,
            &.password {
                width: 100%;
                margin-right: 0;
                border-bottom: 3px solid $primary__color__darker;
                margin-bottom: 20px;

                input {
                    background: #fff;
                    border: 0;
                    width: 100%;
                    height: 50px;

                    &:focus {
                        box-shadow: none;
                    }

                    &:-webkit-autofill {
                        background-color: #fff !important;
                    }
                }

                .control {
                    position: relative;

                    .input-label {
                        position: absolute;
                        left: 0;
                        top: 50%;
                        transform: translateY(-50%);
                        pointer-events: none;
                        background: #fff;
                        font-size: 18px;
                        transition: all 0.2s ease-out;
                    }

                    input.focused ~ .input-label,
                    input:focus ~ .input-label  {
                        left: 20px;
                        top: 0;
                    }
                }
            }
        }
    }



    .settings-button {
        width: 82px;
        height: 82px;
        background: #EFF0F4;
        border-radius: 50%;
        left: -22px;
        bottom: -25px;
        border: 0;

        svg {
            position: absolute;
            top: 16px;
            right: 20px;
        }
    }

    .cart-button {
        width: 120px;
        height: 120px;
        background: #EFF0F4;
        border-radius: 50%;
        right: -20px;
        bottom: -35px;
        border: 0;

        svg {
            position: absolute;
            top: 29px;
            left: 32px;
        }
    }

    .left-handed {
        .cart-button {
            right: auto;
            left: -20px;

            svg {
                left: 40px;
            }
        }

        .settings-button {
            left: auto;
            right: -20px;

            svg {
                right: 28px;
            }
        }
    }

    .back-in-stock {
        text-decoration: underline;

        @media only screen and (max-width: 369px) {
            font-size: 11px;
        }
    }


    .back-in-stock-panel {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 150px;
        z-index: -1;

        > div {
            width: 100%;
            text-align: center;
            color: #e48915;
            font-size: 20px;
            text-transform: uppercase;
            font-weight: 900;
        }

        button {
            background: $primary__color__darker;
            color: #fff;
            border-radius: 90px;
            position: absolute;
            right: 20px;
            bottom: 16px;
            width: calc(100% - 40px);
            height: 50px;
            border: 0;
            font-size: 15px;
            text-transform: uppercase;
            font-weight: 900;
        }
    }


}

.login-logo {
    width: 188px;
    position: fixed;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);

    @media only screen and (min-width: 769px) {
        width: 320px;
    }
}

.login-bottom {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 150px;
    z-index: -1;

    &.settings {
        position: absolute;
    }

    &.overlay {
        background: #fff;
        position: absolute;
        overflow: hidden;
        height: 80px;
        z-index: 1;

        > svg {
            width: 100%;
            height: 150px;
            bottom: 0;
            position: absolute;
        }

        a.home-button {
            width: calc(40vw - 30px);
            position: absolute;
            left: 20px;
            border: 2px solid $primary__color__darker;
            box-sizing: border-box;
            border-radius: 90px;
            color: $primary__color__darker;
            height: 50px;
            line-height: 50px;
            display: block;
            bottom: 20px;
            font-size: 15px;
            font-weight: 900;
            padding: 0;
            text-transform: uppercase;
            text-decoration: none;
            text-align: center;

            svg {
                position: relative;
                top: 2px;
                margin-right: 5px;

                @media only screen and (max-width: 359px) {
                    display: none;
                }
            }
        }

        button {
            height: 50px;
            line-height: 50px;
            background: $primary__color__darker;
            border: 2px solid $primary__color__darker;
            box-sizing: border-box;
            border-radius: 90px;
            width: calc(60vw - 30px);
            display: block;
            position: absolute;
            right: 20px;
            bottom: 20px;
            font-size: 15px;
            font-weight: 900;
            color: #fff;
            padding: 0;
            text-transform: uppercase;

            svg {
                position: relative;
                top: 2px;
                margin-right: 5px;

                @media only screen and (max-width: 359px) {
                    display: none;
                }
            }

        }
    }

    > svg {
        width: 100%;
        height: 150px;
    }
}

.login-top {
    position: relative;
    width: 100%;
    margin-left: 0;
    height: 305px;
    overflow: hidden;

    @media only screen and (min-width: 768px) {
        height: 450px;
    }

    img {
        display: block;
        min-width: 100%;
        position: relative;
        top: 50%;
        left: 50%;
        min-height: 100%;
        transform: translate(-50%, -50%);

        @media only screen and (max-width: 620px) {
            width: auto;
            min-width: 0;
            min-height: 0;
            height: 100%;
            max-width: none;
        }
    }
    svg {
        width: 100%;
        height: 150px;
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
    }
}
.camera_invoer-index-index .scandit .scandit-camera-switcher {
    left: 10%;
}

.scandit.scandit-barcode-picker:after {
    content: "";
    display: block;
    position: absolute;
    top: 20px;
    left: 20px;
    width: calc(100vw - 40px);
    height: calc(100% - 40px);
    border: 2px solid #fff;
    border-radius: 10px;
}

@media only screen and (min-width: 769px) {
    .header.content .header-icon {
        display: none;
    }
}

@media only screen and (max-width: 768px) {
    .header.content .header-icon {
        position: absolute;
        right: 70px;
        display: block;
        width: 40px;
        top: 20px;
        height: 40px;

        svg {
            width: 100%;
            height: auto;
        }
    }
}

@media only screen and (max-width: 550px) {
    .header.content .logo {
        transform: none;
        margin: 0 0 0 14%;
        width: 180px;
    }
}

@media only screen and (max-width: 360px) {
    .header.content .logo {
        margin: 4px 0 0 16%;
        width: 150px;
    }
}

@media only screen and (max-width: 330px) {
    .header.content .logo {
        margin: 4px 0 0 16%;
        width: 135px;
    }
}
