.domain-switcher-overlay {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: transparentize($primary__color__darkest, 0.3);
    z-index: 10000;
}

.domain-switcher-popup {
    position: fixed;
    display: block;
    top: 50%;
    left: 50%;
    border: 1px solid #fff;
    transform: translate(-50%, -50%);
    background-color: #ffffff;
    padding: 20px;
    width: 400px;
    z-index: 10001;
}

.domain-switcher-close {
    position: absolute;
    display: block;
    border: 1px solid $primary__color;
    border-radius: 50%;
    padding: 5px;
    height: 30px;
    width: 30px;
    text-align: center;
    font-size: 0;
    right: -45px;
    top: -45px;
    cursor: pointer;
}
.domain-switcher-close:hover {
    background: transparentize($primary__color__darker, 0.6);
    border-color: #fff;
}

.domain-switcher-close:hover:before {
    color: #fff;
}

.domain-switcher-close:before {
    content: "x";
    position: relative;
    left: -1px;
    color: $primary__color;
    line-height: 24px;
    font-size: 28px;
}

.domain-switcher-preferred {
    background-color: $primary__color__lighter;
}

.domain-switcher-popup ul {
    padding: 0;
}
.domain-switcher-popup ul li {
    list-style: none;
    border: 1px solid $primary__color__lighter;
    margin: 3px;
    padding: 0 0 2px 5px;
}

.domain-switcher-popup ul li a {
    display: inline-block;
    width: 350px;
    color: $primary__color__darker;
    line-height: 24px;
}


.domain-switcher-header {
    margin-bottom: 15px;
    font-family: $font-family-name__base;
    // text-transform: uppercase;
    font-size: 18px;
    text-align: center;

    // font-weight: 900;
    color: $primary__color__darker;
}


.domain-switcher-inactive {
    display: none !important;
}