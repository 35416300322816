$toolbar-mode-icon-font-size: 24px;
$toolbar-element-background : $panel__background-color;

.page-products {
    .columns {
        position: relative;
        z-index: 1;
    }
}

.toolbar {
    @extend .abs-add-clearfix;
}

.toolbar-amount {
    display: block;
    line-height: $toolbar-mode-icon-font-size + 2;
    margin: 0;
    padding: 8px 0 0;

    .products.wrapper ~ .toolbar & {
        display: none;
    }
}

.toolbar-products {
    @extend .abs-add-clearfix;
    padding: 0;
    text-align: center;
    float: right;


    .pages {
        display: none;

        .products.wrapper ~ & {
            display: block;
        }
    }

    .limiter {
        display: none;

        .control {
            display: inline-block;
        }
    }
}

.sorter {
    padding: 5px 0 10px;

    .products.wrapper ~ .toolbar & {
        display: none;
    }
}

.sorter-label {
  display: block;
  text-align: left;
  @extend .debondt-title;
  font-size: 16px;
  font-weight: 900;
  margin-bottom: 5px;
  padding-bottom:5px;
}

.sorter-container {
  position: relative;
  overflow: hidden;
  background: #fff;
  border-radius: 3px;
  border: 1px solid $primary__color;

  .sorter-options {
      margin: 0;
      width: auto;
      border: 0px;
      background: transparent;
      font-size: 14px;
      padding-right: 20px;
      line-height: 36px;
      height: 36px;
      color: $primary__color;
      cursor: pointer;
      width: 130%;
  }

  &:after {
    content: "\e5cf";
    font-family: debondt;
    position: absolute;
    right: 5px;
    line-height: 36px;
    pointer-events: none;
    top: 0;
    font-size: 22px;
  }
}


.sorter-action {
    vertical-align: top;
    @include lib-icon-font(
        $icon-arrow-up,
        $_icon-font-size       : 28px,
        $_icon-font-line-height: 32px,
        $_icon-font-color      : $header-icons-color,
        $_icon-font-color-hover: $header-icons-color-hover,
        $_icon-font-text-hide  : true
    );
}

.sorter.sort-desc {
    &:before {
        content: $icon-arrow-down;
    }
}

.modes {
    display: none;
}

.limiter-options {
    margin: 0 5px 0 7px;
    width: auto;
}

.limiter-label {
    font-weight: 400;
}

.limiter {
    .page-products .toolbar & {
        display: none;
    }
}

//
//  Desktop
//  _____________________________________________
@include min-screen(930px) {
  div.modes {
    display: block;
    float: left;
    margin-right: $indent__base;
    margin-right: 10px;
    margin-top: 45px;
    position:relative;
  }
}


@include min-screen(769px) {
    .page-products {
        .columns {
            padding-top: 0;
            position: relative;
            z-index: 1;
        }
    }

    .toolbar {
        .products.wrapper ~ & .pages {
            float: right;
            margin-bottom: 20px;
        }
    }

    .toolbar-amount {
        float: left;
    }

    .sorter {
        float: right;
        margin-top: 5px;
        margin-bottom: -4px;
    }

    .modes {
      display: block;
      margin-bottom:10px;

        .products.wrapper ~ .toolbar & {
            display: none;
        }

        &:after {
          content: "";
          display: table;
          clear: both;
        }
    }

    .modes-mode {
        color: black;
        border: 1px solid $primary__color;
        border-right: 0;
        float: left;
        font-weight: $font-weight__regular;
        line-height: 0.90;
        padding: 7px 10px;
        text-align: center;
        background: #fff;
        @include lib-icon-font(
            $icon-grid,
            $_icon-font-size       : $toolbar-mode-icon-font-size,
            $_icon-font-text-hide  : true,
            $_icon-font-color      : $primary__color,
            $_icon-font-color-hover: $primary__color,
            $_icon-font-display    : block
        );

        &:not(.active) {
            &:hover {
                color: black;
            }
        }

        &:nth-child(2) {
            border-top-left-radius: 3px;
            border-bottom-left-radius: 3px;
        }

        &:last-child {
            border-right: 1px solid $primary__color;
            border-radius: 0 3px 3px 0;
        }

        &.active {
            color: #fff;
            background: $tertiary__color;

            &:before {
                color: #fff;
            }
        }
    }

    .mode-list {
        @include lib-icon-font-symbol($icon-list);
    }

    .limiter {
        float: right;

        .products.wrapper ~ .toolbar & {
            display: block;
        }
    }
}
