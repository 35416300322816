//
//  Indents
//  ---------------------------------------------

$indent__base: 20px;
$indent__xl  : 40px;
$indent__l   : 30px;
$indent__m   : 25px;
$indent__s   : 10px;
$indent__xs  : 5px;
$indent__auto: auto;

//
//  Icons
//  ---------------------------------------------
$icons__font-name: 'icons-blank-theme';
