/*!
 *
 *  Project:  GLYPHICONS
 *  Author:   Jan Kovarik - www.glyphicons.com
 *  Twitter:  @jankovarik
 *
 */
html,
html .halflings {
  -webkit-font-smoothing: antialiased !important;
}
//@font-face {
//  font-family: 'Glyphicons';
//  src: url('../fonts/glyphicons-regular.eot');
//  src: url('../fonts/glyphicons-regular.eot?#iefix') format('embedded-opentype'), url('../fonts/glyphicons-regular.woff') format('woff'), url('../fonts/glyphicons-regular.ttf') format('truetype'), url('../fonts/glyphicons-regular.svg#glyphicons_halflingsregular') format('svg');
//  font-weight: normal;
//  font-style: normal;
//}

@include lib-font-face(
        $family-name: 'Glyphicons',
        $font-path  : '../fonts/glyphicons/glyphicons-regular',
        $font-weight: normal,
        $font-style : normal
);

i.glyphicons {
  display: inline-block;
  position: relative;
  padding: 0;
  color: #1d1d1b;
  width: 16px;
  height: 16px;
  text-decoration: none;
  *display: inline;
  *zoom: 1;
}
i.glyphicons:before {
  color: #1D1D1B;
  font: 16px 'Glyphicons';
  left: 0;
  position: absolute;
  top: 0;
}
i.glyphicons.glass:before {
  content: "\e001";
}
i.glyphicons.leaf:before {
  content: "\e002";
}
i.glyphicons.dog:before {
  content: "\e003";
}
i.glyphicons.user:before {
  content: "\e004";
}
i.glyphicons.girl:before {
  content: "\e005";
}
i.glyphicons.car:before {
  content: "\e006";
}
i.glyphicons.user_add:before {
  content: "\e007";
}
i.glyphicons.user_remove:before {
  content: "\e008";
}
i.glyphicons.film:before {
  content: "\e009";
}
i.glyphicons.magic:before {
  content: "\e010";
}
i.glyphicons.envelope:before {
  content: "\2709";
}
i.glyphicons.camera:before {
  content: "\e012";
}
i.glyphicons.heart:before {
  content: "\e013";
}
i.glyphicons.beach_umbrella:before {
  content: "\e014";
}
i.glyphicons.train:before {
  content: "\e015";
}
i.glyphicons.print:before {
  content: "\e016";
}
i.glyphicons.bin:before {
  content: "\e017";
}
i.glyphicons.music:before {
  content: "\e018";
}
i.glyphicons.note:before {
  content: "\e019";
}
i.glyphicons.heart_empty:before {
  content: "\e020";
}
i.glyphicons.home:before {
  content: "\e021";
}
i.glyphicons.snowflake:before {
  content: "\2744";
}
i.glyphicons.fire:before {
  content: "\e023";
}
i.glyphicons.magnet:before {
  content: "\e024";
}
i.glyphicons.parents:before {
  content: "\e025";
}
i.glyphicons.binoculars:before {
  content: "\e026";
}
i.glyphicons.road:before {
  content: "\e027";
}
i.glyphicons.search:before {
  content: "\e028";
}
i.glyphicons.cars:before {
  content: "\e029";
}
i.glyphicons.notes_2:before {
  content: "\e030";
}
i.glyphicons.pencil:before {
  content: "\270F";
}
i.glyphicons.bus:before {
  content: "\e032";
}
i.glyphicons.wifi_alt:before {
  content: "\e033";
}
i.glyphicons.luggage:before {
  content: "\e034";
}
i.glyphicons.old_man:before {
  content: "\e035";
}
i.glyphicons.woman:before {
  content: "\e036";
}
i.glyphicons.file:before {
  content: "\e037";
}
i.glyphicons.coins:before {
  content: "\e038";
}
i.glyphicons.airplane:before {
  content: "\2708";
}
i.glyphicons.notes:before {
  content: "\e040";
}
i.glyphicons.stats:before {
  content: "\e041";
}
i.glyphicons.charts:before {
  content: "\e042";
}
i.glyphicons.pie_chart:before {
  content: "\e043";
}
i.glyphicons.group:before {
  content: "\e044";
}
i.glyphicons.keys:before {
  content: "\e045";
}
i.glyphicons.calendar:before {
  content: "\e046";
}
i.glyphicons.router:before {
  content: "\e047";
}
i.glyphicons.camera_small:before {
  content: "\e048";
}
i.glyphicons.dislikes:before {
  content: "\e049";
}
i.glyphicons.star:before {
  content: "\e050";
}
i.glyphicons.link:before {
  content: "\e051";
}
i.glyphicons.eye_open:before {
  content: "\e052";
}
i.glyphicons.eye_close:before {
  content: "\e053";
}
i.glyphicons.alarm:before {
  content: "\e054";
}
i.glyphicons.clock:before {
  content: "\e055";
}
i.glyphicons.stopwatch:before {
  content: "\e056";
}
i.glyphicons.projector:before {
  content: "\e057";
}
i.glyphicons.history:before {
  content: "\e058";
}
i.glyphicons.truck:before {
  content: "\e059";
}
i.glyphicons.cargo:before {
  content: "\e060";
}
i.glyphicons.compass:before {
  content: "\e061";
}
i.glyphicons.keynote:before {
  content: "\e062";
}
i.glyphicons.paperclip:before {
  content: "\e063";
}
i.glyphicons.power:before {
  content: "\e064";
}
i.glyphicons.lightbulb:before {
  content: "\e065";
}
i.glyphicons.tag:before {
  content: "\e066";
}
i.glyphicons.tags:before {
  content: "\e067";
}
i.glyphicons.cleaning:before {
  content: "\e068";
}
i.glyphicons.ruller:before {
  content: "\e069";
}
i.glyphicons.gift:before {
  content: "\e070";
}
i.glyphicons.umbrella:before {
  content: "\2602";
}
i.glyphicons.book:before {
  content: "\e072";
}
i.glyphicons.bookmark:before {
  content: "\e073";
}
i.glyphicons.wifi:before {
  content: "\e074";
}
i.glyphicons.cup:before {
  content: "\e075";
}
i.glyphicons.stroller:before {
  content: "\e076";
}
i.glyphicons.headphones:before {
  content: "\e077";
}
i.glyphicons.headset:before {
  content: "\e078";
}
i.glyphicons.warning_sign:before {
  content: "\e079";
}
i.glyphicons.signal:before {
  content: "\e080";
}
i.glyphicons.retweet:before {
  content: "\e081";
}
i.glyphicons.refresh:before {
  content: "\e082";
}
i.glyphicons.roundabout:before {
  content: "\e083";
}
i.glyphicons.random:before {
  content: "\e084";
}
i.glyphicons.heat:before {
  content: "\e085";
}
i.glyphicons.repeat:before {
  content: "\e086";
}
i.glyphicons.display:before {
  content: "\e087";
}
i.glyphicons.log_book:before {
  content: "\e088";
}
i.glyphicons.adress_book:before {
  content: "\e089";
}
i.glyphicons.building:before {
  content: "\e090";
}
i.glyphicons.eyedropper:before {
  content: "\e091";
}
i.glyphicons.adjust:before {
  content: "\e092";
}
i.glyphicons.tint:before {
  content: "\e093";
}
i.glyphicons.crop:before {
  content: "\e094";
}
i.glyphicons.vector_path_square:before {
  content: "\e095";
}
i.glyphicons.vector_path_circle:before {
  content: "\e096";
}
i.glyphicons.vector_path_polygon:before {
  content: "\e097";
}
i.glyphicons.vector_path_line:before {
  content: "\e098";
}
i.glyphicons.vector_path_curve:before {
  content: "\e099";
}
i.glyphicons.vector_path_all:before {
  content: "\e100";
}
i.glyphicons.font:before {
  content: "\e101";
}
i.glyphicons.italic:before {
  content: "\e102";
}
i.glyphicons.bold:before {
  content: "\e103";
}
i.glyphicons.text_underline:before {
  content: "\e104";
}
i.glyphicons.text_strike:before {
  content: "\e105";
}
i.glyphicons.text_height:before {
  content: "\e106";
}
i.glyphicons.text_width:before {
  content: "\e107";
}
i.glyphicons.text_resize:before {
  content: "\e108";
}
i.glyphicons.left_indent:before {
  content: "\e109";
}
i.glyphicons.right_indent:before {
  content: "\e110";
}
i.glyphicons.align_left:before {
  content: "\e111";
}
i.glyphicons.align_center:before {
  content: "\e112";
}
i.glyphicons.align_right:before {
  content: "\e113";
}
i.glyphicons.justify:before {
  content: "\e114";
}
i.glyphicons.list:before {
  content: "\e115";
}
i.glyphicons.text_smaller:before {
  content: "\e116";
}
i.glyphicons.text_bigger:before {
  content: "\e117";
}
i.glyphicons.embed:before {
  content: "\e118";
}
i.glyphicons.embed_close:before {
  content: "\e119";
}
i.glyphicons.table:before {
  content: "\e120";
}
i.glyphicons.message_full:before {
  content: "\e121";
}
i.glyphicons.message_empty:before {
  content: "\e122";
}
i.glyphicons.message_in:before {
  content: "\e123";
}
i.glyphicons.message_out:before {
  content: "\e124";
}
i.glyphicons.message_plus:before {
  content: "\e125";
}
i.glyphicons.message_minus:before {
  content: "\e126";
}
i.glyphicons.message_ban:before {
  content: "\e127";
}
i.glyphicons.message_flag:before {
  content: "\e128";
}
i.glyphicons.message_lock:before {
  content: "\e129";
}
i.glyphicons.message_new:before {
  content: "\e130";
}
i.glyphicons.inbox:before {
  content: "\e131";
}
i.glyphicons.inbox_plus:before {
  content: "\e132";
}
i.glyphicons.inbox_minus:before {
  content: "\e133";
}
i.glyphicons.inbox_lock:before {
  content: "\e134";
}
i.glyphicons.inbox_in:before {
  content: "\e135";
}
i.glyphicons.inbox_out:before {
  content: "\e136";
}
i.glyphicons.cogwheel:before {
  content: "\e137";
}
i.glyphicons.cogwheels:before {
  content: "\e138";
}
i.glyphicons.picture:before {
  content: "\e139";
}
i.glyphicons.adjust_alt:before {
  content: "\e140";
}
i.glyphicons.database_lock:before {
  content: "\e141";
}
i.glyphicons.database_plus:before {
  content: "\e142";
}
i.glyphicons.database_minus:before {
  content: "\e143";
}
i.glyphicons.database_ban:before {
  content: "\e144";
}
i.glyphicons.folder_open:before {
  content: "\e145";
}
i.glyphicons.folder_plus:before {
  content: "\e146";
}
i.glyphicons.folder_minus:before {
  content: "\e147";
}
i.glyphicons.folder_lock:before {
  content: "\e148";
}
i.glyphicons.folder_flag:before {
  content: "\e149";
}
i.glyphicons.folder_new:before {
  content: "\e150";
}
i.glyphicons.edit:before {
  content: "\e151";
}
i.glyphicons.new_window:before {
  content: "\e152";
}
i.glyphicons.check:before {
  content: "\e153";
}
i.glyphicons.unchecked:before {
  content: "\e154";
}
i.glyphicons.more_windows:before {
  content: "\e155";
}
i.glyphicons.show_big_thumbnails:before {
  content: "\e156";
}
i.glyphicons.show_thumbnails:before {
  content: "\e157";
}
i.glyphicons.show_thumbnails_with_lines:before {
  content: "\e158";
}
i.glyphicons.show_lines:before {
  content: "\e159";
}
i.glyphicons.playlist:before {
  content: "\e160";
}
i.glyphicons.imac:before {
  content: "\e161";
}
i.glyphicons.macbook:before {
  content: "\e162";
}
i.glyphicons.ipad:before {
  content: "\e163";
}
i.glyphicons.iphone:before {
  content: "\e164";
}
i.glyphicons.iphone_transfer:before {
  content: "\e165";
}
i.glyphicons.iphone_exchange:before {
  content: "\e166";
}
i.glyphicons.ipod:before {
  content: "\e167";
}
i.glyphicons.ipod_shuffle:before {
  content: "\e168";
}
i.glyphicons.ear_plugs:before {
  content: "\e169";
}
i.glyphicons.phone:before {
  content: "\e170";
}
i.glyphicons.step_backward:before {
  content: "\e171";
}
i.glyphicons.fast_backward:before {
  content: "\e172";
}
i.glyphicons.rewind:before {
  content: "\e173";
}
i.glyphicons.play:before {
  content: "\e174";
}
i.glyphicons.pause:before {
  content: "\e175";
}
i.glyphicons.stop:before {
  content: "\e176";
}
i.glyphicons.forward:before {
  content: "\e177";
}
i.glyphicons.fast_forward:before {
  content: "\e178";
}
i.glyphicons.step_forward:before {
  content: "\e179";
}
i.glyphicons.eject:before {
  content: "\e180";
}
i.glyphicons.facetime_video:before {
  content: "\e181";
}
i.glyphicons.download_alt:before {
  content: "\e182";
}
i.glyphicons.mute:before {
  content: "\e183";
}
i.glyphicons.volume_down:before {
  content: "\e184";
}
i.glyphicons.volume_up:before {
  content: "\e185";
}
i.glyphicons.screenshot:before {
  content: "\e186";
}
i.glyphicons.move:before {
  content: "\e187";
}
i.glyphicons.more:before {
  content: "\e188";
}
i.glyphicons.brightness_reduce:before {
  content: "\e189";
}
i.glyphicons.brightness_increase:before {
  content: "\e190";
}
i.glyphicons.circle_plus:before {
  content: "\e191";
}
i.glyphicons.circle_minus:before {
  content: "\e192";
}
i.glyphicons.circle_remove:before {
  content: "\e193";
}
i.glyphicons.circle_ok:before {
  content: "\e194";
}
i.glyphicons.circle_question_mark:before {
  content: "\e195";
}
i.glyphicons.circle_info:before {
  content: "\e196";
}
i.glyphicons.circle_exclamation_mark:before {
  content: "\e197";
}
i.glyphicons.remove:before {
  content: "\e198";
}
i.glyphicons.ok:before {
  content: "\e199";
}
i.glyphicons.ban:before {
  content: "\e200";
}
i.glyphicons.download:before {
  content: "\e201";
}
i.glyphicons.upload:before {
  content: "\e202";
}
i.glyphicons.shopping_cart:before {
  content: "\e203";
}
i.glyphicons.lock:before {
  content: "\e204";
}
i.glyphicons.unlock:before {
  content: "\e205";
}
i.glyphicons.electricity:before {
  content: "\e206";
}
i.glyphicons.ok_2:before {
  content: "\e207";
}
i.glyphicons.remove_2:before {
  content: "\e208";
}
i.glyphicons.cart_out:before {
  content: "\e209";
}
i.glyphicons.cart_in:before {
  content: "\e210";
}
i.glyphicons.left_arrow:before {
  content: "\e211";
}
i.glyphicons.right_arrow:before {
  content: "\e212";
}
i.glyphicons.down_arrow:before {
  content: "\e213";
}
i.glyphicons.up_arrow:before {
  content: "\e214";
}
i.glyphicons.resize_small:before {
  content: "\e215";
}
i.glyphicons.resize_full:before {
  content: "\e216";
}
i.glyphicons.circle_arrow_left:before {
  content: "\e217";
}
i.glyphicons.circle_arrow_right:before {
  content: "\e218";
}
i.glyphicons.circle_arrow_top:before {
  content: "\e219";
}
i.glyphicons.circle_arrow_down:before {
  content: "\e220";
}
i.glyphicons.play_button:before {
  content: "\e221";
}
i.glyphicons.unshare:before {
  content: "\e222";
}
i.glyphicons.share:before {
  content: "\e223";
}
i.glyphicons.chevron-right:before {
  content: "\e224";
}
i.glyphicons.chevron-left:before {
  content: "\e225";
}
i.glyphicons.bluetooth:before {
  content: "\e226";
}
i.glyphicons.euro:before {
  content: "\20AC";
}
i.glyphicons.usd:before {
  content: "\e228";
}
i.glyphicons.gbp:before {
  content: "\e229";
}
i.glyphicons.retweet_2:before {
  content: "\e230";
}
i.glyphicons.moon:before {
  content: "\e231";
}
i.glyphicons.sun:before {
  content: "\2609";
}
i.glyphicons.cloud:before {
  content: "\2601";
}
i.glyphicons.direction:before {
  content: "\e234";
}
i.glyphicons.brush:before {
  content: "\e235";
}
i.glyphicons.pen:before {
  content: "\e236";
}
i.glyphicons.zoom_in:before {
  content: "\e237";
}
i.glyphicons.zoom_out:before {
  content: "\e238";
}
i.glyphicons.pin:before {
  content: "\e239";
}
i.glyphicons.albums:before {
  content: "\e240";
}
i.glyphicons.rotation_lock:before {
  content: "\e241";
}
i.glyphicons.flash:before {
  content: "\e242";
}
i.glyphicons.google_maps:before {
  content: "\e243";
}
i.glyphicons.anchor:before {
  content: "\2693";
}
i.glyphicons.conversation:before {
  content: "\e245";
}
i.glyphicons.chat:before {
  content: "\e246";
}
i.glyphicons.male:before {
  content: "\e247";
}
i.glyphicons.female:before {
  content: "\e248";
}
i.glyphicons.asterisk:before {
  content: "\002A";
}
i.glyphicons.divide:before {
  content: "\00F7";
}
i.glyphicons.snorkel_diving:before {
  content: "\e251";
}
i.glyphicons.scuba_diving:before {
  content: "\e252";
}
i.glyphicons.oxygen_bottle:before {
  content: "\e253";
}
i.glyphicons.fins:before {
  content: "\e254";
}
i.glyphicons.fishes:before {
  content: "\e255";
}
i.glyphicons.boat:before {
  content: "\e256";
}
i.glyphicons.delete:before {
  content: "\e257";
}
i.glyphicons.sheriffs_star:before {
  content: "\e258";
}
i.glyphicons.qrcode:before {
  content: "\e259";
}
i.glyphicons.barcode:before {
  content: "\e260";
}
i.glyphicons.pool:before {
  content: "\e261";
}
i.glyphicons.buoy:before {
  content: "\e262";
}
i.glyphicons.spade:before {
  content: "\e263";
}
i.glyphicons.bank:before {
  content: "\e264";
}
i.glyphicons.vcard:before {
  content: "\e265";
}
i.glyphicons.electrical_plug:before {
  content: "\e266";
}
i.glyphicons.flag:before {
  content: "\e267";
}
i.glyphicons.credit_card:before {
  content: "\e268";
}
i.glyphicons.keyboard-wireless:before {
  content: "\e269";
}
i.glyphicons.keyboard-wired:before {
  content: "\e270";
}
i.glyphicons.shield:before {
  content: "\e271";
}
i.glyphicons.ring:before {
  content: "\02DA";
}
i.glyphicons.cake:before {
  content: "\e273";
}
i.glyphicons.drink:before {
  content: "\e274";
}
i.glyphicons.beer:before {
  content: "\e275";
}
i.glyphicons.fast_food:before {
  content: "\e276";
}
i.glyphicons.cutlery:before {
  content: "\e277";
}
i.glyphicons.pizza:before {
  content: "\e278";
}
i.glyphicons.birthday_cake:before {
  content: "\e279";
}
i.glyphicons.tablet:before {
  content: "\e280";
}
i.glyphicons.settings:before {
  content: "\e281";
}
i.glyphicons.bullets:before {
  content: "\e282";
}
i.glyphicons.cardio:before {
  content: "\e283";
}
i.glyphicons.t-shirt:before {
  content: "\e284";
}
i.glyphicons.pants:before {
  content: "\e285";
}
i.glyphicons.sweater:before {
  content: "\e286";
}
i.glyphicons.fabric:before {
  content: "\e287";
}
i.glyphicons.leather:before {
  content: "\e288";
}
i.glyphicons.scissors:before {
  content: "\e289";
}
i.glyphicons.bomb:before {
  content: "\e290";
}
i.glyphicons.skull:before {
  content: "\e291";
}
i.glyphicons.celebration:before {
  content: "\e292";
}
i.glyphicons.tea_kettle:before {
  content: "\e293";
}
i.glyphicons.french_press:before {
  content: "\e294";
}
i.glyphicons.coffe_cup:before {
  content: "\e295";
}
i.glyphicons.pot:before {
  content: "\e296";
}
i.glyphicons.grater:before {
  content: "\e297";
}
i.glyphicons.kettle:before {
  content: "\e298";
}
i.glyphicons.hospital:before {
  content: "\e299";
}
i.glyphicons.hospital_h:before {
  content: "\e300";
}
i.glyphicons.microphone:before {
  content: "\e301";
}
i.glyphicons.webcam:before {
  content: "\e302";
}
i.glyphicons.temple_christianity_church:before {
  content: "\e303";
}
i.glyphicons.temple_islam:before {
  content: "\e304";
}
i.glyphicons.temple_hindu:before {
  content: "\e305";
}
i.glyphicons.temple_buddhist:before {
  content: "\e306";
}
i.glyphicons.bicycle:before {
  content: "\e307";
}
i.glyphicons.life_preserver:before {
  content: "\e308";
}
i.glyphicons.share_alt:before {
  content: "\e309";
}
i.glyphicons.comments:before {
  content: "\e310";
}
i.glyphicons.flower:before {
  content: "\2698";
}
i.glyphicons.baseball:before {
  content: "\e312";
}
i.glyphicons.rugby:before {
  content: "\e313";
}
i.glyphicons.ax:before {
  content: "\e314";
}
i.glyphicons.table_tennis:before {
  content: "\e315";
}
i.glyphicons.bowling:before {
  content: "\e316";
}
i.glyphicons.tree_conifer:before {
  content: "\e317";
}
i.glyphicons.tree_deciduous:before {
  content: "\e318";
}
i.glyphicons.more_items:before {
  content: "\e319";
}
i.glyphicons.sort:before {
  content: "\e320";
}
i.glyphicons.filter:before {
  content: "\e321";
}
i.glyphicons.gamepad:before {
  content: "\e322";
}
i.glyphicons.playing_dices:before {
  content: "\e323";
}
i.glyphicons.calculator:before {
  content: "\e324";
}
i.glyphicons.tie:before {
  content: "\e325";
}
i.glyphicons.wallet:before {
  content: "\e326";
}
i.glyphicons.piano:before {
  content: "\e327";
}
i.glyphicons.sampler:before {
  content: "\e328";
}
i.glyphicons.podium:before {
  content: "\e329";
}
i.glyphicons.soccer_ball:before {
  content: "\e330";
}
i.glyphicons.blog:before {
  content: "\e331";
}
i.glyphicons.dashboard:before {
  content: "\e332";
}
i.glyphicons.certificate:before {
  content: "\e333";
}
i.glyphicons.bell:before {
  content: "\e334";
}
i.glyphicons.candle:before {
  content: "\e335";
}
i.glyphicons.pushpin:before {
  content: "\e336";
}
i.glyphicons.iphone_shake:before {
  content: "\e337";
}
i.glyphicons.pin_flag:before {
  content: "\e338";
}
i.glyphicons.turtle:before {
  content: "\e339";
}
i.glyphicons.rabbit:before {
  content: "\e340";
}
i.glyphicons.globe:before {
  content: "\e341";
}
i.glyphicons.briefcase:before {
  content: "\e342";
}
i.glyphicons.hdd:before {
  content: "\e343";
}
i.glyphicons.thumbs_up:before {
  content: "\e344";
}
i.glyphicons.thumbs_down:before {
  content: "\e345";
}
i.glyphicons.hand_right:before {
  content: "\e346";
}
i.glyphicons.hand_left:before {
  content: "\e347";
}
i.glyphicons.hand_up:before {
  content: "\e348";
}
i.glyphicons.hand_down:before {
  content: "\e349";
}
i.glyphicons.fullscreen:before {
  content: "\e350";
}
i.glyphicons.shopping_bag:before {
  content: "\e351";
}
i.glyphicons.book_open:before {
  content: "\e352";
}
i.glyphicons.nameplate:before {
  content: "\e353";
}
i.glyphicons.nameplate_alt:before {
  content: "\e354";
}
i.glyphicons.vases:before {
  content: "\e355";
}
i.glyphicons.bullhorn:before {
  content: "\e356";
}
i.glyphicons.dumbbell:before {
  content: "\e357";
}
i.glyphicons.suitcase:before {
  content: "\e358";
}
i.glyphicons.file_import:before {
  content: "\e359";
}
i.glyphicons.file_export:before {
  content: "\e360";
}
i.glyphicons.bug:before {
  content: "\e361";
}
i.glyphicons.crown:before {
  content: "\e362";
}
i.glyphicons.smoking:before {
  content: "\e363";
}
i.glyphicons.cloud-upload:before {
  content: "\e364";
}
i.glyphicons.cloud-download:before {
  content: "\e365";
}
i.glyphicons.restart:before {
  content: "\e366";
}
i.glyphicons.security_camera:before {
  content: "\e367";
}
i.glyphicons.expand:before {
  content: "\e368";
}
i.glyphicons.collapse:before {
  content: "\e369";
}
i.glyphicons.collapse_top:before {
  content: "\e370";
}
i.glyphicons.globe_af:before {
  content: "\e371";
}
i.glyphicons.global:before {
  content: "\e372";
}
i.glyphicons.spray:before {
  content: "\e373";
}
i.glyphicons.nails:before {
  content: "\e374";
}
i.glyphicons.claw_hammer:before {
  content: "\e375";
}
i.glyphicons.classic_hammer:before {
  content: "\e376";
}
i.glyphicons.hand_saw:before {
  content: "\e377";
}
i.glyphicons.riflescope:before {
  content: "\e378";
}
i.glyphicons.electrical_socket_eu:before {
  content: "\e379";
}
i.glyphicons.electrical_socket_us:before {
  content: "\e380";
}
i.glyphicons.pinterest:before {
  content: "\e381";
}
i.glyphicons.dropbox:before {
  content: "\e382";
}
i.glyphicons.google_plus:before {
  content: "\e383";
}
i.glyphicons.jolicloud:before {
  content: "\e384";
}
i.glyphicons.yahoo:before {
  content: "\e385";
}
i.glyphicons.blogger:before {
  content: "\e386";
}
i.glyphicons.picasa:before {
  content: "\e387";
}
i.glyphicons.amazon:before {
  content: "\e388";
}
i.glyphicons.tumblr:before {
  content: "\e389";
}
i.glyphicons.wordpress:before {
  content: "\e390";
}
i.glyphicons.instapaper:before {
  content: "\e391";
}
i.glyphicons.evernote:before {
  content: "\e392";
}
i.glyphicons.xing:before {
  content: "\e393";
}
i.glyphicons.zootool:before {
  content: "\e394";
}
i.glyphicons.dribbble:before {
  content: "\e395";
}
i.glyphicons.deviantart:before {
  content: "\e396";
}
i.glyphicons.read_it_later:before {
  content: "\e397";
}
i.glyphicons.linked_in:before {
  content: "\e398";
}
i.glyphicons.forrst:before {
  content: "\e399";
}
i.glyphicons.pinboard:before {
  content: "\e400";
}
i.glyphicons.behance:before {
  content: "\e401";
}
i.glyphicons.github:before {
  content: "\e402";
}
i.glyphicons.youtube:before {
  content: "\e403";
}
i.glyphicons.skitch:before {
  content: "\e404";
}
i.glyphicons.foursquare:before {
  content: "\e405";
}
i.glyphicons.quora:before {
  content: "\e406";
}
i.glyphicons.badoo:before {
  content: "\e407";
}
i.glyphicons.spotify:before {
  content: "\e408";
}
i.glyphicons.stumbleupon:before {
  content: "\e409";
}
i.glyphicons.readability:before {
  content: "\e410";
}
i.glyphicons.facebook:before {
  content: "\e411";
}
i.glyphicons.twitter:before {
  content: "\e412";
}
i.glyphicons.instagram:before {
  content: "\e413";
}
i.glyphicons.posterous_spaces:before {
  content: "\e414";
}
i.glyphicons.vimeo:before {
  content: "\e415";
}
i.glyphicons.flickr:before {
  content: "\e416";
}
i.glyphicons.last_fm:before {
  content: "\e417";
}
i.glyphicons.rss:before {
  content: "\e418";
}
i.glyphicons.skype:before {
  content: "\e419";
}
i.glyphicons.e-mail:before {
  content: "\e420";
}
