html {
    body[data-container="body"] {
        div.sooqrSearchContainer[class*="sooqrSearchContainer-"] {
            border-radius: 0;
            box-shadow: none;
            background: #fafafa;
            border: 1px solid #e8e8e8;
            font-family: $font-family__base;
            top: 109px;
            max-width: 100%;

            @media screen and (max-width: 767px) {
                top: 133px !important;
            }

            @media screen and (min-width: 768px) and (max-width: 1060px) {
                top: 95px !important;
            }

            @media screen and (min-width: 768px) and (max-width: 1060px) and (orientation:landscape){
                top: 95px !important;
            }

            @media screen and (min-width: 1061px) {
                top: 106px !important;
            }

            &.sticky-header-sooqr {
                @media screen and (min-width: 768px) and (max-width: 1060px) {
                    top: 144px !important;
                }

                @media screen and (min-width: 768px) and (max-width: 1060px) and (orientation:landscape){
                    top: 105px !important;
                }

                @media screen and (min-width: 1061px) {
                    top: 39px !important;
                }
            }

            * {
                font-family: $font-family__base;
                font-size: 14px;
            }

            .sbOptions {
                border-radius: 0;
                border: 0;
                margin-top: 9px;
            }

            .sbSelector {
                border: 0;
            }

            .sbToggle {
                background: none;

                &:before {
                    font-family: debondt;
                    content: "\e5cf";
                    color: $primary__color__darker;
                    font-size: 24px;
                    line-height: 30px;

                    @include max-screen(767px) {
                      font-size: 28px;
                      line-height: 40px;
                    }
                }
            }

            .sqr-closeButton {
                font-family: $font-family__base;
                background: $primary__color__darker;
                text-transform: uppercase;
                padding: 7px 7px 7px 12px;

                @include max-screen(767px) {
                    text-indent: 0;
                    font-size: 0;
                    background: #e1e3eb;
                }

                &:after {
                    font-family: debondt;
                    content: "\e5cd";
                    font-size: 20px;
                    margin-left: 7px;
                    line-height: 8px;
                    position: relative;
                    top: 4px;

                    @include max-screen(767px) {
                        font-size: 24px;
                        top: 9px;
                        right: 3px;
                        margin-left: 0;
                        color: #3A3846;
                    }
                }

                &:hover {
                    background: $primary__color;
                }

                @include max-screen(767px) {
                    text-indent: 0;
                    font-size: 0;
                }
            }

            div.sooqrSearchMobileTabs div a {
                @include max-screen(767px) {
                    color: #3A3846;
                }
            }

            .sooqrSearchMobileTabs {
                @include max-screen(767px) {
                    background: #e1e3eb;
                }

                .sooqrSearchMobileTabsResults #sooqrSearchMobileTabsResultsText,
                .sooqrSearchMobileTabsResults .sqrSearchResultCounter,
                .sooqrSearchMobileTabsResults #sooqrSearchMobileTabsFiltersText,
                .sooqrSearchMobileTabsResults a {
                    @include max-screen(767px) {
                        color: #3A3846;
                    }
                }
            }

            div.sooqrSearchFilters {
                @media screen and (min-width: 768px) and (max-width: 784px) {
                 display: none!important;
                }

                div.sooqrFilterText {
                    border-bottom: 1px solid #e8e8e8;
                    font-family: $font-family__base;
                    font-size: 24px;

                    p.sooqrFilterTextResults {
                        font-family: $font-family__base;
                        color: $primary__color;
                        font-weight: 900;
                        font-size: 22px;
                    }
                }

                div.searchFilter {
                    border-bottom: 1px solid #e8e8e8;

                    & > label {
                        font-weight: 900;
                        font-size: 20px;

                        color: $primary__color;
                        background: none;
                        position: relative;
                        margin-bottom: 10px;

                        &:after {
                            position: absolute;
                            content: "\e5cf";
                            font-family: debondt;
                            right: 0;
                            font-size: 32px;
                            @include rotate(180);
                            @include transition(all, 0.2s);
                        }
                    }

                    &.collapsed > label {
                        background: none !important;

                        &:after {
                            @include rotate(0);
                        }
                    }

                    div.searchFilterValues {
                        div.searchFilterFieldMultiselect, div.searchFilterField {
                            label {
                                color: $primary__color;
                                font-size: 14px;
                            }

                            span {
                                background: $tertiary__color;
                            }
                        }
                    }
                }

                a.sqr-powered-by-branding {
                    display: none;
                }
            }

            div.sooqrSearchResultsContainer {
                border-left: 1px solid #e8e8e8;

                @media screen and (min-width: 768px) and (max-width: 784px) {
                   margin: 0;
                   border-left: 0;
                }

                a.moreResults.sqr-moreResults {
                    margin: 5px auto 0 auto;
                    background: $primary__color__darker;
                    text-transform: uppercase;
                    padding: 7px 7px 7px 12px;
                    cursor: pointer;
                    color: #fff;
                    border-radius: 4px;
                    font-weight: bold;
                    border: 0;
                    transition: background-color .3s ease;
                    width: 260px;
                    overflow: hidden;

                    &:hover {
                        background: $primary__color;
                        text-decoration: none;
                    }

                    span {
                        color: #fff;
                        font-weight: 600;
                    }
                }

                div.sooqrSearchResults {
                    div.sqr-options {
                        background: #fafafa;
                        border-bottom: 1px solid #e8e8e8;

                        @media screen and (min-width: 768px) and (max-width: 784px) {
                           display: none;
                        }

                        div.sqr-views {
                            ul {
                                li.sqr-detail {
                                    display: none;
                                    background: none;
                                    position: relative;

                                    &:before {
                                        font-family: debondt;
                                        content: "\e912";
                                        color: $primary__color__darker;
                                        font-size: 21px;
                                        position: absolute;
                                        top: 0;
                                        left: 0;
                                        line-height: 21px;
                                    }

                                    &.sqr-active:before {
                                        color: darken($secondary__color, 15%);
                                    }
                                }

                                li.sqr-grid {
                                    display:none;
                                    background: none;
                                    position: relative;

                                    &:before {
                                        font-family: debondt;
                                        content: "\e911";
                                        color: $primary__color__darker;
                                        font-size: 21px;
                                        position: absolute;
                                        top: 0;
                                        left: 0;
                                        line-height: 21px;
                                    }

                                    &.sqr-active:before {
                                        color: darken($secondary__color, 15%);
                                    }
                                }

                                li.sqr-list {
                                    display:none;
                                    background: none;
                                    position: relative;

                                    &:before {
                                        font-family: debondt;
                                        content: "\e90f";
                                        color: $primary__color__darker;
                                        font-size: 21px;
                                        position: absolute;
                                        top: 0;
                                        left: 0;
                                        line-height: 21px;
                                    }

                                    &.sqr-active:before {
                                        color: darken($secondary__color, 15%);
                                    }
                                }
                            }
                        }
                    }

                    div.sqr-results.sqr-detail div.sqr-resultItem {
                        max-height: none;
                        min-height: 0;
                        height: 200px;
                        cursor: auto;

                        @include max-screen(767px) {
                            background: none;
                        }

                        @include max-screen(890px) {
                            height: 300px;
                        }
                    }

                    div.sqr-results.sqr-detail {
                        & .sqr-right {
                            width: calc(100% - 129px);
                            box-sizing: border-box;

                            @include max-screen(767px) {
                                width: calc(100% - 120px) !important;
                            }
                        }

                        & .sqr-left {
                            width: auto !important;

                            @include max-screen(767px) {
                                max-width: 110px !important;
                                max-height: 110px !important;

                                div.sqr-image img {
                                    max-width: 110px !important;
                                    max-height: 110px !important;
                                }
                            }
                        }
                    }

                    div.sqr-results {
                        div.sqr-resultItem {
                            &.sqr-init {
                                float: left;
                                width: 100%;
                            }

                            a.sqr-button {
                                background: $secondary__color !important;
                                line-height: 40px;
                                font-size: 16px;
                                color: #333;

                                @include max-screen(767px) {
                                    display: inline-block !important;
                                }
                            }
                        }

                        &.sqr-detail div.sqr-right .sqr-text {
                            width: 35%;
                            width: 100%;
                            max-height: 117px;
                            overflow: hidden;

                            @media only screen and (max-width: 889px) {
                                // Delete next line
                                width: calc(100% - 120px);
                                height: 100px;
                            }

                            .sqr-description {
                                display: none;
                            }

                            h3 {
                                overflow: hidden;
                                height: 27px;

                                @media only screen and (max-width: 889px) {
                                    height: 53px;
                                }
                            }
                        }

                        &.sqr-detail div.sqr-right div.sqr-order {
                            width: calc(100% - 120px);

                            @media only screen and (min-width: 890px) {
                                margin-top: 20px;
                            }

                            @include max-screen(767px) {
                                width: calc(100% + 130px) !important;
                            }

                            @media screen and (min-width: 768px) and (max-width: 889px) {
                               margin-top: 0;
                               width: calc(100% + 110px) !important;
                            }

                            @media only screen and (max-width: 889px) {
                                margin-left: -120px;
                                padding-left: 0;
                                float: none !important;
                            }

                            .sqr-price {
                                margin-bottom: 10px;
                                font-size: 14px;
                                margin-top: -50px;
                                color: $primary__color;
                                font-weight: normal;

                                @media only screen and (max-width: 889px) {
                                    display: block;
                                    text-align: right;
                                    //margin-top: -50px;
                                }

                                .sqr-price-value {
                                    font-size: 23px;
                                    font-weight: 900;
                                    color: $primary__color;
                                }
                            }

                            .sqr-button {
                                padding: 0 20px;
                                position: relative;
                            }

                            .catalog-category-view ul.option-selector-dropdown.always-shown {
                                top: 0;
                            }

                            .catalog-category-view .db-option-selector-container {
                                @include max-screen(889px) {
                                    margin-top: 25px !important;
                                 }

                                .db-option-selector {
                                    border-bottom: 1px solid #b4bace;
                                    height: auto;
                                    border-radius: 3px;

                                    .db-option-selector-opener {
                                        width: calc(100% - 40px);
                                    }

                                    .db-option-selector-close {
                                        width: 40px;
                                    }

                                    input.db-option-selector-input {
                                        padding-left: 10px;
                                    }

                                    input.db-option-selector-input::placeholder {
                                        font-size: 14px;
                                    }
                                }

                                button.db-plus, button.db-minus {
                                    font-size: 0;
                                    text-align: center;
                                }

                                .option-selector-dropdown {
                                    z-index: 12;
                                    max-height: 279px;

                                    @media only screen and (max-width: 889px) {
                                        max-height: 172px;
                                    }

                                    .db-flex-container {
                                        height: 50px !important;
                                        display: block;
                                    }

                                    .db-product-name {
                                        float: left;
                                        overflow: hidden;
                                        height: auto !important;
                                        top: 50%;
                                        position: relative;
                                        transform: translateY(-50%);
                                        line-height: 23px !important;
                                        max-height: 50px;
                                        margin-left: 10px;
                                        width: calc(100% - 205px);
                                    }

                                    .db-image {
                                        float: left;
                                        margin-top: 5px;
                                        margin-left: 5px;
                                    }

                                    .db-ordering {
                                        float: right;
                                    }

                                    .message {
                                        padding: 0;
                                        margin-top: 0;
                                    }
                                }

                                .option-selector-dropdown:not(.simple) {
                                    width: 130% !important;

                                    @media only screen and (max-width: 889px) {
                                        width: 100% !important;
                                    }
                                }
                            }
                            .catalog-category-view {
                                .db-option-selector-container:not(.expanded) {
                                    transform: rotate(0);
                                }
                            }
                        }

                        &.grid.sqr-grid {
                            div.sqr-resultItem {
                                border: 0;
                                h3 {
                                    text-align: center;

                                    a, a:link {
                                        font-size: 14px;
                                    }
                                }
                            }

                            div.sqr-right {
                                div.sqr-price {
                                    width: 100%;
                                    text-align: center;
                                }

                                .sqr-button {
                                    font-size: 14px;
                                    line-height: 34px;
                                }
                            }
                        }

                        &.list.sqr-list {
                            div.sqr-right {
                                .sqr-button {
                                    font-size: 14px;
                                    line-height: 28px;
                                    text-transform: none;
                                    letter-spacing: 0;
                                }
                            }
                        }

                        div.sqr-resultItem h3 a:link, div.sqr-resultItem h3 a:link {
                            font-weight: 900;
                            color: $primary__color;
                            font-size: 20px;
                        }
                    }
                }
            }
        }
    }
}
