.block.newsletter {
    margin-bottom: $indent__xl;

    .form.subscribe {
        display: table;
        width: 100%;
    }

    .fieldset {
        display: table-cell;
        padding: 0;
        vertical-align: top;
    }

    .field {
        margin: 0;

        .control {
            @include lib-icon-font(
                $icon-envelope,
                $_icon-font-size       : 35px,
                $_icon-font-line-height: 33px,
                $_icon-font-color      : $form-element-input-placeholder__color
            );
            display: block;
            position: relative;
            height: 32px;

            &:before {
                position: absolute;
                content: none;
            }

            .field-bottom-border {
              position: absolute;
              left: 0;
              bottom: 0;
              width: 100%;
              height: 1px;
              background: $primary__color__darker;

              .animated-border-bottom {
                width: 0%;
                height: 1px;
                background: $secondary__color;
                @include transition(width, 0.2s);

              }
            }
        }
    }

    input {
      border: 0px solid ;
      padding-left: 0;
      color: $primary__color__darker;
      height: 32px;

      &::-webkit-input-placeholder {color: $primary__color__darker;}
      &:-moz-placeholder {color: $primary__color__darker;}
      &::-moz-placeholder {color: $primary__color__darker;}
      &:-ms-input-placeholder {color: $primary__color__darker;}
    }

    .title {
        @extend .debondt-title;
        font-size: 36px;
        font-weight: 100;
    }

    .label {
        @extend .abs-visually-hidden;
    }

    .actions {
        display: table-cell;
        vertical-align: top;
        width: 1%;
    }

    .action.subscribe {
      background: none;
      border: 1px solid $primary__color__darker;
      border-radius: 0px;
      font-family: $font-family__base;
      color: rgba(58, 56, 70, 1);
      text-transform: uppercase;
      letter-spacing: $default__letter-spacing;
      font-weight: 400;
      position: relative;
      overflow: hidden;
      white-space: nowrap;
      @include transition-timing-function(cubic-bezier(0.2, 1, 0.3, 1));
      @include transition-delay(0.2s);

      &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 150%;
        height: 100%;
        background: $secondary__color;
        z-index: -1;
        @include transition(transform 0.3s, opacity 0.3s, background-color 0.3s);
        @include transform-origin(0% 100%);
        @include transform(rotate3d(0, 0, 1, -45deg) translate3d(0, -3em, 0));
      }
    }


    .content {
      &:hover {
        .field-bottom-border {
          .animated-border-bottom {
            width: 100%;

          }
        }

        .action.subscribe {
          border-color: $secondary__color;
          color: rgba(255, 255, 255, 1);

          &:before {
            background-color: $secondary__color;
            @include transform(rotate3d(0, 0, 1, 0deg));
            @include transition-timing-function(cubic-bezier(0.2, 1, 0.3, 1));
            @include transition-delay(0.2s);
          }
        }
      }
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m + 1) {
    .block.newsletter {
        width: 32%;

        .field {

            .control {
                width: 100%;

                input {

                }
            }
        }

        .action.subscribe {

        }
    }
}
