.pages {
    @include lib-pager(
        $_pager-font-size: 28px,
        $_pager-font-weight: 900,
        $_pager-color: $primary__color__darker,
        $_pager-color-visited: $primary__color__darker,
        $_pager-color-hover: $primary__color__darker,
        $_pager-color-active: $primary__color__darker,
        $_pager-background: $primary__color__lighter,
        $_pager-background-hover: $primary__color,
        $_pager-background-active: $primary__color__lighter,
        $_pager-background-visited: $primary__color__lighter,
        $_pager-current-color: #fff,
        $_pager-current-background: $primary__color__darker,
        $_pager-item-margin: 0 0 0 10px,
        $_pager-action-color: $primary__color__darker,
        $_pager-action-color-hover: $primary__color__darker,
        $_pager-action-color-active: $primary__color__darker,
        $_pager-action-color-visited: $primary__color__darker,
        $_pager-action-background: $primary__color__lighter,
        $_pager-action-background-hover: $primary__color,
        $_pager-action-background-active: $primary__color__lighter,
        $_pager-action-background-visited: $primary__color__lighter,
        $_pager-action-border: 1px solid $primary__color,
        $_pager-action-border-hover: 1px solid $primary__color,
        $_pager-action-border-active: 1px solid $primary__color,
        $_pager-action-border-visited: 1px solid $primary__color,
        $_pager-border: 1px solid $primary__color,
        $_pager-border-hover: 1px solid $primary__color,
        $_pager-border-active: 1px solid $primary__color,
        $_pager-border-visited: 1px solid $primary__color,
        $_pager-icon-font: debondt,
        $_pager-icon-next-content: "\e904",
        $_pager-icon-previous-content: "\e90b",
        $_pager-icon-font-margin: 0,
        $_pager-icon-font-size: 40px,
        $_pager-current-border: 1px solid $primary__color__darkest
    );

    .item {
        text-align: center;

        a, strong {
            width: 50px;
            height: 50px;
            border-radius: 3px;
            line-height: 50px;
            box-sizing: border-box;
        }

    }

    .action {

    }
}


.checkout-cart-index {
    .toolbar-number {
        display: none;
    }
}

.home-block-title {
    text-transform: uppercase;
    font-size: 48px;
    font-weight: 900;
    letter-spacing: -0.05em;
    display: block;
    text-align: center;
    margin: 20px 0 30px;
    color: #3a3846;

    strong {
        font-weight: 900;
    }
}
