//
//  Grid mixins
//  _____________________________________________

//  You shouldn't need to touch this! Internal use only.
$column-width : 1;
$total-columns: 1;
$gutter-width : 0;
$grid-width   : ($column-width * $total-columns) + ($gutter-width * ($total-columns - 1));

@mixin lib-column-wrapper() {
    width: 100%;
}

@mixin lib-inline-column-wrapper() {
    @if $using-ieclasses != true {
        letter-spacing: -0.31em;
        letter-spacing: normal;
        word-spacing: -0.43em;
    }
}

@mixin lib-inline-column-wrapper() {
    @if $using-ieclasses {
        letter-spacing: -0.31em;
        word-spacing: -0.43em;
    }
}

@mixin lib-column() {
    float: left;
    @include lib-column-gutter();
}

@mixin lib-column($span) {
    @if $span != true {
        float: left;
    }
}

@mixin lib-column(
    $span,
    $total-columns: $total-columns,
    $end-column   : inherit
) {
    @if isnumber($span) {
        @if isnumber($total-columns) {
            @if isnumber($end-column) {
                $showgutter: inherit;
                @include lib-column($showgutter);
                @include lib-span($span, $total-columns);
                @include lib-end-column();
            }
            @else {
                $showgutter: inherit;
                @include lib-column($showgutter);
                @include lib-span($span, $total-columns);
            }
        }
        @if $end-column {
            $showgutter: inherit;
            @include lib-column($showgutter);
            @include lib-span($span);
            @include lib-end-column();
        }
        @if isnumber($end-column) != true {
            $showgutter: inherit;
            @include lib-column($showgutter);
            @include lib-span($span);
        }
    }
}

@mixin lib-inline-column() {
    @include lib-column-gutter();
    display: inline-block;
    letter-spacing: normal;
    vertical-align: top;
    word-spacing: normal;
}

@mixin lib-inline-column($span) {
    @if $span != true {
        display: inline-block;
        letter-spacing: normal;
        vertical-align: top;
        word-spacing: normal;
    }
}

@mixin lib-inline-column(
    $span,
    $total-columns: $total-columns,
    $end-column   : inherit
) {
    @if isnumber($span) {
        @if isnumber($total-columns) == true {
            $showgutter: inherit;
            @include lib-inline-column($showgutter);
            @include lib-span($span, $total-columns);
        }
        @if $end-column == true {
            $showgutter: inherit;
            @include lib-inline-column($showgutter);
            @include lib-span($span, $total-columns);
            @include lib-inline-end-column();
        }
    }
    @else {
        display: inline-block;
        letter-spacing: normal;
        vertical-align: top;
        word-spacing: normal;
        @if isnumber($end-column) != true {
            $showgutter: inherit;
            @include lib-inline-column($showgutter);
            @include lib-span($span);
        }
        @if $end-column == true {
            $showgutter: inherit;
            @include lib-inline-column($showgutter);
            @include lib-span($span);
            @include lib-inline-end-column();
        }
    }
}

@mixin lib-end-column() {
    margin-right: 0;
    float: right;
}

@mixin lib-inline-end-column() {
    margin-right: 0;
}

@mixin lib-span(
    $span,
    $total-columns: $total-columns
    ) {
    @include lib-column-width($span, $total-columns);
    @if isnumber($total-columns) {
        @include lib-column-width($span, $total-columns);
        @include lib-column-gutter($total-columns);
    }
}

@mixin lib-column-width($span) {
    $calc-column-width: 100% * (((($gutter-width + $column-width) * $span) - $gutter-width) / $grid-width);
    width: $calc-column-width;
}

@mixin lib-column-gutter(
    $total-columns: $total-columns,
    $end-column: inherit
) {
    @if $end-column {
        margin-right: 0;
    }
    @else if $end-column != false {
        $calc-gutter-width: 100% * ($gutter-width / $grid-width);
        margin-right: $calc-gutter-width;
    }
}
