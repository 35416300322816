/*
 * This file turns the color group filter into a colorful dropdown.
 * When the values of attribute 'color_group' change, this files needs to be updated as well.
 */

.filter-options-content {
  display: block;
  /* 5*48 + 12 */
  width: 252px;
}

ol.am_shopby_filter_items_attr_color_group {
  display: flex;
  flex-wrap: wrap;

  li {
    display: block;
  }
}

ol.am_shopby_filter_items_attr_color_group li a {
  display: block;
  border: 1px solid #ccc;
  margin: 2px;
  width: 40px;
  height: 40px;
}

ol.am_shopby_filter_items_attr_color_group li a span {
  visibility: hidden;
}

ol.am_shopby_filter_items_attr_color_group {

  .am_shopby_filter_item_59 {
    background-color: #e71837;
  }
  .am_shopby_filter_item_60 {
    background-color: #7fff00;
  }
  .am_shopby_filter_item_61 {
    background-color: #0000ff;
  }
  .am_shopby_filter_item_62 {
    background-color: yellow;
  }
  .am_shopby_filter_item_63 {
    background-color: #a83cc3;
  }
  .am_shopby_filter_item_13949 {
    background-color: gold;
  }
  .am_shopby_filter_item_13953 {
    background-color: pink;
  }
  .am_shopby_filter_item_13956 {
    background-color: silver;
  }
  .am_shopby_filter_item_13964 {
    background-color: #eeceac;
  }
  .am_shopby_filter_item_13968 {
    background-color: saddlebrown;
  }
  .am_shopby_filter_item_13969 {
    background-color: lightgrey;
  }
  .am_shopby_filter_item_13975 {
    background-color: white;
  }
  .am_shopby_filter_item_13984 {
    background-color: black;
  }
  .am_shopby_filter_item_13988 {
    background-color: grey;
  }
  .am_shopby_filter_item_13989 {
    background-color: #774f2e;
  }
  .am_shopby_filter_item_14001 {
    background-color: orange;
  }
  .am_shopby_filter_item_14002 {
    background-color: beige;
  }
  .am_shopby_filter_item_14014 {
    background-color: lightblue;
  }
  .am_shopby_filter_item_14236 {
    background-color: #c8a2c8;
  }
  .am_shopby_filter_item_14237 {
    background-color: purple;
  }
  .am_shopby_filter_item_15476 {
    background-color: #a68462;
  }
}






