//
//  Variables
//  _____________________________________________

$form-field__vertical-indent__desktop: 29px;
$form-calendar-icon__color: $primary__color;
$form-element-input__height: 44px;



//
//  Common
//  _____________________________________________

.fieldset {
    @include lib-form-fieldset();
    &:last-child {
        margin-bottom: 0;
    }

    & > .field,
    & > .fields > .field {
        @include lib-form-field(
        $_type-inline-label-align      : left,
        $_type-block-label-align       : left,
        $_label-color                  : inherit,
        $_label-font-size              : 18px,
        $_label-font-family            : inherit,
        $_label-font-weight            : 400,
        $_label-font-style             : inherit,
        $_label-line-height            : inherit,
        $_type-inline-label-width      : 33.8%,
        $_type-inline-label-padding    : 8px 15px 0 0,
        $_type-inline-control-width    : 66.2%
        );

        &.no-label {
            > .label {
                @extend .abs-visually-hidden;
            }
        }

        &.choice {
            .label {
                display: inline;
                font-weight: normal;
            }
        }

        .label {
            .column:not(.main) & {
                font-weight: normal;
            }
        }

        .field.choice {
            margin-bottom: $indent__s;

            &:last-child {
                margin-bottom: 0;
            }
        }

        input[type=file] {
            margin: $indent__xs 0;
        }

        textarea {min-height: 300px}
    }
}

.legend + .fieldset,
.legend + div {
    clear: both;
}

.legend {
    strong {
        margin-left: $indent__xs;
    }
}

fieldset.field {
    border: 0;
    padding: 0;
}

.field {
    &.date {
        @extend .abs-field-date;

        .time-picker {
            display: inline-block;
            margin-top: $indent__s;
            white-space: nowrap;
        }
    }

    .message {
        &.warning {
            margin-top: $indent__s;
        }
    }
}

div.mage-error[generated] {
    margin-top: 7px;
}

.field .tooltip {
    @include lib-tooltip(right);
    .tooltip-content {
        min-width: 200px;
        white-space: normal;
    }
}

input,
select {
    &:focus ~ .tooltip .tooltip-content {
        display: block;
    }
}

._has-datepicker {
    ~ .ui-datepicker-trigger {
        @include lib-button-reset();
        @include lib-icon-font(
            $_icon-font-content    : $icon-calendar,
            $_icon-font-color      : $primary__color__lighter,
            $_icon-font-size       : $icon-calendar__font-size,
            $_icon-font-line-height: $icon-calendar__font-size,
            $_icon-font-display    : inline-block,
            $_icon-font-text-hide  : true
        );
        vertical-align: middle;

        &:focus {
            box-shadow: none;
            outline: 0;
        }
    }
}

//
//  Sidebar forms
//  -----------------------------------------

.sidebar {
    .fieldset {
        margin: 0;

        & > .field:not(.choice),
        .fields > .field {
            &:not(:last-child) {
                margin: 0 0 $form-field__vertical-indent;
            }

            .label {
                margin: 0 0 4px;
                padding: 0 0 $indent__xs;
                text-align: left;
                width: 100%;
            }

            .control {
                width: 100%;
            }
        }
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .fieldset {
        .legend {
            @extend .abs-margin-for-forms-desktop;
        }

        > .field {
            @include lib-form-field-type-revert(
                $_type-inline-label-align          : left,
                $_type-inline-control-width        : 100%,
                $_type-inline-label-width          : 0,
                $_type-inline-label-padding        : 8px 15px 0 0
            );
            margin: 0 0 20px;

            .label.experience-description, .control.experience-description {
                width: 100%;
                margin-bottom: 10px;
            }
        }
    }
}
