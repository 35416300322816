/**
 * @license
 *
 * Font Family: Satoshi
 * Designed by: Deni Anggara
 * URL: https://www.fontshare.com/fonts/satoshi
 * © 2023 Indian Type Foundry
 *
 * Font Styles:
 * Satoshi Variable(Variable font)
 * Satoshi Variable Italic(Variable font)
 * Satoshi Light
 * Satoshi Light Italic
 * Satoshi Regular
 * Satoshi Italic
 * Satoshi Medium
 * Satoshi Medium Italic
 * Satoshi Bold
 * Satoshi Bold Italic
 * Satoshi Black
 * Satoshi Black Italic
 *
*/


/**
* This is a variable font
* You can controll variable axes as shown below:
* font-variation-settings: 'wght' 900.0;
*
* available axes:

* 'wght' (range from 300.0 to 900.0)

*/

@font-face {
    font-family: 'Satoshi-Variable';
    src: url('../fonts/satoshi/Satoshi-Variable.woff2') format('woff2'),
    url('../fonts/satoshi/Satoshi-Variable.woff') format('woff'),
    url('../fonts/satoshi/Satoshi-Variable.ttf') format('truetype');
    font-weight: 300 900;
    font-display: swap;
    font-style: normal;
}


/**
* This is a variable font
* You can controll variable axes as shown below:
* font-variation-settings: 'wght' 900.0;
*
* available axes:

* 'wght' (range from 300.0 to 900.0)

*/

@font-face {
    font-family: 'Satoshi';
    src: url('../fonts/satoshi/Satoshi-VariableItalic.woff2') format('woff2'),
    url('../fonts/satoshi/Satoshi-VariableItalic.woff') format('woff'),
    url('../fonts/satoshi/Satoshi-VariableItalic.ttf') format('truetype');
    font-weight: 300 900;
    font-display: swap;
    font-style: italic;
}


@font-face {
    font-family: 'Satoshi';
    src: url('../fonts/satoshi/Satoshi-Light.woff2') format('woff2'),
    url('../fonts/satoshi/Satoshi-Light.woff') format('woff'),
    url('../fonts/satoshi/Satoshi-Light.ttf') format('truetype');
    font-weight: 300;
    font-display: swap;
    font-style: normal;
}


@font-face {
    font-family: 'Satoshi';
    src: url('../fonts/satoshi/Satoshi-LightItalic.woff2') format('woff2'),
    url('../fonts/satoshi/Satoshi-LightItalic.woff') format('woff'),
    url('../fonts/satoshi/Satoshi-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-display: swap;
    font-style: italic;
}


@font-face {
    font-family: 'Satoshi';
    src: url('../fonts/satoshi/Satoshi-Regular.woff2') format('woff2'),
    url('../fonts/satoshi/Satoshi-Regular.woff') format('woff'),
    url('../fonts/satoshi/Satoshi-Regular.ttf') format('truetype');
    font-weight: 400;
    font-display: swap;
    font-style: normal;
}


@font-face {
    font-family: 'Satoshi';
    src: url('../fonts/satoshi/Satoshi-Italic.woff2') format('woff2'),
    url('../fonts/satoshi/Satoshi-Italic.woff') format('woff'),
    url('../fonts/satoshi/Satoshi-Italic.ttf') format('truetype');
    font-weight: 400;
    font-display: swap;
    font-style: italic;
}


@font-face {
    font-family: 'Satoshi';
    src: url('../fonts/satoshi/Satoshi-Medium.woff2') format('woff2'),
    url('../fonts/satoshi/Satoshi-Medium.woff') format('woff'),
    url('../fonts/satoshi/Satoshi-Medium.ttf') format('truetype');
    font-weight: 500;
    font-display: swap;
    font-style: normal;
}


@font-face {
    font-family: 'Satoshi';
    src: url('../fonts/satoshi/Satoshi-MediumItalic.woff2') format('woff2'),
    url('../fonts/satoshi/Satoshi-MediumItalic.woff') format('woff'),
    url('../fonts/satoshi/Satoshi-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-display: swap;
    font-style: italic;
}


@font-face {
    font-family: 'Satoshi';
    src: url('../fonts/satoshi/Satoshi-Bold.woff2') format('woff2'),
    url('../fonts/satoshi/Satoshi-Bold.woff') format('woff'),
    url('../fonts/satoshi/Satoshi-Bold.ttf') format('truetype');
    font-weight: 700;
    font-display: swap;
    font-style: normal;
}


@font-face {
    font-family: 'Satoshi';
    src: url('../fonts/satoshi/Satoshi-BoldItalic.woff2') format('woff2'),
    url('../fonts/satoshi/Satoshi-BoldItalic.woff') format('woff'),
    url('../fonts/satoshi/Satoshi-BoldItalic.ttf') format('truetype');
    font-weight: 700;
    font-display: swap;
    font-style: italic;
}


@font-face {
    font-family: 'Satoshi';
    src: url('../fonts/satoshi/Satoshi-Black.woff2') format('woff2'),
    url('../fonts/satoshi/Satoshi-Black.woff') format('woff'),
    url('../fonts/satoshi/Satoshi-Black.ttf') format('truetype');
    font-weight: 900;
    font-display: swap;
    font-style: normal;
}


@font-face {
    font-family: 'Satoshi';
    src: url('../fonts/satoshi/Satoshi-BlackItalic.woff2') format('woff2'),
    url('../fonts/satoshi/Satoshi-BlackItalic.woff') format('woff'),
    url('../fonts/satoshi/Satoshi-BlackItalic.ttf') format('truetype');
    font-weight: 900;
    font-display: swap;
    font-style: italic;
}


.items {
    @include lib-list-reset-styles();
}

//
//    Desktop
//--------------------------------------

@include min-screen($screen__m) {
    h1 {
        font-size: 30px;
        margin-bottom: $h1__margin-bottom__desktop;
        font-weight: 900;

    }
}
