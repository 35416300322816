@include lib-font-face(
    $family-name: $icons__font-name,
    $font-path  : $icons__font-path,
    $font-weight: normal,
    $font-style : normal
);

@include lib-font-face(
    $family-name: 'debondt',
    $font-path  : '../fonts/icons-debondt/icons-debondt',
    $font-weight: normal,
    $font-style : normal
);
