.amshopby-hightlighted {
    background-color: yellow;
}


.ui-tooltip, .arrow:after {
    background: white;
    border: 1px solid black;
    box-shadow: 0 0 7px black;
}
.ui-tooltip {
    padding: 10px 20px;
    box-shadow: 0 0 7px black;
}
.ui-tooltip .arrow {
    width: 70px;
    height: 16px;
    overflow: hidden;
    position: absolute;
    left: 50%;
    margin-left: -35px;
    bottom: -16px;
}
.ui-tooltip .arrow.top {
    top: -16px;
    bottom: auto;
}
.ui-tooltip .arrow.left {
    left: 7%;
}
.ui-tooltip .arrow:after {
    content: "";
    position: absolute;
    left: 20px;
    top: -20px;
    width: 25px;
    height: 25px;
    box-shadow: 6px 5px 9px -9px black;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}
.ui-tooltip .arrow.top:after {
    bottom: -20px;
    top: auto;
}

.sticky-position-keeper {
  margin: 20px 0;
}

.filterbar-container {
  // overflow: hidden;
  border: 1px solid $primary__color__darker;
  border-width: 1px 0;
  margin: 0;

  &:after {
    visibility: hidden;
    display: block;
    font-size: 0;
    content: "";
    clear: both;
    height: 0;
  }
}

.block.filter {
  padding: 5px 0;
  margin: 0px;
  float: left;
  width: 65%;

  &:after {
    visibility: hidden;
    display: block;
    font-size: 0;
    content: " ";
    clear: both;
    height: 0;
  }

  .block-title {
    margin-bottom: 5px;

    strong {
      font-weight: 900;
    }
  }

  .block-actions {
    float: left;
    display: inline-block;
    border-radius: 3px;
    margin-right: 5px;
    cursor: pointer;
    background: $secondary__color;
    border: 1px solid $secondary__color__dark;
    padding: 2px 5px;
    margin-bottom: 0px;

    a {
      color: #fff;
      // text-transform: uppercase;
      // letter-spacing: $default__letter-spacing;
      font-weight: 400;

      &:hover {
        text-decoration: none;
      }

      &:before {
        font-family: 'debondt';
        content: "\e5cd";
        margin-right: 5px;
        font-size: 18px;
        line-height: 0;
        position: relative;
        top: 2px;
        display: inline-block;
      }
    }

    &:hover {
      background: $primary__color__darker;
      border-color: $primary__color__darker;
    }
  }
}

.filter-content {
  position: relative;

  .filter-options {
    width: 100%;

    &:after {
      visibility: hidden;
      display: block;
      font-size: 0;
      content: " ";
      clear: both;
      height: 0;
    }

    .selected-options {
      width: 100%;
      float: left;
      margin: 5px 0 0;

      padding: 10px 0 0;
    }
  }


  .layered-nav-filter-button {
    background: #fff;
    float: left;
    border: 1px solid $primary__color;
    position: relative;
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
    margin-right: 10px;

    .filter-options-title {
      line-height: 36px;
      margin-bottom: 0;
      font-size: 14px;
      cursor: pointer;
      padding: 0 8px;
      font-weight: 400;

      &:after {
        font-family: 'debondt';
        content: "\e5cf";
        display: inline-block;
        margin-left: 10px;
        font-size: 22px;
        font-weight: 400;
        line-height: 0;
        position: relative;
        top: 4px;
        @include transition(transform 0.2s);
      }
    }

    .filter-options-content {
      position: absolute;
      min-width: 250px;
      z-index: 99;
      overflow-y: auto;
      background: #fff;
      border: 1px solid $primary__color;
      left: -1px;
      padding: 5px 5px 0;
      max-height: 180px;

      li a {
        font-size: 14px;
        color: $primary__color;
      }

      .swatch-option {
        width: 36px;
        height: 36px;
        border: 1px solid $primary__color__darker;
      }
    }
  }


  .closed {
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
    margin-bottom: 5px;

    .filter-options-content {
      display: none;
    }

    .filter-options-title {

    }
  }

  .open {
    margin-bottom: 0;

    .filter-options-title {
      padding-bottom: 5px;
    }

    .filter-options-content {
      display: block;
    }

    .filter-options-title {

      &:after {
        @include rotate(180);
      }

    }
  }
}


.filter-current {
  float: left;

  .block-subtitle {
    margin-right: 10px;
    float: left;
  }

  .items {
    overflow: hidden;

    .item {
      display: inline-block;
      border-radius: 3px;
      margin-right: 5px;
      float: left;
      background: $primary__color__darker;
      border: 1px solid $primary__color__darkest;
      padding: 2px 5px;
      color: #fff;
      margin-bottom: 5px;

      .filter-label {
        display: inline;
      }

      .remove {
        text-decoration: none;

        span {
          display: none;
        }

        &:after {
          font-family: 'debondt';
          content: "\e5cd";
          color: #fff;
          font-size: 16px;
          line-height: 0;
          position: relative;
          top: 2px;
        }

        &:hover {

        }
      }
    }

  }
}




@include max-screen($screen__m) {
  .filterbar-container {
    border: 0px;

    .block.filter {
      width: 100%;

      .block-title {
        text-align: center;

        strong {
          font-size: 22px;
        }
      }
    }
     .toolbar-products {
       width: 100%;
       margin-bottom: 10px;

       .sorter-label {
         text-align: center;
         font-size: 22px;
       }
     }

    .filter-content {
      .layered-nav-filter-button {
        width: 100%;
        box-sizing: border-box;
        margin-bottom: 5px;
        background: none;
        border: 1px solid $primary__color;
        border-width: 0 0 1px 0;
        border-radius: 0px;

        .filter-options-title {
          padding: 0;
            font-size: 18px;
            font-weight: 900;

          &:after {
            right: 0;
            top: 14px;
            position: absolute;
            font-size: 30px;
          }
        }
      }

      .open {
        .filter-options-content {
          width: 100%;
          position: relative;
          margin-bottom: 0;
          background: none;
          border-radius: 0;
          left: 0;
          border: none;
          box-sizing: border-box;
          padding: 5px 10px;
        }
      }
    }
  }
}
