.cms-scanapp-intro {
    .page-title,
    .breadcrumbs {
        display: none !important;
    }
}

.scanintro-container {
    overflow: hidden;
    margin-top: 80px;

    @media only screen and (max-width: 700px) {
        margin-top: 20px;
    }
}

.scanintro-steps {
    float: left;
    width: calc(100% - 625px);
    margin-top: 56px;

    ul {
        margin: 0;

        > li {
            padding-left: 40px;
            font-size: 18px;
            margin: 0 0 15px;
            position: relative;

            &:before {
                content: '';
                display: block;
                width: 17px;
                height: 17px;
                background: #3a3846;
                position: absolute;
                border-radius: 50%;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
            }

            @media only screen and (max-width: 1060px) {
                font-size: 16px;
            }

            @media only screen and (max-width: 875px) {
                margin-bottom: 5px;
                padding-right: 10px;
            }
        }
    }

    @media only screen and (max-width: 1180px) {
        width: calc(100% - 580px);
    }

    @media only screen and (max-width: 1120px) {
        width: 52%;
    }

    @media only screen and (max-width: 875px) {
        margin-top: 26px;
    }

    @media only screen and (max-width: 700px) {
        margin-top: 0;
        width: 100%;
    }
}

.scanintro-steps__title {
    padding: 20px 30px;
    background: #3a3846;
    color: #fff;
    font-size: 48px;
    font-weight: 300;
    line-height: 60px;
    text-transform: uppercase;

    span {
        display: block;
        font-weight: 900;
    }

    @media only screen and (max-width: 875px) {
        padding: 10px 20px;
    }
}

.scanintro-images {
    float: left;
    width: 625px;

    @media only screen and (max-width: 1180px) {
        width: 580px;
    }

    @media only screen and (max-width: 1120px) {
        float: left;
        width: 48%;
        height: 0;
        padding-bottom: 50%;
        position: relative;

        .image-centering {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
        }
    }

    @media only screen and (max-width: 700px) {
        width: 80%;
        margin-left: 10%;
        padding-bottom: 82%;
        margin-top: 50px;
    }
}

.phone-image-diagonal {
    position: relative;
    float: left;

    .red-circle {
        position: absolute;
        top: 68px;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        border: 3px solid #FF4A4A;
        right: 46px;

        @media only screen and (max-width: 1120px) {
            width: 20%;
            height: 0;
            padding-bottom: 20%;
            top: 11%;
            right: 16%;
        }
    }

    .red-line {
        position: absolute;
        left: -20px;
        top: 18%;

        @media only screen and (max-width: 1120px) {
            width: 89%;
            top: 18%;
            right: 16%;
            left: auto;
            height: auto;
        }

        @media only screen and (max-width: 700px) {
            display: none;
        }
    }

    @media only screen and (max-width: 1120px) {
        height: 100%;
        width: auto;

        img {
            height: 100%;
            width: auto;
        }
    }
}

.phone-image-front {
    float: right;

    @media only screen and (max-width: 1120px) {
        height: 100%;
        width: auto;

        img {
            height: 100%;
            width: auto;
        }
    }
}

.scanintro-steps__subtitle {
    margin-top: 40px;
    font-size: 32px;
    font-weight: 300;
    text-transform: uppercase;
    color: #3a3846;
    line-height: 40px;
    margin-bottom: 40px;

    span {
        display: block;
        font-weight: 900;
    }

    @media only screen and (max-width: 1260px) {
        font-size: 26px;
        line-height: 32px;
    }

    @media only screen and (max-width: 1060px) {
        font-size: 22px;
        line-height: 28px;
        margin-top: 20px;
        margin-bottom: 20px;
    }

    @media only screen and (max-width: 875px) {
        font-size: 18px;
        margin-top: 10px;
        margin-bottom: 10px;
    }

    @media only screen and (max-width: 420px) {
        font-size: 16px;
        line-height: 20px;
    }
}

.scanintro-youtube {
    float: left;
    width: 55%;
    margin-top: 80px;

    @media only screen and (max-width: 700px) {
        width: 100%;
    }
}

.scanintro-youtube__container {
    position:relative;
    padding-bottom:56.25%;
    padding-top:30px;
    height:0;
    overflow:hidden;
    width: 100%;
    border: 1px solid #3a3846;
    box-sizing: border-box;

    iframe {
        position:absolute;
        top:0;
        left:0;
        width:100%;
        height:100%;
    }
}

.scanintro-cta {
    float: left;
    width: calc(45% - 30px);
    margin-left: 30px;
    margin-top: 80px;

    a.button {
        width: 430px;
        max-width: 90%;
        height: 70px;
        background: #BF9DA1;
        border: 1px solid #886C6F;
        color: #fff;
        text-transform: uppercase;
        font-weight: 900;
        display: block;
        text-align: center;
        line-height: 70px;
        box-sizing: border-box;
        border-radius: 5px;
        font-size: 32px;
        text-decoration: none;
        position: relative;
        left: 50%;
        transform: translateX(-50%);

        &:hover {
            background: #886C6F;
        }
    }

    @media only screen and (max-width: 700px) {
        margin-top: 20px;
        width: 100%;
        margin-left: 0;
    }
}

.scanintro-cta__title {
    font-size: 48px;
    line-height: 60px;
    text-transform: uppercase;
    font-weight: 300;
    color: #3a3846;
    text-align: center;
    margin-top: 80px;
    margin-bottom: 40px;

    span {
        display: block;
        font-weight: 900;
    }

    @media only screen and (max-width: 1060px) {
        margin-top: 50px;
    }

    @media only screen and (max-width: 875px) {
        margin-top: 20px;
    }
}

.scanintro-urgency {
    width: 100%;
    float: left;
    margin-top: 80px;

    @media only screen and (max-width: 700px) {
        margin-top: 30px;
    }
}

.scanintro-urgency__center {
    display: block;
    float: left;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    height: 120px;

    svg {
        width: 88px;
        height: auto;
        float: left;
        position: relative;
        margin-right: 10px;
        top: 50%;
        transform: translateY(-50%);

        @media only screen and (max-width: 1060px) {
            top: 0;
            transform: none;
        }

        @media only screen and (max-width: 875px) {
            width: 72px;
        }

        @media only screen and (max-width: 420px) {
            width: 56px;
        }

        @media only screen and (max-width: 360px) {
            width: 44px;
        }
    }

    @media only screen and (max-width: 1260px) {
        height: 100px;
    }

    @media only screen and (max-width: 1060px) {
        height: auto;
    }
}

.scanintro-urgency__title {
    font-size: 48px;
    line-height: 60px;
    text-transform: uppercase;
    font-weight: 300;
    color: #3a3846;
    float: left;

    span {
        font-weight: 900;
        display: block;
    }

    @media only screen and (max-width: 360px) {
        font-size: 18px;
        line-height: 22px;
    }
}

.scanintro-steps__title,
.scanintro-urgency__title,
.scanintro-cta__title {

    @media only screen and (max-width: 1260px) {
        font-size: 40px;
        line-height: 50px;
    }

    @media only screen and (max-width: 1060px) {
        font-size: 32px;
        line-height: 44px;
    }

    @media only screen and (max-width: 875px) {
        font-size: 26px;
        line-height: 36px;
    }

    @media only screen and (max-width: 420px) {
        font-size: 22px;
        line-height: 28px;
    }
}
