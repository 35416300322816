$widgets-indent__bottom: $indent__base;

.block-static-block,
.block-cms-link {
    &.widget {
        margin-bottom: $widgets-indent__bottom;
        .links & {
            margin-bottom: 0;
        }
    }
}

.block-cms-link-inline {
    &.widget {
        margin: 0;
    }
}
