/*
 *  Owl Carousel - Core
 */
.brand {
	background: #fafafa;
	position: relative;

	&-title {
		@extend .debondt-title;
		font-size: 24px;
		text-align: center;
		margin-top: 14px;
	}
}

#brand-slider {
	@extend .page-main;
	padding: 0
}

.owl-wrapper-outer {
	overflow: hidden;
}

.owl-carousel {
	display: none;
	width: 100%;
	-webkit-tap-highlight-color: transparent;
	/* position relative and z-index fix webkit rendering fonts issue */
	position: relative;
	z-index: 1;

	.owl-buttons {
		font-size: 0;
		background: red;
		height: 200px;
		width: 110px;
	}

	.owl-stage {
		position: relative;
		-ms-touch-action: pan-Y;
	}

	.owl-stage:after {
		content: ".";
		display: block;
		clear: both;
		visibility: hidden;
		line-height: 0;
		height: 0;
	}

	.owl-stage-outer {
		width: 100%;
		position: relative;
		overflow: hidden;
		/* fix for flashing background */
		-webkit-transform: translate3d(0px, 0px, 0px);
	}

	.owl-item {
		position: relative;
		min-height: 1px;
		float: left;
		-webkit-backface-visibility: hidden;
		-webkit-tap-highlight-color: transparent;
		-webkit-touch-callout: none;
	}
	.owl-item img {
		display: block;
		width: 100%;
		-webkit-transform-style: preserve-3d;
	}

	.owl-nav.disabled,
	.owl-dots.disabled {
		display: none;
	}

	.owl-nav .owl-prev,
	.owl-nav .owl-next,
	.owl-dot {
		cursor: pointer;
		cursor: hand;
		-webkit-user-select: none;
		-khtml-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
	}

	.owl-nav .owl-prev,
	.owl-nav .owl-next {
		font-size: 0;
		top: 50%;
		position: absolute;
		height: 140px;
		width: 77px;
		@include translate(0, -50%);
		background-size: 100%;

		&:hover {
			height: 120px;
			width: 66px;
		}
	}

	.owl-nav .owl-prev {
		left: 20px;
		background: url(../images/owl-arrow-left.svg);
		background-size: cover;

		&:hover {
			margin-left: 6px;
		}
	}

	.owl-nav .owl-next {
		right: 20px;
		background: url(../images/owl-arrow-right.svg);
		background-size: cover;

		&:hover {
			margin-right: 6px;
		}
	}

	&.owl-loaded {
		@extend .page-main;
		padding-left: 0;
		padding-right: 0;
		display: block;
		position: static;
	}

	&.owl-loading {
		opacity: 0;
		display: block;
	}

	&.owl-hidden {
		opacity: 0;
	}

	&.owl-refresh .owl-item {
		display: none;
	}

	&.owl-drag .owl-item {
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
	}

	&.owl-grab {
		cursor: move;
		cursor: grab;
	}

	&.owl-rtl {
		direction: rtl;
	}

	&.owl-rtl .owl-item {
		float: right;
	}
}

/* No Js */
.no-js .owl-carousel {
	display: block;
}

@include max-screen(1500px) {
	#brand-slider {
		padding: 0 10%;
		max-width: none;
		position: relative;
	}

	.owl-carousel {
		.owl-nav .owl-prev,
		.owl-nav .owl-next {
			height: 100px;
			width: 55px;

			&:hover {
				height: 80px;
				width: 44px;
			}
		}
	}
}

@include max-screen(660px) {
	#brand-slider {
		padding: 0 20%;
	}
}
