// BANNER AND CALLOUTS

.cms-index-index {
    .columns {
        .column.main {
            overflow: hidden;
        }
    }
}

.custom-slider .owl-carousel .owl-item {
    .banner-image {
        @include max-screen(920px) {
            height: 410px;
            overflow: hidden;
            display: block;

            img {
                width: auto;
                height: 100%;
                max-width: none;
                position: relative;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }

    }

    .custom_content {
        position: relative;
        line-height: 0;
        min-height: 410px;

        > img {
            width: 100%;
        }

        .homepage-banner-container {
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            bottom: 0;
        }

        .banner-logo {
            width: 100%;
            margin-top: 40px;
            text-align: center;

            img {
                display: inline-block;

                @include max-screen(920px) {
                    width: 260px;
                }

                @include max-screen(660px) {
                    width: 220px;
                }
            }
        }

        .banner-title {
            text-align: center;
            font-family: $font-family__base;
            letter-spacing: $default__letter-spacing;
            font-size: 72px;
            color: #fff;
            text-transform: uppercase;
            font-weight: 900;
            line-height: 1.5;
            margin-top: 80px;

            @include max-screen(1130px) {
                margin-top: 30px;
            }

            @include max-screen(920px) {
                font-size: 60px;
            }

            @include max-screen(660px) {
                font-size: 42px;
                line-height: 0.9;
            }
        }

        .banner-subtitle {
            font-family: 'Caveat';
            color: #fff;
            font-size: 100px;
            line-height: 1.5;
            text-align: center;
            letter-spacing: $condensed__letter-spacing;

            @include max-screen(1130px) {
                font-size: 80px;
            }

            @include max-screen(920px) {
                font-size: 60px;
            }

            @include max-screen(660px) {
                margin-top: 20px;
                font-size: 50px;
            }
        }
    }
}


.home-callout {
    position: relative;
    float: left;

    &.image-callout {
        overflow: hidden;

        img {
            max-width: none;
            margin: 0;
            opacity: 1;
            width: 100%;
            @include transition(0.2s);

        }
    }

    &.image-callout:hover {
        img {
            opacity: 0.8;
            width: 110%;
            margin: -5% 0 0 -5%;
            @include transition(0.2s);

        }
    }

    &.square {
        width: 25%;
        height: 0;
        padding-bottom: 25%;
    }

    &.wide {
        width: 50%;
        height: 0;
        padding-bottom: 25%;
    }

    &.pink {
        background: $secondary__color;
    }

    &.blue {
        background: $primary__color;
    }


    .callout-container {
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        position: absolute;

        .callout-title {
            font-size: 72px;
            font-family: $font-family__base;
            color: #fff;
            text-transform: uppercase;
            text-align: center;
            letter-spacing: $default__letter-spacing;
            font-weight: 900;

            span {
                font-weight: 100;
            }
        }

        .callout-title-hip {
            font-family: $font-family__base;
            color: #fff;
            font-weight: 300;
            font-size: 60px;
            padding-left: 30px;
            line-height: 60px;
        }

        .callout-subtitle {
            font-family: 'Caveat', Arial, Helvetica, sans-serif;
            font-size: 44px;
            color: #fff;
            text-align: center;
            line-height: 1;
            text-shadow: 2px 1px 2px rgba(0, 0, 0, .6);
        }
    }


    &.callout1 .callout-container {
        padding-top: 40%;

        .callout-subtitle {
            max-width: 60%;
            margin: auto;
        }
    }

    &.callout2 .callout-container {
        padding-top: 20%;

        .callout-title {
            font-size: 54px;
            text-align: left;
            padding-left: 30px;
            line-height: 54px;
        }
    }

    &.callout3 .callout-container {
        padding-top: 20%;

        .callout-title {
            text-align: left;
            padding-left: 30px;
            font-size: 50px;
            line-height: 56px;
        }
    }

    &.callout4 .callout-container {
        overflow: hidden;
        padding-top: 15%;

        .callout-title {
            width: 46%;
            color: $primary__color__darker;
            text-align: left;
            font-size: 50px;
            line-height: 55px;
            float: right;
        }
    }

    &.callout5 .callout-container {
        padding-top: 30%;

        .callout-title {
            text-align: left;
            font-size: 60px;
            padding-left: 30px;
            line-height: 60px;
        }
    }

    &.callout6 .callout-container {
        padding-top: 10%;

        .callout-subtitle {
            text-shadow: none;
            color: $primary__color__darker;
            font-size: 100px;
            letter-spacing: $default__letter-spacing;
            text-align: right;
            padding-right: 50px
        }
    }
}


// EDITORIAL PIECE
.home-editorial {
    overflow: hidden;
    width: 100%;
    float: left;
    margin-top: 50px;

    .home-half-container {
        float: left;
        width: 50%;
    }

    .home-text-location {
        height: 300px;
        background: $secondary__color;

        img {
            height: 300px;
            width: 300px;
            float: left;
        }

        .homepage-location-container {
            float: left;
            color: #fff;

            .homepage-location-title {
                @extend .debondt-title;
                font-size: 36px;
                line-height: 38px;
                margin: 40px 0 0 30px;
                color: #fff;
            }
        }
    }

    .home-text-container {
        height: 300px;
        width: 600px;
        padding-left: 20px;

        .company-text-title {
            @extend .debondt-title;
            font-size: 48px;
            line-height: 32px;
            margin-bottom: 60px;
        }

        .company-text-content {
            font-size: 18px;
            text-align: justify;
        }
    }

    .home-callout-container {
        height: 600px;
        color: #fff;
        position: relative;
        float: right;
        background: $primary__color;
        width: 100%;

        .home-callout-inner {
            float: right;
        }

        .company-callout-title {
            @extend .debondt-title;
            font-size: 50px;
            line-height: 54px;
            margin-top: 140px;
            color: #fff;
        }

        svg {
            margin: 30px 50px 0 0;
        }

    }
}


.homepage-citation {
    font-size: 72px;
    text-align: center;
    font-family: 'Caveat';
    margin: 50px 0;
    letter-spacing: $default__letter-spacing;


    &:before {
        font-family: "Times new Roman";
        content: "\201C";
        font-size: 180px;
        line-height: 72px;
        top: 48px;
        display: inline-block;
        position: relative;
        color: $secondary__color;
    }

    &:after {
        @extend .homepage-citation:before;
        content: "\201D";
        margin-left: 20px;
    }
}


#maincontent {
    &:after {
        visibility: hidden;
        display: block;
        font-size: 0;
        content: " ";
        clear: both;
        height: 0;
    }

    .home {
        float: left;
        width: 48%;

        &.home-ml {
            margin-left: 4%;
        }

        &-title {
            @extend .debondt-title;
            font-size: 24px;
            margin-bottom: 20px;
        }

        &-justify {
            text-align: left;
            font-size: 14px;
        }
    }
}

@include max-screen(1800px) {

    .home-callout {
        .callout-container {
            .callout-title {
                font-size: 60px;
            }

            .callout-title-hip {
                font-size: 42px;
                line-height: 1.1;
            }

            .callout-subtitle {
                font-size: 36px;
            }
        }

        &.callout1 .callout-container {
            .callout-subtitle {
            }
        }

        &.callout2 .callout-container {
            .callout-title {
                font-size: 46px;
            }
        }

        &.callout3 .callout-container {
            .callout-title {
                font-size: 46px;
                line-height: 50px;
            }
        }

        &.callout4 .callout-container {
            .callout-title {
                font-size: 42px;
                line-height: 46px;
            }
        }

        &.callout5 .callout-container {
            .callout-title {
                font-size: 46px;
            }
        }

        &.callout6 .callout-container {
            .callout-subtitle {
                font-size: 80px;
            }
        }
    }
}

@include max-screen(1580px) {

    .home-callout {
        .callout-container {
            .callout-title {
                font-size: 50px;
            }

            .callout-title-hip {
                font-size: 36px;
                line-height: 1.1;
            }

            .callout-subtitle {
                font-size: 30px;
            }
        }

        &.callout1 .callout-container {
            .callout-subtitle {
            }
        }

        &.callout2 .callout-container {
            .callout-title {
                font-size: 36px;
                line-height: 1.1;
            }
        }

        &.callout3 .callout-container {
            .callout-title {
                font-size: 36px;
                line-height: 1.1;
            }
        }

        &.callout4 .callout-container {
            .callout-title {
                font-size: 36px;
                line-height: 1.1;
                width: 48%;
            }
        }

        &.callout5 .callout-container {
            .callout-title {
                font-size: 36px;
                line-height: 1.1;
            }
        }

        &.callout6 .callout-container {
            .callout-subtitle {
                font-size: 60px;
            }
        }
    }

}


@include max-screen(1320px) {
    .home-callout {
        &.callout2 .callout-container {
            .callout-title {
                padding-left: 15px;
            }
        }
    }
}

@include max-screen(1280px) {
    .home-editorial {
        .home-half-container {
            width: 100%;
        }

        .home-callout-container {
            height: auto;
            width: 100%;

            .home-callout-inner {
                width: 100%;
                margin-bottom: 10px;
                float: right;
                height: auto;
            }

            .company-callout-title {
                margin-top: 20px;
                float: right;
                text-align: right;
                padding-right: 20px;
            }

            svg {
                float: left;
                margin-left: 20px;
            }
        }

        .home-text-container {
            width: 50%;
            float: left;
            box-sizing: border-box;
            padding: 20px;
            height: auto;

            .company-text-title {
                margin-bottom: 40px;
                line-height: 40px;
            }
        }

        .home-text-location {
            float: left;
            width: 50%;
        }
    }
}

@include max-screen(1200px) {
    .home-callout {
        .callout-container {
            .callout-title {
                font-size: 40px;
            }

            .callout-title-hip {
                font-size: 28px;

            }

            .callout-subtitle {
                font-size: 24px;
            }
        }

        &.callout2 .callout-container {
            .callout-title {
                font-size: 28px;
            }
        }

        &.callout3 .callout-container {
            .callout-title {
                font-size: 28px;

            }
        }

        &.callout4 .callout-container {
            .callout-title {
                font-size: 28px;

            }
        }

        &.callout5 .callout-container {
            .callout-title {
                font-size: 28px;
            }
        }

        &.callout6 .callout-container {
            .callout-subtitle {
                font-size: 60px;
            }
        }
    }

}

@include max-screen(1160px) {
    .home-editorial {
        .home-text-location {
            .homepage-location-container {
                .homepage-location-title {
                    margin-left: 15px;
                    font-size: 28px;
                }
            }
        }

        .home-callout-container {

            svg {
                width: 400px;
                height: 110px;
            }

            .company-callout-title {
                font-size: 42px;
                line-height: 44px;
            }
        }
    }

    .homepage-citation {
        font-size: 60px;

        &:before, &:after {
            font-size: 120px;
            top: 28px;
        }
    }
}

@include max-screen(1130px) {
    .homepage-banner {

        .banner-logo {
            img {
                width: 300px;
            }
        }

        .banner-title {
            margin-top: 30px;
        }

        .banner-subtitle {
            font-size: 80px;
        }
    }
}

@include max-screen(1010px) {
    .home-editorial {
        .home-text-location {
            position: relative;

            img {
                width: 100%;
                height: auto;
            }

            .homepage-location-container {
                .homepage-location-title {
                    position: absolute;
                    top: 0;
                    left: 0;
                    color: $primary__color__darker;
                    font-size: 44px;
                }
            }
        }
    }
}

@include max-screen(980px) {
    .home-callout {
        .callout-container {
            .callout-title {
                font-size: 32px;
            }

            .callout-title-hip {
                font-size: 24px;

            }

            .callout-subtitle {
                font-size: 20px;
            }
        }

        &.callout2 .callout-container {
            .callout-title {
                font-size: 24px;
            }
        }

        &.callout3 .callout-container {
            .callout-title {
                font-size: 24px;

            }
        }

        &.callout4 .callout-container {
            .callout-title {
                font-size: 24px;
                width: 55%;

            }
        }

        &.callout5 .callout-container {
            padding-top: 18%;

            .callout-title {
                font-size: 24px;
            }
        }

        &.callout6 .callout-container {
            .callout-subtitle {
                font-size: 50px;
                padding-right: 20px;
            }
        }
    }

}

@include max-screen(940px) {

    .home-editorial {
        .home-callout-container {

            svg {
                width: 350px;
            }

            .company-callout-title {
                font-size: 32px;
                line-height: 32px;
            }
        }
    }

    .homepage-citation {
        font-size: 48px;

        &:before, &:after {
            font-size: 90px;
            top: 14px;
        }

        &:after {
            margin-left: 12px;
        }
    }
}

@include max-screen($screen__m) {
    .home-callout {
        &.square {
            width: 50%;
            height: 0;
            padding-bottom: 50%;
        }

        &.wide {
            width: 50%;
            height: 0;
            padding-bottom: 50%;

            &.image-callout:hover {
                > img {
                    width: 210%;
                }
            }

            > img {
                width: 200%;
            }
        }

        .callout-container {
            .callout-title {
                font-size: 50px;
            }

            .callout-title-hip {
                font-size: 36px;
            }

            .callout-subtitle {
                font-size: 30px;
            }
        }

        &.callout1 .callout-container {
            .callout-subtitle {
            }
        }

        &.callout2 .callout-container {
            .callout-title {
                font-size: 36px;
            }
        }

        &.callout3 .callout-container {
            padding-top: 45%;

            .callout-title {
                font-size: 36px;
            }
        }

        &.callout4 .callout-container {
            padding-top: 50%;

            .callout-title {
                color: #fff;
                font-size: 36px;

                width: 95%;
            }
        }

        &.callout5 .callout-container {
            padding-top: 30%;

            .callout-title {
                font-size: 36px;

            }
        }

        &.callout6 .callout-container {
            .callout-subtitle {
                font-size: 80px;
            }
        }
    }

    .home-editorial {
        .home-callout-container {

            svg {
                margin: 0 0 0 50%;
                @include translate(-50%, 0);
            }

            .company-callout-title {
                width: 100%;
                padding: 0;
                margin: 0 0 20px;
                text-align: center;
            }
        }

        .home-text-container {
            width: 100%;
            padding: 50px 20px;

            .company-text-title {
                font-size: 36px;
                text-align: center;
            }
        }

        .home-text-location {
            width: 100%;
            height: auto;


            img {
                width: 50%;
            }

            .homepage-location-container {
                .homepage-location-title {
                    position: static;
                    color: #fff;
                    font-size: 36px;
                }
            }
        }
    }

    .homepage-citation {
        font-size: 36px;
        padding: 0 20px;

        &:before, &:after {
            font-size: 60px;
            top: 10px;
        }

        &:after {
            margin-left: 16px;
        }
    }

    #maincontent {
        .home {
            width: 100%;

            &-title {
                text-align: center;
                font-size: 36px;
                margin-bottom: 30px;
            }

            &.home-ml {
                margin-left: 0;
                margin-top: 20px;
            }
        }
    }

}

@include max-screen(660px) {
    .home-callout {

        .callout-container {
            .callout-title {
                font-size: 50px;
            }

            .callout-title-hip {
                font-size: 28px;
            }

            .callout-subtitle {
                font-size: 24px;
                max-width: 80%;
            }
        }

        &.callout1 .callout-container {
            padding-top: 30%;

            .callout-subtitle {
            }
        }

        &.callout2 .callout-container {
            .callout-title {
                font-size: 28px;
            }
        }

        &.callout3 .callout-container {
            padding-top: 40%;

            .callout-title {
                font-size: 28px;
            }
        }

        &.callout4 .callout-container {
            padding-top: 40%;

            .callout-title {
                color: #fff;
                font-size: 28px;

                width: 95%;
            }
        }

        &.callout5 .callout-container {
            padding-top: 20%;

            .callout-title {
                font-size: 32px;

            }
        }

        &.callout6 .callout-container {
            .callout-subtitle {
                font-size: 60px;
                max-width: none;
                width: 90%;
            }
        }
    }
}

@include max-screen(550px) {
    .home-editorial {
        .home-text-location {
            .homepage-location-container {
                .homepage-location-title {
                    font-size: 24px;
                    line-height: 30px;
                }
            }
        }
    }
}

@include max-screen(520px) {
    .home-callout {

        .callout-container {
            .callout-title {
                font-size: 28px;
            }

            .callout-title-hip {
                font-size: 20px;
                padding-left: 15px;
            }

            .callout-subtitle {
                font-size: 20px;
                width: 80%;
            }
        }

        &.callout1 .callout-container {
            padding-top: 30%;

            .callout-subtitle {
            }
        }

        &.callout2 .callout-container {
            .callout-title {
                font-size: 24px;
            }
        }

        &.callout3 .callout-container {
            padding-top: 40%;

            .callout-title {
                font-size: 24px;
                padding-left: 15px;
            }
        }

        &.callout4 .callout-container {
            padding-top: 40%;

            .callout-title {
                color: #fff;
                font-size: 24px;
                width: 95%;
            }
        }

        &.callout5 .callout-container {
            padding-top: 20%;

            .callout-title {
                font-size: 24px;
                padding-left: 15px;

            }
        }

        &.callout6 .callout-container {
            .callout-subtitle {
                font-size: 60px;
                padding-right: 10px;
            }
        }
    }
}

@include max-screen(440px) {
    .home-callout {

        .callout-container {
            .callout-title {
                font-size: 28px;
            }

            .callout-title-hip {
                font-size: 20px;
                padding-left: 10px;
            }

            .callout-subtitle {
                font-size: 20px;
                width: 80%;
            }
        }

        &.callout1 .callout-container {
            padding-top: 20%;

            .callout-subtitle {
            }
        }

        &.callout2 .callout-container {
            .callout-title {
                font-size: 20px;
                padding-left: 10px;
            }

            br:first-child {
                display: none;
            }
        }

        &.callout3 .callout-container {
            padding-top: 30%;

            .callout-title {
                font-size: 20px;
                padding-left: 10px;
            }
        }

        &.callout4 .callout-container {
            padding-top: 40%;

            .callout-title {
                color: #fff;
                font-size: 16px;
                width: 95%;
            }
        }

        &.callout5 .callout-container {
            padding-top: 12%;

            .callout-title {
                font-size: 20px;
                padding-left: 10px;

            }
        }

        &.callout6 .callout-container {
            .callout-subtitle {
                font-size: 40px;
                padding-right: 10px;
            }
        }
    }
}

@include max-screen(380px) {
    .home-editorial {
        .home-text-location {
            .homepage-location-container {
                .homepage-location-title {
                    font-size: 20px;
                    line-height: 24px;
                }
            }
        }
    }

    .home-editorial {
        .home-callout-container {
            svg {
                width: 280px;
            }
        }
    }
}

.cms-index-index .homepage-banner-container {
    display: block;

    @media only screen and (max-width: 767px) {
        grid-template-columns: 1fr 1fr;
    }

    & > div {
        box-sizing: border-box;
        max-width: 1280px;
        padding-left: 20px;
        padding-right: 20px;
        width: auto;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        gap: 20px;
        margin: 50px auto 20px;
    }
}

