//
//  Variables
//  ---------------------------------------------

$product-name-link__color                   : $text__color;
$product-name-link__color__active           : $text__color;
$product-name-link__color__hover            : $text__color;
$product-name-link__color__visited          : $text__color;

$product-name-link__text-decoration         : none;
$product-name-link__text-decoration__active : $link__hover__text-decoration;
$product-name-link__text-decoration__hover  : $link__hover__text-decoration;
$product-name-link__text-decoration__visited: $link__hover__text-decoration;

//
//  Product Lists
//  _____________________________________________

.products {
    margin: $indent__l 0;
}

.category-images {
  margin: 0;
  overflow: hidden;

  li {
    float: left;
    width: 23.5%;
    margin-right: 2%;
    height: 0;
    position: relative;
    margin-top: 0;
    margin-bottom: 2%;
    padding-bottom: 23.5%;

    &:nth-child(4n + 4) {
      margin-right: 0;
    }

    .content-container {
      display: block;
    }

    .category-name {
      position: absolute;
      bottom: 20px;
      left: 0;
      width: 100%;
      padding: 0 5px;
      box-sizing: border-box;
      color: $primary__color__darker;
      font-size: 17px;
      text-align: center;
      text-transform: uppercase;
      font-weight: 900;
      text-shadow: 1px 1px 3px #fff;
      text-align: center;

      @media only screen and (max-width: 1070px) {
        font-size: 15px;
        text-transform: none;
      }
    }

    @media only screen and (max-width: 680px) {
      width: 32.5%;
      margin-right: 1.25%;
      padding-bottom: 32.5%;
      margin-bottom: 1.25%;

      &:nth-child(4n + 4) {
        margin-right: 1.25%;
      }

      &:nth-child(3n + 3) {
        margin-right: 0;
      }

    }

    @media only screen and (max-width: 480px) {
      width: 48.5%;
      margin-right: 3%;
      padding-bottom: 48.5%;
      margin-bottom: 3%;

      &:nth-child(4n + 4) {
        margin-right: 3%;
      }

      &:nth-child(3n + 3) {
        margin-right: 3%;
      }

      &:nth-child(2n + 2) {
        margin-right: 0;
      }

    }
  }

  @media only screen and (max-width: 768px) {
    padding-top: 15px;
  }
}


form input.product-list-qty-input {
  height: 52px;
  width: 52px;
  line-height: 52px;
  text-align: center;
  float: right;
  border-radius: 3px;
  margin-top: 15px;
  margin-right: 15px;

  @media only screen and (min-width: 481px) and (max-width: 566px){
    float: left;
  }

  @media only screen and (min-width: 567px) and (max-width: 1027px){
    float: right;
  }
}

.products-grid {
  clear: both;
  border-top: 1px solid transparent;

  .product-list-sku {
    display:none;
  }

  .suggested-retail-price{
    display:none;
  }
}

.product {
    &-items {
        @extend .abs-reset-list;
    }

    &-item {
        @extend .abs-add-box-sizing;
        vertical-align: top;
        border-bottom: 1px dashed $primary__color__darker;
        margin-bottom: 20px;

        .products-grid & {
            display: inline-block;
            width: 48%;

            @media only screen and (max-width: 639px) {
              margin-right: 2%;

              &:nth-child(even) {
                margin-right: 0;
                margin-left: 2%;

              }
            }


          &-details {
            margin-bottom: 40px;
            z-index: 3;
            position: relative;
            text-align: center;
            padding: 0 2px;
            height: 116px;
          }

          &-hover {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 100%;
            z-index: 2;
            pointer-events: none;
            @include transition(bottom 0.2s ease-out)
          }

          &-name {
            overflow:hidden;
            height: 44px;
            text-align: center;

            a {
              font-size: 17px;
            }
          }

          .product-item-photo{
            display: block;
            width: 100%;
            height: 0;
            padding-bottom: 100%;
            position: relative;

              a {
                  display: block;
              }

            img{
              position: absolute;
              top: 60%;
              left: 50%;
              transform: translate(-50%, -50%);
            }
          }
        }

        &-name {
            @extend .abs-product-link;
            display: block;
            margin: $indent__xs 0 0;
            word-wrap: break-word;
            hyphens: auto;
            text-align: left;

            a {
              font-size: 24px;

              @include max-screen(1150px) {
                font-size: 21px;
              }

              @media only screen and (max-width: 850px) and (min-width: 769px) {
                font-size: 15px;
                text-transform: none;
              }
            }
        }

        &-info {
            max-width: 100%;
            width: 152px;


            .page-products & {
                width: 240px;
            }
        }

        &-actions {


            .actions-secondary {
                & > .action {
                    @extend .abs-actions-addto-gridlist;
                    &:before {
                        margin: 0;
                    }

                    span {
                        @extend .abs-visually-hidden;
                    }
                }
            }
        }

        &-description {
            margin: $indent__m 0;
        }

        .product-reviews-summary {
            .rating-summary {
                margin: 0 4px 0 0;
            }

            .reviews-actions {
                font-size: 18px;
                margin-top: 5px;

                a {
                  color: $primary__color__darker;
                }


                .view {
                  &:after {
                    content: "|";
                    display: inline-block;
                    margin-left: 10px;
                  }
                }
            }
        }

        .price-box {
            margin: $indent__s 0 10px;
            overflow: hidden;
            text-align: center;
            @include transition(all 0.2s ease-out 0.1s);

            .price {
                font-size: 18px;
                font-weight: 400;
                white-space: nowrap;
                color: $primary__color__darker;
            }

            .price-label {
                font-size: 14px;
                font-weight: 400;
                display: none;

            }

            .special-price {
              float: right;
              width: 50%;
              text-align: left;
              display: inline-block;
              padding-left: 3px;
              box-sizing: border-box;
              color: $primary__color__darker;
              .price {
                font-weight: 400;
              }

            }

            .old-price {
              float: right;
              width: 50%;
              text-align: right;
              display: inline-block;
              padding-right: 3px;
              box-sizing: border-box;
              color: $primary__color;

              .price {
                font-weight: 400;
                color: $primary__color;
              }
            }


        }

        .special-price,
        .minimal-price {
            .price {
                font-size: 18px;
                font-weight: $font-weight__bold;
            }

            .price-wrapper {
                display: inline-block;
            }

            .price-including-tax + .price-excluding-tax {
                display: block;
            }
        }

        .special-price {
            display: block;
        }

        .old-price {
            .price {
                font-weight: $font-weight__regular;
            }
        }

        .minimal-price {
            .price-container {
                display: block;
            }
        }

        .minimal-price-link {
            margin-top: 5px;

            .price-label {
                color: $link__color;
                font-size: 14px;
            }

            .price {
                font-weight: $font-weight__regular;
            }
        }

        .minimal-price-link,
        .price-excluding-tax,
        .price-including-tax {
            display: block;
            white-space: nowrap;
        }

        .price-from,
        .price-to {
            margin: 0;
        }

        .action.tocompare {
            @include lib-icon-font-symbol($icon-compare-empty);
        }

        .tocart {
            white-space: nowrap;
        }
    }

    &-count-simple {
      font-size: 17px;
      font-family: $font-family__base;
      color: #a49a9b;
      height: 30px;
      text-align: center;
    }
}

#amasty-shopby-product-list {
  .products-grid .product-item-actions .tocart {
     display:none;
  }

  .products-grid .product-item:hover {
    .suggested-retail-price {
      display:none;
    }

    .product-item-actions .tocart {
      display: block;
    }
  }

  .products-list {
    .product-count-simple {
      display: none;
    }

    a.tocart {
      padding-top: 17px;
      text-align: center;
    }

    .product-item-actions {
      display: block;

      .actions-primary {
        display: block;
      }

      .tocart {
        display: block;
        height: 52px;
        background: $quaternary__color;
        border-color: darken($quaternary__color, 5%);
        font-size: 16px;
        margin:15px auto 0 auto;
        width: auto;
        color: #333;
        float: right;

        &:hover {
          background: lighten($quaternary__color, 5%);
        }
      }
    }
  }
}

.products-grid {
  .widget-new-grid {

    .product-item {
      border-bottom: 0px;


      &-name {
        text-align: center;

        a {
          font-size: 18px;
        }
      }
    }
  }
}


.price-container {
    .price {
        font-size: 14px;
    }

    .price-including-tax + .price-excluding-tax,
    .weee {
        margin-top: $indent__xs;
    }

    .price-including-tax + .price-excluding-tax,
    .weee,
    .price-including-tax + .price-excluding-tax .price,
    .weee .price,
    .weee + .price-excluding-tax:before,
    .weee + .price-excluding-tax .price {
        font-size: 11px;
    }

    .weee {
        &:before {
            content: '('attr(data-label) ': ';
        }

        &:after {
            content: ')';
        }

        + .price-excluding-tax {
            &:before {
                content: attr(data-label) ': ';
            }
        }
    }
}

.products-list {
    .product {
        &-item {
            display: table;
            width: 100%;
            margin-bottom: 40px;
            padding-bottom: 20px;

            &-col-left {
              float: left;
              width: 100%;

              .product-list-sku {
                margin-top: 40px;
                font-size: 18px;
              }
            }

            &-col-right {
              float: left;
              width: 100%;
            }

            &-info {
                display: block;
                width: 100%;
            }

            &-photo {
                display: block;
                float: left;
                padding: 0 $indent__l 0 0;
                vertical-align: top;
            }

            &-details {
                display: block;
                float: left;
                width: calc(100% - 270px);
                vertical-align: top;
            }


            .price-box {
              text-align: right;
              min-height: 59px;

              .special-price {
                width: auto;

              span.price-final_price {
                &:before {
                  content: none;
                }
              }

                .price {
                  font-size: 42px;
                  font-weight: 900;
                }
              }
              .old-price {
                width: auto;
                position: relative;
                top: 26px;
                margin-right: 10px;

                span.price-final_price {
                  &:before{
                    content: none;
                  }
                  .price {
                    font-size: 18px;
                    font-weight: 400;
                  }
                }
              }

              span.price-final_price {

                .price {
                  font-weight: 900;
                  font-size: 42px;
                }
              }

            }

            .suggested-retail-price {
              text-align: right;
              font-size: 18px;
            }
        }
    }

    .product-image-wrapper {
        @extend .abs-reset-image-wrapper;
    }
}
.products-list .product-item .price-box span.price-final_price:before {
  display: inline-block;
  font-size: 18px;
  margin-right: 10px;
}

html[lang="nl-NL"] .products-list .product-item .price-box span.price-final_price:before {content: "Uw prijs excl. btw:";}
html[lang="de-DE"] .products-list .product-item .price-box span.price-final_price:before {content: "Ihr Preis exkl. MWST";}
html[lang="en-US"] .products-list .product-item .price-box span.price-final_price:before {content: "Your price excl. Tax:";}


//
//  Mobile
//  _____________________________________________

@include max-screen($screen__s) {
    .products-list .product {
        &-item {
            table-layout: fixed;

            &-photo {
                padding: 0 $indent__s $indent__s 0;
                width: 30%;
            }
        }
    }

  #amasty-shopby-product-list .products-grid .product-item:hover .product-item-actions{
    .tocart {
      display:none;
    }

    .suggested-retail-price {
      display:block;
    }
  }

  .products-grid .product-item {
    border-bottom:0;

    &:hover .tocart {
      display:none;
    }

    .product-list-qty-input{
      display:none;
    }

    .db-option-selector-container{
      display: none;
    }
  }

  .products-grid {
    .debondt-new-label{
      display:none;
    }
  }
}

@include min-screen($screen__s) {


    .product {
        &-item {
          position: relative;
            .products-grid & {
                margin-bottom: $indent__l;
            }

            &-actions {
                display: none;


                .products-grid & {
                    margin: $indent__s 0;
                }

                .actions-primary + .actions-secondary {
                    display: table-cell;
                    padding-left: 5px;
                    white-space: nowrap;
                    width: 50%;
                    & > * {
                        white-space: normal;
                    }
                }

                .actions-primary {
                    display: table-cell;
                }
            }
        }
    }

    .products-grid .product-item {
      border-bottom:0;
      padding:0 6px;

      .product-count-simple{
        padding-top:5px;
      }

      .db-option-selector-container{
        display: none;
      }

      input.product-list-qty-input{
        display:none;
      }

      .product-item-actions button.action.tocart.primary{
        width:100%;
      }



      &:hover {

        .product-item-hover {
          position: absolute;
          top: 0;
          right: 0;
          left: 0;
          bottom: 0;
          background: transparentize($tertiary__color, 0.8);
          z-index: 2;
        }

        .product-item-name {
          & > a {
            color: #fff;
            z-index: 4;
          }
        }

        .product-item-details {
          z-index: 3;
        }

        .product-count-simple {
          visibility: hidden;
        }

        .price-box {
          position: relative;
          z-index: 4;
          top: -25px;
          margin: 0;
          height: 34px;
          line-height: 34px;

          .price-label, .old-price {
            color: #fff;
          }

          .price {
            color: #fff;
            font-size: 20px;
          }
        }

        .product-item-actions {
          display: inline-block;
          text-align: center;
          width: 100%;
          position: relative;
          top: -30px;

          .actions-primary {
            display: inline-block;

            .tocart {
                background: none;
                border-color: $secondary__color;
                background: $secondary__color;
                color: #333;
                font-size: 16px;
                border-radius: 5px;
                font-weight: 600;
                position: relative;
                display: block;
                line-height: 24px;
                overflow: hidden;
                height: 40px;

                &:hover {
                    border-color: $secondary__color;
                }
            }
          }
        }
      }



    }

    .products-grid .product-item {
        width: 33.3333%;
    }

    .page-products,
    .page-layout-1column,
    .page-layout-3columns,
    .page-products.page-layout-1column,
    .page-products.page-layout-3columns {
        .products-grid {
            .product-item {
                width: 33.3333%;
            }
        }
    }
}

//
//  Desktop
//  _____________________________________________


@media only screen and (min-width: 481px) and (max-width: 567px){
  #amasty-shopby-product-list {
    .item {
      .product-item-actions {
        .actions-primary {
          .tocart {
            float: left;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 782px){
  #amasty-shopby-product-list {
    .item {
      .product-item-actions {
        .actions-primary {
          .tocart {
            width: auto;
          }
        }
      }
    }
  }
}


@media only screen and (min-width: 607px) and (max-width: 1027px){
  #amasty-shopby-product-list {
    .item {
      .product-item-actions {
        .actions-primary {
          .tocart {
            float: right;
          }
        }
      }
    }
  }
}


@include min-screen($screen__m) {
    .page-products {
        .products-grid {
            .product-item {
                width: 33.3333%;
            }
        }
    }

    .page-products.page-layout-1column {
        .products-grid {
            .product-item {
                width: 25%;
            }
        }
    }

    .page-products.page-layout-3columns {
        .products-grid {
            .product-item {
                width: 50%;
            }
        }
    }
}
@include min-screen($screen__l) {
    .products-grid {
        .product-item {
            width: 20%;
        }
    }

    .page-layout-1column {
        .products-grid {
            .product-item {
                width: 16.6666%;
            }
        }
    }

    .page-layout-3columns {
        .products-grid {
            .product-item {
                width: 25%;
            }
        }
    }

    .page-products {
        .products-grid {
            .product-items {
                margin: 0;
            }

            .product-item {
                margin-left: calc((100% - 4 * 23.233%) / 3);
                padding: 0;
                width: 23.233%;

                &:nth-child(4n + 1) {
                    margin-left: 0;
                }
            }
        }
    }

    .page-products {
        &.page-layout-1column {
            .products-grid {
                .product-item {
                    margin-left: 0;
                    width: 20%;
                }
            }
        }

        &.page-layout-3columns {
            .products-grid {
                .product-item {
                    margin-left: 1%;
                    width: 32.667%;

                    &:nth-child(3n) {
                        margin-left: 1%;
                    }

                    &:nth-child(3n + 1) {
                        margin-left: 0;
                    }
                }
            }
        }
    }
}

@include max-screen(1027px) {
    .products-list {
        .product {
            &-item {


                &-col-left {
                    width: 100%;


                  .product-list-sku {
                    margin: 20px 0;
                  }
                }

                &-col-right {
                    width: 100%;
                }

                &-info {

                }

                &-photo {

                }

                &-details {

                }


                .price-box {
                    text-align: left;
                    margin-top: 0;
                    display: inline-block;

                  .special-price {


                  span.price-final_price {
                    &:before {

                    }
                  }

                    .price {

                    }
                  }
                  .old-price {


                    span.price-final_price {
                      &:before{

                      }
                      .price {

                      }
                    }
                  }

                  span.price-final_price {

                    &:before {

                    }

                    .price {

                    }
                  }

                }

                .suggested-retail-price {
                    text-align: left;
                }
            }
        }

        .product-image-wrapper {

        }
    }
}

@include max-screen($screen__m) {
    .products-list .product-reviews-summary .rating-summary,
    .products-list .product-reviews-summary .reviews-actions {
        display: block;
        margin-top: 0;
    }

    .products.wrapper.list .product-reviews-summary {
        display: block;
        margin: 0;
    }
}

@media (min-width: 640px) and (max-width: 1150px){
  .products-grid {
    .widget-new-grid .product-item-name a {
      font-size: 17px;
    }
    .product-count-simple {
      font-size: 15px;
    }
  }
}

@include max-screen(500px) {
  .products-grid {
    .product-item {
      border-bottom: 0;

      .product-count-simple {
        font-size: 14px;
      }

      &-name {
        text-align: center;

        a {
          font-size: 14px;
        }
      }

      .price-box {
        .price-label {
          font-size: 14px;
        }

        .special-price .price {
          font-size: 14px;
        }
        .old-price .price {
          font-size: 14px;
        }
        .price {
          font-size: 14px;
        }
      }
    }
  }
  .product-item .product-item-details .product-item-name a {
    font-size: 14px;
  }
}

@include max-screen(480px) {
    #amasty-shopby-product-list {

        .item {
            .product-item-actions {
                .actions-primary {
                    .tocart {
                      width: 80%;
                      float:right;
                    }
                }
              }
            .product-item-actions {
                .actions-primary {
                    .tocart {
                      display: none;
                    }
                }
              }
        }
    }
    .products-list {

        .product-item-info {
            width: 100%;
            display: block;
        }

        .product-item-photo, .product-item-details {
            display: block;
            width: 100%;
            text-align: center;
        }

        .product-image-container {
            width: 100%!important;
        }

        .product-item {
            .suggested-retail-price {
                text-align: center;
            }

            &-name {
                display: inline-block;
                text-align: center;

                a {
                    line-height: 36px;
                }
            }

            .price-box {
                text-align: center;
            }
        }

        .product-reviews-summary {
            display: block;

            .rating-summary {
                display: inline-block;
            }

            .reviews-actions {
                display: inline-block;
                margin-top: 0;
            }
        }

    }
}

@include max-screen($screen__s) {
    .products-grid {
        .widget-new-grid {
            .product-item-actions {
                display: none;
            }
        }
    }
}

@include max-screen(380px) {
    .products-grid {
      .widget-new-grid {
        .product-item {
          .price-box {
              .price-label {
                  display: none;
              }

          }
        }
      }
    }
}
.cms-index-index .product-item .price-box .old-price {
  float: none;
  width: auto;
  margin-left: 5px;
  text-align: left;
}
