.breadcrumbs {
    @include lib-breadcrumbs(
    $_breadcrumbs-font-size: 18px,
    $_icon-font: debondt,
    $_breadcrumbs-icon-font-content: "\e409",
    $_icon-font-size: 26px,
    $_icon-font-color: $primary__color__darker,
    $_icon-font-vertical-align: middle,
    $_breadcrumbs-current-color: $primary__color__darker,
    $_breadcrumbs-link-color: $primary__color__darker,
    $_breadcrumbs-link-color-visited: $primary__color__darker
    );

    margin: 20px auto;

    .item {
        color: $primary__color;
      font-weight: 400;

      &:after {
        position: relative;
        top: -2px;
        padding: 0 10px;
      }
    }

    .item:not(:last-child):after {
      font-weight: 400;
    }
}

@include max-screen($screen__m) {
    .breadcrumbs {display: none;}
}
