a.action.primary,
button {
    border-radius: 3px;
}
button {
    &:active {
        box-shadow: $button__shadow;
    }
}
a.action.primary {
    @include lib-link-as-button();
}
.action.primary {
    @include lib-button-primary(
        $_button-background-active    : lighten($secondary__color, 5),
        $_button-color-active         : $secondary__color__dark,
        $_button-border-active        : 1px solid $secondary__color__dark
    )

}


.box-tocart {
    .action.primary {
        @include lib-button-primary(
            $_button-background           : $secondary__color,
            $_button-border               : 1px solid $secondary__color,

            $_button-background-hover     : $secondary__color__dark,
            $_button-border-hover         : 1px solid $secondary__color__dark,

            $_button-background-active    : lighten($secondary__color, 5),
            $_button-color-active         : $secondary__color__dark,
            $_button-border-active        : 1px solid $secondary__color__dark
            );

            text-transform: uppercase;
            letter-spacing: $default__letter-spacing;

            span {
                font-size: 24px;
            }

    }
}

 .checkout {
     .action.primary {
         @include lib-button-primary(
             $_button-background           : $quaternary__color,
             $_button-border               : 1px solid $quaternary__color,

             $_button-background-hover     : $quaternary__color,
             $_button-border-hover         : 1px solid $quaternary__color,

             $_button-background-active    : lighten($quaternary__color, 5),
             $_button-color-active         : $quaternary__color,
             $_button-border-active        : 1px solid $quaternary__color
             );

            border-radius: 5px;

             span {
                 font-size: 16px;
                 color: #333;
             }
     }
 }
