// Mixins
@import "mixins/media-queries";
@import "mixins/transforms";
@import "mixins/animation";

// Vendor libs
@import 'vendor/normalize/lib'; // Normalize.css
@import 'vendor/magento-ui/lib'; // Magento UI

// Theme variables
@import 'variables';
@import 'variables-debondt';

// Reset default styles with magento-reset
@include lib-magento-reset();

// Theme blocks
@import 'blocks/extends';
@import 'blocks/typography';
@import 'blocks/layout';
@import 'blocks/tables';
@import 'blocks/messages';
@import 'blocks/navigation';
@import 'blocks/tooltips';
@import 'blocks/loaders';
@import 'blocks/forms';
@import 'blocks/icons';
@import 'blocks/buttons';
@import 'blocks/sections';
@import 'blocks/pages'; // Theme pager
@import 'blocks/actions-toolbar';
@import 'blocks/breadcrumbs';
@import 'blocks/popups';
@import 'blocks/price';
@import 'blocks/scan-input';
@import 'blocks/camera-input';
//@import 'blocks/gallery';
@import 'blocks/sooqr';
@import 'blocks/header';
@import 'blocks/homepage';
@import 'blocks/cmsblocks';
@import 'blocks/owl-carousel';
@import 'blocks/colorgroup-swatches';
@import 'blocks/account_questionair';
@import 'blocks/algemene-voorwaarden';


// Components styles (modal/sliding panel)
@import 'blocks/components/modals'; // from lib
@import 'blocks/components/modals_extend'; // local

// Modules
@import '../Magento_AdvancedCheckout/css/source/module';
@import '../Magento_AdvancedSearch/css/source/module';
@import '../Magento_Braintree/css/source/module';
@import '../Magento_BraintreeTwo/css/source/module';
@import '../Magento_Bundle/css/source/module';
@import '../Magento_Catalog/css/source/module';
@import '../Magento_CatalogEvent/css/source/module';
@import '../Magento_CatalogSearch/css/source/module';
@import '../Magento_Checkout/css/source/module';
@import '../Magento_Customer/css/source/module';
@import '../Magento_Downloadable/css/source/module';
@import '../Magento_GiftCard/css/source/module';
@import '../Magento_GiftCardAccount/css/source/module';
@import '../Magento_GiftMessage/css/source/module';
@import '../Magento_GiftRegistry/css/source/module';
@import '../Magento_GiftWrapping/css/source/module';
@import '../Magento_GroupedProduct/css/source/module';
@import '../Magento_Invitation/css/source/module';
@import '../Magento_LayeredNavigation/css/source/module';
@import '../Magento_Msrp/css/source/module';
@import '../Magento_MultipleWishlist/css/source/module';
@import '../Magento_Multishipping/css/source/module';
@import '../Magento_Newsletter/css/source/module';
@import '../Magento_Paypal/css/source/module';
@import '../Magento_ProductVideo/css/source/module';
@import '../Magento_Review/css/source/module';
@import '../Magento_Reward/css/source/module';
@import '../Magento_Rma/css/source/module';
@import '../Magento_Sales/css/source/module';
@import '../Magento_SalesRule/css/source/module';
@import '../Magento_SendFriend/css/source/module';
@import '../Magento_Swatches/css/source/swatches';
@import '../Magento_Theme/css/source/module';
@import '../Magento_Vault/css/source/module';
@import '../Magento_Weee/css/source/module';
@import '../Magento_Wishlist/css/source/module';
@import '../Ves_Megamenu/css/source/module';
@import '../VladimirPopov_WebForms/css/source/module';
@import '../Amasty_Shopby/css/source/module';
@import '../BigBridge_DeBondt/css/source/module';
@import '../BigBridge_DomainSwitcher/css/source/module';
@import '../Amasty_Blog/css/source/module';
@import '../BigBridge_Prijslijst/css/source/module';
@import '../BigBridge_ProductPageDiscount/css/source/module';
@import '../BigBridge_Scanner/css/source/module';
@import '../BigBridge_RecentlyOrdered/css/source/module';


// Widgets
@import '../Magento_AdvancedCheckout/css/source/widgets';
@import '../Magento_Banner/css/source/widgets';
@import '../Magento_Catalog/css/source/widgets';
@import '../Magento_CatalogEvent/css/source/widgets';
@import '../Magento_Cms/css/source/widgets';
@import '../Magento_MultipleWishlist/css/source/widgets';
@import '../Magento_VersionsCms/css/source/widgets';

.grecaptcha-badge {
  display: none;
}
