.checkout-agreement {
  overflow: hidden;

  > input[type="checkbox"] {
    width: 13px;
    height: 13px;
    margin-top: 5px;
    margin-right: 5px;
    float: left;
  }

  > label {
    float: left;
    width: calc(100% - 18px);

    button {
      text-align: left;
    }
  }
}