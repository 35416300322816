//
//  Variables
//  _____________________________________________

$checkout-shipping-policy-action__color        : $link__color;
$checkout-shipping-policy-tooltip__width       : 420px;
$checkout-shipping-policy-tooltip-mobile__width: 300px;

//
//  Common
//  _____________________________________________

.checkout-shipping-method {
    position: relative;
}

.shipping-policy-block {
    &.field-tooltip {
        top: 12px;

        .field-tooltip-action {
            color: $checkout-shipping-policy-action__color;
            cursor: pointer;

            &:before {
                display: none;
            }

            span {
                @extend .abs-visually-hidden-reset;
            }
        }

        .field-tooltip-content {
            @extend .abs-add-box-sizing;
            @extend .abs-checkout-tooltip-content-position-top;
            width: $checkout-shipping-policy-tooltip__width;
            top: $indent__l;
        }
    }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__s) {
    .shipping-policy-block {
        &.field-tooltip {
            margin-bottom: $indent__base;
            position: relative;
            right: auto;
            top: auto;

            .field-tooltip-content {
                width: $checkout-shipping-policy-tooltip-mobile__width;
                right: auto;

                &:before,
                &:after {
                    right: auto;
                }
            }
        }
    }
}
