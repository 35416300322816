.vertnav-menu {
  @media only screen and (max-width: 767px) {
    display: none;
  }

  .categorie-title {
    font-size: 18px;
      font-weight: 900;
    color: $primary__color;
  }

  .bordered-cat-menu {
    ul {
      padding: 0;
      margin: 0;
      position: relative;
      width: 100%;
      overflow: hidden;
      display: inline-block;
    }

    > ul {
      padding-left: 0;
      margin: 0;
      list-style: none;

      > li {
        font-weight: 900;

      }

      .open-submenu {
        line-height: 25px;
        height: 25px;
        overflow: hidden;

        a {
          display: block;
          width: calc(100% - 25px);
          float: left;
        }

        &:after {
          content: "\e5cf";
          font-family: 'debondt', arial, Helvetica, sans-serif;
          display: block;
          float: right;
          cursor: pointer;
          height: 25px;
          width: 25px;
          line-height: 25px;
          font-size: 25px;
          text-align: center;
          transition: transform 0.2s;
        }

        &.active {
          &:after {
            transform: rotate(180deg);
          }
        }
      }

      .submenu {
        overflow: hidden;
        height: 0;
        transition: height 0.2s ease-out;
      }

      a {
        color: $primary__color;
        font-size: 14px;
      }

      .open-submenu.active a {
        text-decoration: underline;
        //color: $secondary__color;
        font-weight: 900;
      }

      li.level2 {
        & > a {
          text-decoration: none;
          font-size: 15px;
          text-transform: none;
        }

        &.active > a {
          font-weight: 900;
        }
      }

      ul {
        list-style: none;
        margin: 0;
        padding-left: 20px;
        position: relative;
        box-sizing: border-box;
        width: 100%;
      }
    }
  }
}
