//
//  Theme variables
//  _____________________________________________

//  Messages
$message-global-note__background          : $color-yellow-light2;
$message-global-note__border-color        : $color-yellow-light3;
$message-global-note__color               : $text__color;

$message-global-note-link__color          : $link__color;
$message-global-note-link__color-hover    : $link__hover__color;
$message-global-note-link__color-active   : $link__active__color;

$message-global-caution__background       : $color-red9;
$message-global-caution__border-color     : none;
$message-global-caution__color            : $color-white;

$message-global-caution-link__color       : $link__color;
$message-global-caution-link__color-hover : $link__hover__color;
$message-global-caution-link__color-active: $link__active__color;

// Header
$header__background-color                 : false;
$header-icons-color                       : $color-gray56;
$header-icons-color-hover                 : $color-gray20;

$addto-color                              : $text__color__muted;
$addto-hover-color                        : $primary__color;

$minicart-icons-color                     : $header-icons-color;
$minicart-icons-color-hover               : $header-icons-color-hover;

$button__shadow                           : inset 0 2px 1px rgba(0, 0, 0, 0.12);

$h1__margin-bottom__desktop               : $indent__xl;

// Footer
$footer__background-color                 : false;

//
//  Common
//  _____________________________________________

body {
    background-color: $page__background-color;
}

body.cms-page-view .column.main {
  font-size: 14px;
  line-height: 30px;
  max-width: 100%;
}

//
//  Header
//  ---------------------------------------------

.page-header {
    background-color: $header__background-color;
    border-bottom: 1px solid $border-color__base;
    margin-bottom: $indent__base;
    position: relative;
    height: 168px;

  .switcher {
    display: none;
  }
}

.header {
    &.content {
        @extend .abs-add-clearfix;
        padding-top: $indent__s;
        position: relative;
    }
}

.logo {
    float: left;
    margin: 0 0 $indent__s $indent__xl;
    max-width: 240px;
    position: relative;
    z-index: 5;

    svg {
        max-width: 100%;
    }

    img {
        display: block;
    }

    .page-print & {
        float: none;
    }
}

.page-main {
    & > .page-title-wrapper {
        .page-title + .action {
            margin-top: $indent__l;
        }
    }
}

.action.skip {
    &:not(:focus) {
        @extend .abs-visually-hidden;
    }

    &:focus {
        background: $color-gray94;
        padding: $indent__s;
        box-sizing: border-box;
        left: 0;
        position: absolute;
        text-align: center;
        top: 0;
        width: 100%;
        z-index: 15;
    }
}


//
//  Sticky elements
//  ---------------------------------------------

@include min-screen(769px) {

    .filterbar-container.sticky-bar {
        box-sizing: border-box;
        margin-left: auto;
        margin-right: auto;
        max-width: 1280px;
        padding-left: 20px;
        padding-right: 20px;
        width: auto;
        margin: 10px auto 0;
        border: 0px;

        .block.filter {
            .block-title {
                display: none;
            }
        }

      .sorter{
        margin-top:0;

        .sorter-label {
          display: none;
        }
      }

      .modes {
        margin-top:5px;
      }
    }

    .sticky-position-keeper {

        &:after {
            clear: both;
            content: ' ';
            display: block;
            height: 0;
            overflow: hidden;
            visibility: hidden;
        }
    }
    .page-header {
        .sticky-container {
            width: 100%;

        }
    }
    .page-header.sticky {
      .sticky-container {
        position: fixed;
        top: 0;
        width: 100%;
        z-index: 9999;
        background: #fff;
        transform: translate3d(0,0,0);
        -webkit-box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.2);
           -moz-box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.2);
                box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.2);

          @media screen and (orientation: landscape) and (max-width: 1025px) {
              position: static;
              transform: none;
          }

          .block-customer-mini-login {
              top: 5px;
            }

        .header {
          &.panel {
            padding-top: 0;
            padding-bottom: 0;
            height: 0;
            overflow: hidden;
          }

          &.content {
            padding-top: 0;
              padding-bottom: 8px;

            .logo {
              margin: 0 auto 0 0 ;
              max-width: 166px;
              top: 10px;
            }

          }
        }

        > .header-main-usp {
          height: 0;
          overflow: hidden;
        }
      }
    }
}

//
//  Account sign up block
//  ---------------------------------------------

.account-block {
  background: $primary__color;
  color: #fff;
  z-index: 0;


  &-container {
    @extend .page-main;
  }

  &-title {
    @extend .debondt-title;
    font-weight: 900;
    font-size: 24px;
    margin: 20px 0 30px;
    text-align: center;
      color: white;
  }

  &-action {
    font-size: 18px;
    font-weight: 900;
    margin: 0 0 50px;
    text-align: center;

    .account-block-animation {
      display: inline-block;
      height: 58px;
      position: relative;
      cursor: pointer;
      z-index: 0;

      a {
          color: #fff;
          text-decoration: none;
          height: 58px;
          display: inline-block;
      }

      .sign-up-button {
        letter-spacing: $default__letter-spacing;
        color: #fff;
        border: 4px solid #fff;
        padding: 12px 15px;
        margin-left: 50px;
        overflow: hidden;
        display: block;
        float: left;
          border-radius: 30px;
        top: 0;
        position: relative;
        @include transition-timing-function(cubic-bezier(0.2, 1, 0.3, 1));
        @include transition-delay(0.1s);

        &:before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 150%;
          height: 100%;
          background: $secondary__color__dark;
          z-index: -1;
          @include transition(transform 0.3s, opacity 0.3s, background-color 0.3s);
          @include transform-origin(0% 100%);
          @include transform(rotate3d(0, 0, 1, -45deg) translate3d(0, -3em, 0));
        }
      }

      .sign-up-text {
          display: block;
          float: left;
          height: 58px;
          line-height: 58px;
      }

      .animated-border-line {
        display: inline-block;
        width: 93%;
        height: 4px;
        background: #fff;
        position: absolute;
        bottom: 1px;
        left: 0;
        z-index: -1;

        &:before {
          content: "";
          height: 4px;
          width: 0%;
          display: block;
          background: $secondary__color;
          @include transition-timing-function(cubic-bezier(0.2, 1, 0.3, 1));
          @include transition(width 0.3s)
        }
      }

      &:hover {
        .sign-up-button {
          text-decoration: none;
          border-color: $secondary__color;

          &:before {
            background-color: $secondary__color;
            @include transform(rotate3d(0, 0, 1, 0deg));
            @include transition-timing-function(cubic-bezier(0.2, 1, 0.3, 1));
            @include transition-delay(0.1s);
          }
        }
        .animated-border-line {
          &:before {
            width: 100%;
            display: block;
          }
        }
      }
    }
  }
}


//
//  Global notice
//  ---------------------------------------------

.message.global {
    p {
        margin: 0;
    }

    &.noscript,
    &.cookie {
        @include lib-message($_message-type: global-note);
        margin: 0;
    }

    &.cookie {
        bottom: 0;
        left: 0;
        position: fixed;
        right: 0;
        z-index: 3;

        .actions {
            margin-top: $indent__s;
        }
    }

    &.demo {
        @include lib-message($_message-type: global-caution);
        margin-bottom: 0;
        text-align: center;
    }
}

//
//  Footer
//  ---------------------------------------------

.page-footer {
    background-color: $footer__background-color;
    margin-top: auto;
}

.footer {
      &.content {
      overflow: hidden;
        margin-top: 25px;
        padding-top: 25px;

        .block {
            float: left;
            width: 100%;
            font-size: 14px;
            box-sizing: border-box;

            &.block-social a {
                display: block;
                margin-top: 20px;
            }

            &-title {
              @extend .debondt-title;
            }

            svg {display: block;}

            address {
              font-family: $font-family__base;
              margin-top: 20px;

              span {
                display: inline-block;
                min-width: 80px;
                font-weight: 900;
              }
            }

            .social-icon {
              float: left;

              &:before {
                font-family: 'debondt';
                text-decoration: none;
                color: $primary__color__darker;
                font-size: 60px;
              }

              &.facebook:before {content: "\e90a";}
              &.twitter:before {content: "\e90e";}
              &.instagram:before {
                content: "\e910";
                font-size: 45px;
                margin: 0 6px 0 9px;
                display: block;
                position: relative;
              }
              &.pinterest:before {content: "\e90c";}

              &:hover:before {
                color: $primary__color;
              }
            }


            ul {
              margin-top: 10px;
              li {
                line-height: 36px;

                a {
                  color: $primary__color;
                }
              }
            }
        }

        ul {
            @extend .abs-reset-list;
        }

        .links {
            & > li {
                margin: 0 0 8px;
            }
        }

        .switcher-store {
            margin: 0 0 30px;
        }
    }

    &-collumn {
      float: left;
    }

    &-left {
      width: 30%;
      margin-right: 4%;
    }
    &-center {
      width: 38%;
      margin-right: 4%;
    }
    &-right {
      width: 24%;
    }
}
.copyright-container {
    background: $primary__color;

  .copyright-inner {
    @extend .page-main;

    .copyright {
      height: 36px;
      line-height: 36px;
      color: #fff;
    }
  }
}

.page-header,
.page-footer {
    .switcher {
        margin-right: 10px;

        .options {
            @include lib-dropdown(
                $_dropdown-actions-padding           : 0,
                $_dropdown-list-item-padding         : 0,
                $_dropdown-toggle-icon-content       : $icon-down,
                $_dropdown-toggle-active-icon-content: $icon-up,
                $_icon-font-text-hide                : true,
                $_icon-font-size                     : 22px,
                $_icon-font-line-height              : 22px,
                $_dropdown-list-min-width            : 160px
            );

            ul.dropdown {
                a {
                    display: block;
                    padding: 8px;

                    &:hover {
                        text-decoration: none;
                    }
                }
            }
        }

        li {
            font-size: $font-size__s;
            margin: 0;
        }

        .label {
            @extend .abs-visually-hidden;
        }

        strong {
            font-weight: $font-weight__regular;
        }
    }
}

//
//  Widgets
//  ---------------------------------------------

.sidebar {
    .widget.block:not(:last-child),
    .widget:not(:last-child) {
        @extend .abs-margin-for-blocks-and-widgets;
    }
}

.widget {
    clear: both;

    .block-title {
        @extend .abs-block-title;
    }
}

.page-header,
.page-footer {
    .widget.block {
        margin: $indent__base 0;
    }
}

.no-display {
    @extend .abs-no-display;
}

//
//  Calendar
//  ---------------------------------------------

.ui-datepicker {
    td {
        padding: 0;
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen(769px) {

    html,
    body {
        height: 100%;
    }

    .page-wrapper {
        display: flex;
        flex-direction: column;
        min-height: 100%;

        & > .breadcrumbs,
        & > .top-container,
        & > .widget {
            box-sizing: border-box;
            width: 100%;
        }

        .ie10 & {
            height: 100%;
        }
    }

    .navigation ul {
        padding: 0 20px;
    }

    .header {
        &.panel {
          & > .header.links {
              float: right;
              font-size: 0;
              @include lib-list-inline();
              margin-left: auto;

              & > li {
                  font-size: $font-size__base;
                  margin: 0 0 0 15px;

                  &.welcome,
                  a {
                      line-height: 1.4;
                  }

                  &.welcome {
                      a {
                          padding-left: $indent__xs;
                      }
                  }
              }
          }

        }

        &.content {
            padding: 24px 20px 0;
        }
    }

    .page-header {
        border: 0;
        margin-bottom: 0;

        .panel.wrapper {
          position: relative;
          z-index: 999;
          float: left;
          width: 100%;
        }

        .header.panel {
            padding: 2px 20px;
            height: 24px;
        }

        .switcher {
            display: none;
        }
    }

    .page-main {
        & > .page-title-wrapper {
            .page-title {
                display: inline-block;
            }

            .page-title + .action {
                float: right;
                margin-top: $indent__base;
            }
        }
    }

    .logo {
        margin: 0 auto 0 0;

        img {
            max-height: inherit;
        }
    }

    .footer {
        &.content {
          overflow: hidden;

            .links {
                display: inline-block;
                margin-bottom: 20px;
                padding: 0 50px 0 0;
                vertical-align: top;
            }

            .switcher.store {
                display: inline-block;
                padding-right: 50px;
                vertical-align: top;
            }
        }

        .copyright {
            @include lib-clearfix();
        }
    }
}

@include max-screen(1200px) {
    .minicart-wrapper {
        margin-left: 0;
    }

    .block-search {
        width: 343px;
        margin-right: 20px;
    }
}


@include max-screen(1060px) {
    .block-search {
        width: 100%;
        margin-right: 0px;
        margin-top: 15px;

        .control {
            padding-bottom: 0;
        }
    }

    .page-header {
        height: 219px;
    }
}

@include max-screen(1020px) {
    .footer {
        &-left {
          width: 45%;
          margin-right: 9%;
        }
        &-center {
          width: 46%;
          margin-right: 0%;
        //   margin-top: 40px;
          margin-bottom: 40px;
        }
        &-right {
          width: 50%;
        }
    }
}

@include max-screen($screen__m) {
    .page-header {
        height: auto;
        margin-bottom: 0;
        border-bottom: 0px;
    }

    .block-search {
        margin-top: 0;
    }

    .minicart-wrapper {
        position: absolute;
        right: 16px;
        top: 10px;
        margin-top: 10px;

        .action.showcart {
            height: 52px;
            &:before {
                color: $primary__color__darker;
                display: block;
            }
        }
    }

    .logo {
        @include translate(-50%, 0);
        margin: 0 0 0 50%;
        width: 166px;
    }

    //
    //  Account sign up block
    //  ---------------------------------------------

    .account-block {

      &-container {
        overflow: hidden;
      }

      &-title {
          font-size: 36px;
      }
    }
}

@include max-screen(690px) {
    .account-block {
        &-container {

        }

        &-action {
            font-size: 20px;

            .account-block-animation {
                height: 61px;

                a {
                    height: 60px;
                }

                .sign-up-text {
                    height: 60px;
                    line-height: 60px;
                }
            }
        }
    }
}

@include max-screen(680px) {
    .footer {
        text-align: center;
        &-left {
          width: 70%;
          float: none;
          display: inline-block;
          margin-right: 0%;
          margin-bottom: 20px;
          text-align: center;
        }
        &-center {
          width: 100%;
          float: none;
          display: inline-block;
          margin-right: 0%;
          text-align: center;
        }
        &-right {
          width: 100%;
          float: none;
          display: inline-block;
          text-align: center;
        }

        &.content {
            .block {
                svg {
                    display: inline-block;
                }

                .social-icon {
                    float: none;
                    display: inline-block;
                }
            }
        }
    }
}

@include max-screen(600px) {
    .account-block {
        &-action {
            font-size: 18px;
            font-weight: 300;
            text-transform: none;

            .account-block-animation {
                height: 58px;

                a {
                    height: 58px;
                }

                .sign-up-text {
                    height: 58px;
                    line-height: 58px;
                }

                span.sign-up-button {
                    text-transform: uppercase;
                    margin-left: 20px;
                }
            }
        }
    }
}

@include max-screen(480px) {
    .footer {
        &-left {
          width: 90%;
          margin-right: 0%;
        }
        &-center {
          width: 100%;
          margin-right: 0%;
        }
        &-right {
          width: 100%;
        }
    }
}

@include max-screen(440px) {
    .logo {
        width: 220px;
    }

    .minicart-wrapper {
        right: 12px;
        .action.showcart {
          position: relative;

            &:before {
                font-size: 46px;
                line-height: 48px;
                margin-top: -2px;
            }
        }
    }

    .nav-open {

        .page-wrapper {
            left: calc(100% - 60px);
        }

        .nav-toggle {
            &:before {
                margin-left: -10px;
            }
        }
    }

    .nav-sections {
        width: calc(100% - 60px);
        left: calc(-1 * (100% - 60px));
    }

    .account-block {
        &-action {
            font-size: 16px;

            .account-block-animation {
                height: 50px;

                a {
                    height: 50px;
                }

                .sign-up-text {
                    height: 50px;
                    line-height: 50px;
                }

                span.sign-up-button {

                    margin-left: 15px;
                    padding: 10px;
                }
            }
        }
    }
}

@include max-screen(380px) {
    .logo {
        width: 200px;
        margin: 2px 0 2px 50%;
    }

    .footer {
        &-left {
          width: 100%;
        }
    }

    .account-block {
        &-action {
            font-size: 14px;

            .account-block-animation {
                height: 41px;

                a {
                    height: 41px;
                }

                .sign-up-text {
                    height: 41px;
                    line-height: 41px;
                }

                span.sign-up-button {

                    margin-left: 10px;
                    padding: 10px;
                    border-width: 1px;

                }

                .animated-border-line {
                    height: 1px;

                    &:before {
                        height: 1px;
                    }
                }
            }
        }
    }
}

@include max-screen(360px) {
    .logo {
        width: 170px;
        margin: 5px 0 5px 50%;
    }
}

@include max-screen(330px) {
    .account-block {
        &-action {
            .account-block-animation {
                span.sign-up-button {
                    padding: 10px 6px;

                }
            }
        }
    }
}

a.action.new-account {
    background: $secondary__color;
    display: inline-block;
    color: #333;
    border-radius: 50px;
    padding: 0 15px;
    line-height: 25px;
    margin-bottom: 13px;
    border: 1px solid $secondary__color;
    text-decoration: none;
}

a.action.new-account > span {
    color: #333;
}

a.action.new-account:hover {
    background: transparent;
}
