//
//  Rating variables
//  _____________________________________________

$rating-icon__count: 5;
$rating-icon__content: $icon-star;
$rating-icon__font: $icon-font;
$rating-icon__font-size: 28px;
$rating-icon__letter-spacing: -10px;
$rating-icon__color: $color-gray78;

$rating-icon__active__color: $color-orange-red2;

$rating-label__hide: inherit;
