.db_csv_upload {
  margin-top: 10px;
}

.import-csv {

  h2 {
    text-transform: uppercase;
    font-weight: 800;
  }

  li {
    font-size: 15px;
    font-family: 'Ubuntu', Arial, Helvetica, sans-serif;
    line-height: 25px;
    color: $primary__color__darker;
    margin-bottom: 20px;
  }
  .message,
  .sku{
    font-weight: 800;
  }


  .hidden {
    display: none
  }
}

.csv-import-active {
  position: fixed;
  width: 100%;
}

.loader {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 99999;
  background: rgba(255, 255,255,0.9);

  > span {
    position: relative;
    display: inline-block;
    top: 20%;
    width: 80%;
    left: 50%;
    transform: translateX(-50%);
    max-width: 600px;
    text-align: center;
  }
}

.spinner {
  margin: -20px 0 0 -20px;
  width: 60px;
  height: 60px;
  position: absolute;
  top: 50%;
  left: 50%;
  text-align: center;
  animation: sk-rotate 2.0s infinite linear;
}

.dot1, .dot2 {
  width: 60%;
  height: 60%;
  display: inline-block;
  position: absolute;
  top: 0;
  background-color: #333;
  border-radius: 100%;

  -webkit-animation: sk-bounce 2.0s infinite ease-in-out;
  animation: sk-bounce 2.0s infinite ease-in-out;
}

.dot2 {
  top: auto;
  bottom: 0;
  -webkit-animation-delay: -1.0s;
  animation-delay: -1.0s;
}

@-webkit-keyframes sk-rotate { 100% { -webkit-transform: rotate(360deg) }}
@keyframes sk-rotate { 100% { transform: rotate(360deg); -webkit-transform: rotate(360deg) }}

@-webkit-keyframes sk-bounce {
  0%, 100% { -webkit-transform: scale(0.0) }
  50% { -webkit-transform: scale(1.0) }
}

@keyframes sk-bounce {
  0%, 100% {
    transform: scale(0.0);
    -webkit-transform: scale(0.0);
  } 50% {
      transform: scale(1.0);
      -webkit-transform: scale(1.0);
    }
}

.import-csv {
  h1 {
    margin-top: 50px;
  }

  h2.error-title {
    margin-top: 50px;
  }

  .centered-container {
    display: inline-block;
    position: relative;
    text-align: center;
    top: 50px;
    left: 50%;
    transform: translateX(-50%);
  }
}
