//
//  Shopping cart
//  ---------------------------------------------

body.checkout-cart-index {
    .page-title-wrapper {
        h1.page-title {
                font-weight: 900;
                font-size: 24px;
                margin-top: 10px;
        }
    }
}

//  Summary block
.cart-summary {
    @extend .abs-add-box-sizing;
    @extend .abs-adjustment-incl-excl-tax;
    background: $primary__color__lighter;
    margin-bottom: $indent__m;
    padding: 1px 15px 15px;
    border: 1px solid $primary__color;
    border-radius: 3px;
    z-index: 1;


    & > .title {
        display: block;
        @extend .debondt-title;
        font-size: 24px;
        font-weight: 900;
        line-height: 48px;
    }

    .block {
        @extend .abs-discount-block;
        margin-bottom: 0;

        .item-options {
            margin-left: 0;
        }

        .fieldset {
            margin: 15px 0 $indent__m $indent__xs;

            .field {
                margin: 0 0 $indent__s;

                &.note {
                    font-size: $font-size__s;
                }
            }

            .methods {
                .field {
                    & > .label {
                        display: inline;
                    }
                }
            }
        }

        .fieldset.estimate {
            & > .legend,
            & > .legend + br {
                @extend .abs-no-display;
            }
        }
    }
    .actions-toolbar {
        & > .primary {
            button {
                @extend .abs-revert-secondary-color;
            }
        }
    }
}

//  Totals block
.cart-totals {
    @extend .abs-sidebar-totals;
    border-top: 1px dashed $primary__color__darker;
    padding-top: $indent__s;

    .table-wrapper {
        margin-bottom: 0;
        overflow: inherit;

        tr {
            font-size: 15px;

            th, td {
                padding: 10px 0;
            }
        }

        .totals-tax {
        }

        .grand.totals {
            border-top: 1px dashed $primary__color__darker;


            .mark, .amount {
                padding-top: 5px;

                strong {
                    font-weight: 900;
                    text-transform: uppercase;
                    letter-spacing: $default__letter-spacing;
                }
            }
        }
    }
}

//  Products table
.cart.table-wrapper {
    .items {
        &.cart.table {
            //border-bottom: $border-width__base solid $primary__color__darker;
            margin-bottom: 20px;
        }
        thead + .item {
            border-top: $border-width__base solid $primary__color__darker;
        }

        & > tbody.item:not(:last-child) {
            border-bottom: $border-width__base dashed $primary__color__darker;
            position: relative;

            .price {
                font-weight: 400;
                font-size: 14px;
            }

            select.qty {
                font-weight: 400;
            }
        }
    }

    .col {
        padding-top: $indent__base;
        font-size: 14px;
        padding-left: 0;
        font-weight: 900;
        padding-bottom: 0;


        &.qty {
            .input-text {
                @extend .abs-input-qty;
                margin-top: -5px;
                border-radius: 5px;
            }

            .label {
                @extend .abs-visually-hidden;
            }
        }
    }

    .item {
        &-actions {
            padding: 0;
            display: inline-block;

            td {
                padding-bottom: $indent__s;
                text-align: center;
                white-space: normal;
                padding: 0;
                display: inline-block;
            }

        }

        .col {
            &.item {
                display: block;
                min-height: 75px;
                padding: $indent__m 0 $indent__s 75px;
                position: relative;
            }
        }
    }

    .actions-toolbar {
        @extend .abs-add-clearfix;
        padding: 0;
        display: inline-block;

        & > .action {
            @include lib-link-as-button();
            margin-right: $indent__s;

            &-edit {display: none;}

            &:last-child {
                margin-right: 0;
            }

            &-delete {
                font-size: 0;
                color: $primary__color__darker;

                &:hover {
                    color: $secondary__color__dark;
                }

                &:before {
                    font-family: debondt;
                    content: "\e9ac";
                    font-size: 28px;

                }
            }
        }

        .gift-options-cart-item {
            display: none;
        }
    }

    .action {
        &.help.map {
            @extend .abs-action-button-as-link;
            font-weight: $font-weight__regular;
        }
    }

    .product {
        &-item-photo {
            display: block;
            left: 0;
            max-width: 60px;
            padding: 0;
            position: absolute;
            top: 15px;
            width: 100%;
        }

        &-item-details {
            white-space: normal;
        }

        &-item-name {
            display: inline-block;
            margin-top: -6px;
            font-size: 28px;
            line-height: 28px;
            font-weight: 900;
        }
    }

    .gift-registry-name-label {
        &:after {
            content: ':';
        }
    }

    //  Product options
    .item-options {
        @extend .abs-product-options-list;
        @extend .abs-add-clearfix;
        font-size: 14px;
        margin-bottom: $indent__s;
    }

    .product-item-name + .item-options {
        margin-top: $indent__s;
        font-size: 14px;
        text-transform: none;
        font-weight: 400;
        position: relative;

        dt {
            font-weight: 400;
        }
    }

    .product-image-wrapper {
        @extend .abs-reset-image-wrapper;
    }

    .action.configure {
        display: inline-block;
        margin: 0 0 $indent__base;
    }
}

.cart-container {
    .form-cart {
        @extend .abs-shopping-cart-items;
        //border-bottom: 1px dashed $primary__color__darker;
        float: none;
        margin-bottom: 30px;
        display: inline-block;

        .actions {
            & > .continue,
            & > .update {display: none;}

            & > .action.clear {
                background: none;
                border: 1px solid $tertiary__color;
                background: $tertiary__color;
                line-height: 50px;
                box-sizing: border-box;
                border-radius: 5px;
                font-size: 16px;
                padding: 0 33px;
                color: #fff;
                margin-top: 20px;
            }
        }
    }

    .checkout-methods-items {
        @extend .abs-reset-list;
        margin-top: $indent__base;
        text-align: center;

        .action.primary {
            @extend .abs-button-l;
            width: 100%;
        }

        .item {
            & + .item {
                margin-top: $indent__base;
            }
        }
    }
}

//
//  Cross sell
//  ---------------------------------------------

.block.crosssell {
    margin-top: 70px;
}

.cart-tax-info,
.cart .cart-tax-info {
    & + .cart-tax-total {
        display: block;
    }
}

.cart.table-wrapper,
.order-items.table-wrapper {
    .col.price,
    .col.qty,
    .col.subtotal,
    .col.msrp {
        text-align: right;
    }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__m) {
    .cart {
        &.table-wrapper {
            overflow: inherit;

            thead {
                .col {
                    &:not(.item) {
                        display: none;
                    }
                }
            }

            .col {
                &.qty,
                &.price,
                &.subtotal,
                &.msrp {
                    box-sizing: border-box;
                    display: block;
                    float: left;
                    text-align: center;
                    white-space: nowrap;
                    width: 33%;
                    &:before {
                        content: attr(data-th) ':';
                        display: block;
                        font-weight: $font-weight__bold;
                        padding-bottom: $indent__s;
                    }
                }

                &.msrp {
                    white-space: normal;
                }
            }

            .item {
                .col.item {
                    padding-bottom: 0;
                }
            }
        }
    }

    .cart-container {
        .form-cart {
            @extend .abs-shopping-cart-items-mobile;
        }
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m + 1) {
    .cart-container {
        .widget {
            float: left;
        }
    }

    .cart-summary {
        float: right;
        position: relative;
        width: 23%;

        .actions-toolbar {
            .column.main & {
                & > .secondary {
                    float: none;
                }
            }
        }

        .block {
            .fieldset {
                .field {
                    @include lib-form-field-type-revert($_type: block);
                    margin: 0 0 $indent__s;
                }
            }
        }
    }

    .cart {
        &.table-wrapper {
            margin-bottom: 0;
            .items { // Google Chrome version 44.0.2403.107 m fix
                min-width: 100%;
                width: auto;
            }

            .item {
                .col {
                    &.item {
                        padding: 27px 8px $indent__s;
                    }
                }

                &-actions {
                    td {
                        text-align: right;
                    }
                }
            }

            .product {
                &-item-photo {
                    display: inline-block;
                    max-width: 100%;
                    padding-right: $indent__base;
                    position: static;
                    vertical-align: top;
                    width: auto;
                    float: left;
                }

                &-item-details {
                    display: table-cell;
                    vertical-align: top;
                    white-space: normal;
                    width: 99%;
                }
            }

            .item-actions {
                position: absolute;
                right: 0;
                margin-top: -50px;
                .actions-toolbar {
                    text-align: left;
                }
            }
        }
    }
}


.cart.item .product-item-details .item-options dt:after {
    content: none;
}

.cart-container .form-cart {
    width: 72%
}
.cart.table-wrapper .items.cart.table {
    position: relative
}

@media only screen and (max-width: 768px) {
    .cart-container .form-cart {
        width: 100%
    }
}

.cart {
    .product-item-name a {
        font-size: 18px;
    }
}

body.checkout-cart-index .columns .cart-container .cart-summary .checkout button.action.primary.checkout {
    background: $secondary__color !important;
}

body.checkout-cart-index .columns .cart-container .cart-summary .checkout button.action.primary.checkout span {
    color:  #fff;
}
