
.camera_invoer-index-index {

    .page-container {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: #ffffff;
        touch-action: none;
        overflow: hidden;
    }

    .page-series {
        left: -100%;
        position: absolute;
        width: 400%;
        height: 100%;
        transition: left 0.3s;
    }

    .quickscan-checkbox {

    }

    .lefthanded-checkbox {

    }

    .waiting {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .center-page {
        background-color: #c0c0c0;
        position: absolute;
        left: 25%;
        width: 25%;
        height: 100%;
    }



    .interactive-page {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
    }

    .product-page {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: #ffffff;
        display: none;
        z-index: 100;
    }

    .back-in-stock-panel.hidden {
        display: none;
    }

    .add-to-cart-panel.hidden {
        display: none;
    }

    .product-name {

    }

    .settings-button {
        position: absolute;
        left: 0;
        bottom: 0;
        z-index: 20;
    }

    .cart-button {
        position: absolute;
        right: 0;
        bottom: 0;
        z-index: 20;
    }

    .settings-back-button {
        position: absolute;
        right: 0;
        top: 0;
    }

    .cart-back-button {
        position: absolute;
        left: 0;
        top: 0;
    }

    .product-back-button {
        position: absolute;
        left: 0;
        top: 0;
    }

    .checkout-button {
        position: absolute;
        bottom: 0;
    }

    .minus-button {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 50px;
    }

    .amount-input {
        position: absolute;
        bottom: 0;
        left: 50px;
        width: 150px;
    }

    .plus-button {
        position: absolute;
        bottom: 0;
        left: 200px;
        width: 50px;
    }

    .add-to-cart-button {
        position: absolute;
        bottom: 0;
        right: 0;
    }

    .scanner-status {
        position: fixed;
        margin-top: auto;
        background: white;
        top: 10px;
        left: 10px;
        color: #fff;
        width: calc(100% - 20px);
        box-sizing: border-box;
        padding: 20px;
        z-index: 99999;
        display: none;
        font-size: 15px;
        box-shadow: 0 0 4px rgba(0, 0, 0, 0.4);

        &.error {
            display: block;
            background: red;
        }

        &.warning {
            display: block;
            background: #ff9d67;
        }

        &.success {
            display: block;
            background: #26a726;
        }

        &.notice {
            display: block;
            background: #4949f6;
        }
    }

    .scanner-status span {
        display: block;
        text-align: center;
    }

    #status-close {
        float: right;
        cursor: pointer;
    }

    .totals {

    }

    .subtotal {
        font-weight: bold;
    }

    .product-count {

    }

    #scanner-input.viewport {
        position: relative;
    }

    #interactive.viewport > canvas, #interactive.viewport > video {
        max-width: 100%;
        width: 100%;
    }

    canvas.drawing, canvas.drawingBuffer {
        position: absolute;
        left: 0;
        top: 0;
    }

    @media only screen and (max-width: 768px) {

        h2 {
            font-size: 16px;
        }

        .header-main-usp,
        .block-search {
            display: none;
        }

        #cart-products {
            padding: 5px;
        }

        .scanner-cart-products {
            display: block;
            box-sizing: border-box;

            tbody {
                display: block;
                box-sizing: border-box;

                tr{
                    display: grid;
                    grid-template-columns: calc(57% - 66px) calc(43% - 100px) 56px 56px 56px;
                    grid-template-rows: 50% 50%;

                    td:nth-child(2) {
                        grid-row-start: 2;
                        grid-row-end: 2;
                        grid-column-start: 1;
                        grid-column-end: 6;
                        padding-top: 5px;
                    }

                    &.hidden {
                        height: 0;
                    }

                    .right,
                    .qty,
                    .sku {
                        padding-bottom: 2px;
                    }

                    td {
                        box-sizing: border-box;
                    }
                }
            }
        }

        #interactive.viewport {
            width: 100%;
            height: auto;
            overflow: hidden;
        }

    }
}
