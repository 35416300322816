.home-customer {
    display: flex;
    gap: 5%;

    .welcome-back-banner {
        border-radius: 20px;
        overflow: hidden;
        margin-bottom: 20px;
    }

    @media only screen and (max-width: 768px) {
        flex-direction: column;
    }

    .home-customer-right {
        width: 55%;
        display: flex;
        flex-direction: column;
        justify-content: center;

        @media only screen and (max-width: 768px) {
            width: 100%;
        }

        .home-customer-title {
            font-size: 24px;
            margin: 20px 0 10px;
            font-weight: 900;
            color: $primary__color;

            @media only screen and (max-width: 768px) {
                text-align: center;
            }
        }
    }

    .home-customer-left {
        width: 45%;
        font-size: 24px;
        font-weight: 900;
        margin: 20px 0 0;
        color: $primary__color;

        @media only screen and (max-width: 768px) {
            width: 100%;
            text-align: center;
        }

        .customer-name {
            font-weight: 900;
        }
    }
}

@media only screen and (max-width: 640px) {
    #home-orders-table {
        display: block;

        thead {
            display: none;
        }

        tbody {
            display: block;

            tr {
                display: block;
                margin-bottom: 15px;

                td {
                    display: block;
                    padding: 0;

                    &:before {
                        content: attr(data-th) ': ';
                        font-weight: 600;
                    }
                }
            }
        }

    }
}
