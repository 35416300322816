//
//  Component
//  ---------------------------------------------
.catalog-product-view .db-option-selector-container {
  position: relative;
  float: none;
  width: 100%;

  .db-option-selector {
    border-radius: 3px 3px 0 0;
    border-bottom: 0px;
    height: 60px;
    padding-right: 7px;

    input.db-option-selector-input {
      width: 100%;
    }
  }

  &.dbUserLoggedIn {
    .option-selector-dropdown li .db-product-name {
      width: calc(100% - 180px);
    }

    .option-selector-dropdown li .db-ordering {
      display: block;
    }
  }
}

.option-selector-dropdown {
    margin: 0 0 0 0;
}

.db-option-selector-superplus {
    border: 1px solid $primary__color;
    background: #fff;
    border-radius: 0 0 3px 3px;
    border-top: 0;
    height: 54px;
    padding-right: 7px;
    font-size: 14px;
    text-align: right;
    margin-top: 0;

    div.buttons {
        margin: 10px;
        display: inline-block;
        button {
            display: inline-block;
            width: 26px;
            border-color: $primary__color;
            background: $primary__color;
            color: #fff;
            border-radius: 3px;
            font-size: 0;
            height: 32px;
            padding: 0;
            border-width: 1px 1px 1px 0;

            &:hover {
                background: lighten(#54c728, 20%);
            }

            &.db-minus {
                border-radius: 3px 0 0 3px;
                border-width: 1px 0 1px 1px;
            }

            &:before {
                font-family: debondt;
                font-size: 22px;
                line-height: 28px;
                font-weight: 400;
            }
            &.db-minus:before {content: "\e15b";}
            &.db-plus:before {content: "\e145";}
        }
    }
}

.catalog-category-view .db-option-selector-container {
  &.dbUserLoggedIn {
    .option-selector-dropdown li .db-product-name {
      width: calc(100% - 180px);
    }

    .option-selector-dropdown li .db-ordering {
      display: block;
    }
  }
}

.option-selector-dropdown li.not-salable {
  overflow: hidden;
  position: relative;

  &:hover,
  &.selected{
    background: $primary__color__lighter;
  }

  .db-flex-container {
    opacity: 0.15;
    pointer-events: none;
  }

  &:before {
    content: '';
    display: block;
    border-top: 1px solid $primary__color;
    width: 150%;
    transform: rotate(-5deg);
    position: absolute;
    top: 31%;
    z-index: 99999;
    left: -2px;
  }


}

.dbUserLoggedIn .option-selector-dropdown li.not-salable {
  &:after {
    content: '\e911';
    font-family: 'debondt';
    font-size: 27px;
    position: absolute;
    right: 110px;
    top: 30px;
    transform: translateY(-50%);
  }

  &:hover:after {
    color: $tertiary__color;
  }
}

// default: hide cart buttons
div.db-option-selector-container .buttons {
  visibility: hidden;
}
// user logged in: show buttons
div.db-option-selector-container.dbUserLoggedIn .buttons {
  visibility: visible;
}

.catalog-product-view .product-options-wrapper {
  margin-top: 5px;
}

.db-option-selector-container {
  //position: relative;
  float: right;
  margin-top: 15px;
  width: 100%;
}

.db-option-selector {
  position: relative;
  background: #fff;
  border: 1px solid $primary__color;
  border-radius: 3px;
  padding-left: 7px;

  &:after {
    visibility: hidden;
    display: block;
    font-size: 0;
    content: "";
    clear: both;
    height: 0;
  }

}

//
//  Component Header
//  ---------------------------------------------

button.db-option-selector-opener {
  display: block;
  //width: 379px;
  float: left;
  height: 50px;
  background: none;
  border: 0px;
  width: calc(100% - 52px);
  padding-left: 7px;
  text-align: left;
  font-size: 18px;
  color: $primary__color;
  font-size: 14px;
  font-weight: 400;

}

input.db-option-selector-input {
  float: left;
  //width: 379px;
  width: calc(100% - 52px);
  margin: 7px 0;
    padding-left: 15px;
  border-color: $primary__color;
  color: $primary__color__darker;

  &::-webkit-input-placeholder   {color: $primary__color; font-size: 14px;}
  &:-moz-placeholder             {color: $primary__color; font-size: 14px;}
  &::-moz-placeholder            {color: $primary__color; font-size: 14px;}
  &:-ms-input-placeholder        {color: $primary__color; font-size: 14px;}
}


button.db-option-selector-opener.hidden, input.db-option-selector-input.hidden {
  display: none;
}

.db-option-selector-close {
  float: right;
  height: 50px;
  border: 0px;
  background: none;
  font-size: 0;

  &:hover {
    border: 0px;
    background: none;
  }

  &:focus {
    border: 0px;
    background: none;
  }

  &:after {
    font-size: 22px;
    content: "\e5cf";
    font-family: debondt;
    font-weight: 400;
    color: $primary__color__darker;
    line-height: 36px;
  }
}

//
//  Component list
//  ---------------------------------------------

ul.option-selector-dropdown {
  background-color: lighten($primary__color, 80%);
  border: 1px solid $primary__color;
  padding: 0;
  right: 0;
  z-index: 10;
  margin-top: 0;
  max-height: 300px;
  top: 51px;
  border-radius: 0 3px 3px 3px;

  &::-webkit-scrollbar{width:7px;height:7px;}
  &::-webkit-scrollbar-button:start:decrement {display:block;height:0;background-color:transparent;}
  &::-webkit-scrollbar-button:end:increment {display:block;height:0;background-color:transparent;}
  &::-webkit-scrollbar-track-piece{background-color:transparent;-webkit-border-radius:0;-webkit-border-bottom-right-radius:20px;-webkit-border-bottom-left-radius:20px;}
  &::-webkit-scrollbar-thumb:vertical{height:50px;background-color:#80838d;-webkit-border-radius:20px;}
  &::-webkit-scrollbar-thumb:horizontal{width:50px;background-color:#80838d;-webkit-border-radius:20px;}

  li {
    border-bottom: 1px solid $primary__color;
    margin-bottom: 0;
    padding: 4px 0;
    margin-top:0;
    cursor: pointer;

      &:last-child {
          border-bottom: 0;
      }

    .db-flex-container {
      margin: 0;
      line-height: 25px;
      padding-left: 10px;
      padding-right: 10px;
      position: relative;
      display: flex;
      justify-content: space-between;
      font-size: 18px;
    }

    .swatch-color {
      width: 40px;
      display: block;
      position: absolute;
      height: 40px;
      border: 1px solid $primary__color__darker;
      box-sizing: border-box;
      top: 7px;
      left: 4px;
    }

    div.buttons {
      margin-top: 10px;
      button {
        display: inline-block;
        width: 26px;
        float: left;
        border-color: #000;
        background: $primary__color;
        color: #fff;
        border-radius: 0 3px 3px 0;
        font-size: 0;
        height: 32px;
        padding: 0;
        border-width: 1px 1px 1px 0;

        &:hover {
          background: lighten($primary__color__darker, 20%);
        }

        &.db-minus {
          border-radius: 3px 0 0 3px;
          border-width: 1px 0 1px 1px;
        }

        &:before {
          font-family: debondt;
          font-size: 22px;
          line-height: 28px;
          font-weight: 400;
        }
        &.db-minus:before {content: "\e15b";}
        &.db-plus:before {content: "\e145";}


      }

      input {
        display: inline-block;
          border-radius: 0;
        width: 32px;
        height: 32px;
        text-align: center;
        padding: 0 3px;
        float: left;
        border: 1px solid $primary__color__darker;
      }
    }
  }

  &.hidden {
    display: none;
  }

  &.always-shown {
    position: inherit;
    width: auto;
    top: 0;
    border-radius: 0 0 3px 3px;
    overflow: hidden;
  }
}

@include max-screen($screen__s){
  .catalog-category-view ul.option-selector-dropdown.always-shown {
    top: -54px;
  }
}

ul.option-selector-dropdown.configurable {
  overflow-y: scroll;
}

ul.option-selector-dropdown.simple {
}

.db-option-selector-container.expanded {

  .db-option-selector {
    border-radius: 3px 3px 0 0;
    z-index: 11;
    border-width: 1px 1px 0 1px;
  }

  .db-option-selector-close {
    &:after {
      content: "\e5cd";
    }
  }
}


//
//  Component list item
//  ---------------------------------------------



.swatch-image {
  width: 40px;
  height: 40px;
  display: block;
  margin-top: 7px;
  margin-bottom: 7px;
  border: 1px solid $primary__color__darker;
  box-sizing: border-box;

}


//
//  Notices
//  ---------------------------------------------

.db-selector-option-notice-container {
  display: none;
}



//
//  Loader
//  ---------------------------------------------

.option-selector-dropdown li {

  .db-product-name {
    display: block;
    width: calc(100% - 50px);
    padding-top: 14px;
    padding-bottom: 14px;
      font-size: 14px;
  }

  .db-image {
    display: block;
    padding-right: 10px;
  }

  .db-ordering {
    display: none;
    overflow: hidden;
    position: relative;

    .buttons,
    .db-progress {
      float: left;
    }

    .db-progress {
      margin-top: 8px;
      margin-right: 10px;
      position: relative;
    }
  }

  .db-progress {
    height: 36px;
    width: 36px;

    svg {
      position: absolute;
      top: 0;
      left: 0;
    }

    svg.progress-circle {
      opacity: 0;
      stroke: $positive__confirmation__color;
      stroke-width: 4;
      fill: none;

      path {
        fill: transparentize($positive__confirmation__color, 1);
        // animation: dash 4s  linear forwards;
        stroke-dasharray: 100;
        stroke-dashoffset: 100;
        transition: stroke-dashoffset 0s, fill 0s 0s;
      }
    }

    svg.checkmark {

      path {
        opacity: 0;
        fill: none;
        stroke: #fff;
        stroke-linecap: round;
        stroke-width: 4;
        stroke-dasharray: 30;
        stroke-dashoffset: 30;
        transition: stroke-dashoffset 0s;
      }
    }


  }
  &.db-send-to-server{
    .db-progress {
      svg.progress-circle {
        opacity: 1;
        path {
          stroke-dashoffset: 0;
          transition: stroke-dashoffset 2s;
        }
      }

    }
  }

  &.db-success {
    .db-progress {
      svg.progress-circle {
        opacity: 1;
        path {
          fill: transparentize($positive__confirmation__color, 0);
          stroke-dashoffset: 0;
          transition: fill 0.2s;
        }
      }

      svg.checkmark {
        path {
          opacity: 1;
          stroke-dashoffset: 0;
          transition: stroke-dashoffset 0.3s;
        }
      }
    }
  }
}

@include max-screen(1027px) {
  .db-option-selector-container {
    width: 100%;
  }
  ul.option-selector-dropdown {
    width: 100%;
    box-sizing: border-box;
    border-radius: 0 0 3px 3px;
  }

  button.db-option-selector-opener,
  .cart.table-wrapper .db-option-selector-opener.action-gift,
  input.db-option-selector-input {
    width: calc(100% - 52px);
  }
}

@include max-screen($screen__m) {
  .catalog-category-view {
    .db-option-selector-container {
      left: 0;
      margin: 0;
      bottom: 10px;
    }

    .products-list {
      .product-item {
        position: relative;
      }
    }
  }
}

@media only screen and (min-width: 481px) and (max-width: 561px){
  .catalog-category-view {
    .db-option-selector-container {
      width: 140%;
      float:left;
    }
  }
}

@media only screen and (min-width: 561px) and (max-width: 640px){
  .catalog-category-view {
    .db-option-selector-container {
      width: 125%;
      float:left;
    }
  }
}


.db-image {
  position: relative;
}

@include max-screen(480px) {
  ul.option-selector-dropdown li {
    font-size: 14px;
    text-align: left;
  }
}

//
// Out-of-stock popup
//

.out-of-stock-popup {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100000;
  background: rgba(0,0,0,0.5);
  height: 100%;
  width: 100%;
}

.out-of-stock-popup-body {
  position: relative;
  background: #FFFFFF;
  padding: 20px;
  width: 80%;
  max-width: 600px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  .out-of-stock-form {
    text-align: center;
    margin-top: 40px;

    > div {
      margin-bottom: 20px;

      input[type="checkbox"] {
        position: relative;
        top: 4px;
      }
    }

    input[type="submit"] {
      display: block;
      height: 52px;
      background: #c6a7ab;
      border: 1px solid #93777a;
      color: #fff;
      font-size: 19px;
      padding: 0 30px;
      text-transform: uppercase;
      border-radius: 3px;
      margin: 15px auto 0 auto;

      &:hover {
        background: #d0b6ba;
      }
    }
  }

  .out-of-stock-close {
    position: absolute;
    height: 30px;
    width: 30px;
    border-radius: 50%;
    background: #fff;
    line-height: 25px;
    text-align: center;
    top: -15px;
    padding: 0;
    right: -15px;
    font-size: 21px;
    font-weight: 600;
    cursor: pointer;

    &:hover {
      box-shadow: 0 0 10px 5px #1979c3;
    }
  }

  h3 {
    text-align: center;
    font-weight: 900;
    margin-bottom: 20px;
    text-transform: uppercase;
  }
}

.super-plus ul.option-selector-dropdown {
    border-radius: 0 0 0 0;
}

