//
//  Variables
//  _____________________________________________

$checkout-wrapper__margin             : $indent__base;
$checkout-wrapper__columns            : 8;

$checkout-step-title__border          : $border-width__base solid $color-gray80;
$checkout-step-title__font-size       : 26px;
$checkout-step-title__font-weight     : $font-weight__light;
$checkout-step-title__padding         : $indent__s;

$checkout-step-title-mobile__font-size: 18px;

//
//  Common
//  _____________________________________________

div.no-quotes-block {
    background: #ffdda0;
    border: 1px solid #ec9900;
    line-height: 25px;
    padding: 10px 20px;
    font-size: 14px;
    font-weight: 500;
}

fieldset#payment_form_purchaseorder > .field {
    margin-bottom: 0;


    > .label {
        font-size: 15px;
    }
}
//
//.extra-po-text {
//    padding-left: 35.8%;
//
//    @include max-screen(767px) {
//        padding-left: 0;
//        text-align: center;
//    }
//}

.checkout-index-index,
.checkout-onepage-success {
    .page-title-wrapper {
        @extend .abs-visually-hidden;
        font-family: Ubuntu;
    }

    .page-header {
        height: auto;
        margin: 20px 0;
    }

    .order-number-success {
        margin-bottom: 20px;
    }

    .action.primary.continue {
        background: $secondary__color;
        border: 1px solid $secondary__color__dark;
        color: #fff;
        font-weight: 700;
        margin: 20px 0 0 0;
        text-transform: uppercase;
        padding: 14px 17px;
        line-height: 22px;
        font-size: 18px;
    }
}

.checkout-container {
    @extend .abs-add-clearfix;
    margin: 0 0 $checkout-wrapper__margin;
}

.opc-wrapper {
    margin: 0 0 $checkout-wrapper__margin;

    .opc {
        @extend .abs-reset-list;
    }

    .step-title {
        @extend .abs-checkout-title;
    }

    .step-content {
        margin: 0 0 $indent__xl;
    }
}

.fieldset > .field.checkout-agreement.choice:before {
    content:none;
}

.opc-wrapper .fieldset > .field.checkout-agreement.required > label {
    font-size: 14px;
    padding-right: 0;
    position: relative;
}

.opc-wrapper .fieldset > .field.required > label:after {
    content: none;
}

.checkout-index-index {
    .nav-sections,
    .nav-toggle {
        display: none;
    }

    .logo {
        margin-left: 0;
    }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__s) {
    .opc-wrapper {
        .step-title {
            font-size: $checkout-step-title-mobile__font-size;
            border-bottom: 0;
            padding-bottom: 0;
        }
    }


}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .opc-wrapper {
        @extend .abs-add-box-sizing-desktop-m;
        @include lib-layout-column(2, 1, $checkout-wrapper__columns);
        padding-right: $indent__l;
    }
}

@include max-screen($screen__m) {
    .checkout-index-index {
        .logo {
            margin-left: 50%;
        }

        .minicart-wrapper {
            .action.showcart {
                background: none;
                border: none;

                .counter.qty {
                    left: 13px;
                    top: 1px;
                    position: absolute;
                }
            }
        }
    }
}
