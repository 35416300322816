.pricelist-pricelist-select {

    form {
        display: block;
        float: left;
        width: 100%;

        .col-left,
        .col-right {
            float: left;
            width: 50%;

            > label {
                display: block;
                margin-bottom: 10px;
            }

            button {
                display: block;
                margin-bottom: 15px;
                color: #fff;
                background: $secondary__color;
                border-color: $secondary__color__dark;
                text-transform: uppercase;
                letter-spacing: -0.05em;
                line-height: 22px;
                padding: 10px 17px;
                font-size: 18px;
                float: left;

                &:hover {
                    background: #d0b6ba;
                }
            }
        }

        .col-bottom {
            float: left;
            width: 100%;
            margin-top: 20px;

            > label {
                float: left;
                margin-bottom: 10px;
            }
        }


        > h3 {
            float: left;
            width: 100%;
            margin-bottom: 30px;
            text-transform: uppercase;
            font-weight: 900;
            font-size: 24px;
            letter-spacing: -0.05em;
            display: inline-block;
            color: $primary__color;
        }

        div.tree-multiselect {
            border: 0;

            div.title {
                background: #fff;
                color: $primary__color;
                padding: 5px;
                font-size: 15px;
                font-weight: 600;

                > span.collapse-section {
                    float: right;
                }
            }

            input[type="checkbox"] {
                position: relative;
                top: -1px;
            }

            > div.selections {
                border: 0;
                border-radius: 0;
                padding: 0;

                .auxiliary {
                    margin-bottom: 10px;

                    input.search {
                        border: 1px solid $primary__color ;
                    }
                }

                @media only screen and (max-width: 620px) {
                    width: 100%;
                }
            }

            > div.selected {
                padding: 43px 0 0 4%;

                > div.item {
                    padding: 4px 5px;
                    overflow: auto;
                    background: $primary__color__lighter;
                    border: 1px solid $primary__color;
                    border-radius: 3px;
                }

                @media only screen and (max-width: 620px) {
                    display: none;
                }
            }

            span.remove-selected {
                background: $primary__color__darkest;
                font-size: 15px;
                font-weight: 900;
            }
        }
    }
}
