//
//  Variables
//  _____________________________________________

$review-ratings-left: 280px;
$reviews-indent-desktop: 30px;

//
//  Common
//  _____________________________________________

.rating-summary {
    @include lib-rating-summary(
    $_icon-font: debondt,
    $_icon-content: "\e901",
    $_icon-color: #b4b4b4,
    $_icon-color-active: #eaac83,
    $_icon-font-size: 36px,
    $_icon-letter-spacing: -5px
    );
    .rating-result {

    }
}

.product-reviews-summary,
.table-reviews {
    .rating-summary {
        @include lib-rating-summary-label-hide();
    }
}

.review-control-vote {
    @include lib-rating-vote(
    $_icon-font: debondt,
    $_icon-content: "\e901",
    $_icon-color: #ccc,
    $_icon-color-active: #eaac83,
    $_icon-font-size: 48px,
    $_icon-letter-spacing: -7px
    );
}

//
//  Add review block
//  ---------------------------------------------

.review-form {

    .actions-toolbar.review-form-actions {
        margin-left: 0;
        float: right;
    }

    .review-form-submit-button {

        button.action.submit.primary {
            background: transparent;
            border: 4px solid $primary__color__darker;
            border-radius: 0;
            font-size: 24px;
            position: relative;
            color: $primary__color__darker;
            text-transform: uppercase;
            overflow: hidden;
            font-weight: 900;
            box-sizing: border-box;
            line-height: 52px;
            padding: 0 15px;
            @include transition-timing-function(cubic-bezier(0.2, 1, 0.3, 1));
            @include transition(all 0.2s);

            &:before {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 150%;
                height: 100%;
                background: $secondary__color;
                z-index: -1;
                @include transition(transform 0.3s, opacity 0.3s, background-color 0.3s);
                @include transform-origin(0% 100%);
                @include transform(rotate3d(0, 0, 1, -45deg) translate3d(0, -3em, 0));
            }

            &:hover {
                border-color: $secondary__color;
                color: #fff;

                &:before{
                    background-color: $secondary__color;
                    @include transform(rotate3d(0, 0, 1, 0deg));
                    @include transition-timing-function(cubic-bezier(0.2, 1, 0.3, 1));
                }
            }
        }
    }

}

.product-detailed-reviews {
    float: left;
    margin-top: 50px;
    width: 100%;
    margin-bottom: 20px;
}

#product-review-container {
    float: left;
    width: 50%;
    margin-right: 10%;
}

.review-add {
    float: left;
    width: 40%;
}

.block {

    &.review-list {

        .block-title {
            @extend .debondt-title;
            font-size: 36px;
        }
    }
    &.add.review {
        margin-bottom: $indent__xl;

        > .title {
            @include lib-heading(h2);
        }

        .legend {
            @include lib-heading(h3);
            margin: 0 0 $indent__base;
        }

        .field.rating {
            display: table-row;

            .label {
                display: table-cell;
                padding: $indent__xs 0;
                vertical-align: middle;
            }

            .control {
                display: table-cell;
                vertical-align: top;
            }
        }
    }

    &.reviews.list {
        > .title {
            @include lib-heading(h2);
        }

        .review {
            &.item {
                margin-bottom: $indent__xl;
            }

            &.title {
                @include lib-heading(h3);
            }
        }
    }
}

.data.table.reviews {
    .rating-summary {
        margin-top: -4px;
    }
}

.customer-review.view {
    margin-bottom: $indent__base;

    .review.title,
    .ratings-summary,
    .product-details {
        margin-bottom: $indent__base;
    }
}

.product-reviews-summary {
    display: table;
    margin: 0 0 30px;

    .products.wrapper.list & {
        margin: 0 auto;
    }

    &.empty {
        margin-left: 0;
    }

    .rating-summary {
        display: table-cell;
        left: -$indent__xs;
        position: relative;
        text-align: left;
        vertical-align: middle;
    }

    .reviews-actions {
        display: table-cell;
        line-height: $rating-icon__font-size;
        vertical-align: middle;

        a {
            margin-right: $indent__xs;
        }
    }
}

//
//  Desktop
//  _____________________________________________

@include max-screen($screen__m) {
    .product-info-main {
        .product-reviews-summary {
            .rating-summary {
                display: inline-block;
            }
        }
    }

    .product-reviews-summary {
        display: block;
        width: 100%;
        text-align: center;
    }

    .review-add {
        .review-form {
            .review-field-ratings {
                legend {
                    display: none;
                }
            }
        }
    }

    .review-control-vote {
        &:before {
            // left: 11px;
        }
    }

    .review-add {
        .review-field-ratings  {
            .nested {
                display: block;
            }
            .review-field-rating {
                display: block;
                 .control {
                     width: 210px;
                     height: 48px;
                     display: inline-block;
                     position: relative;
                     margin-bottom: 0;
                     left: 50%;
                     @include translate(-50%, 0);
                 }
            }
        }
    }


}

@include min-screen($screen__m + 1) {


    .product-reviews-summary {
        .products.wrapper.list & {
            margin: 0;
        }
    }
}

//
//  Common
//  _____________________________________________

.customer-review {
    .product-details {
        @extend .abs-add-clearfix;
        margin-bottom: $indent__xl;
    }

    .product-media {
        float: left;
        margin-right: 3%;
        max-width: 285px;
        width: 30%;
    }

    .review-details {
        .customer-review-rating {
            margin-bottom: $indent__base;

            .item {
                margin-bottom: $indent__s;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }

        .review-title {
            @include lib-heading(h3);
            font-weight: $font-weight__semibold;
            margin-bottom: $indent__base;
        }

        .review-content {
            margin-bottom: $indent__base;
        }
    }

    .product-reviews-summary {
        .rating-summary,
        .reviews-actions {
            display: block;
        }
    }
}

.review-field-ratings {
    .nested {
        display: table;
    }

    .review-field-rating {
        display: table-row;

        .label,
        .control {
            display: table-cell;
            padding-bottom: $indent__s;
            vertical-align: top;
        }

        .label {
            padding-right: $indent__s;
            padding-top: 6px;
        }

        .control {
            padding-top: 2px;
        }
    }
}

//
//  Review product page
//  ---------------------------------------------

.review {
    &-toolbar {
        margin: 0 0 $indent__base;
        text-align: center;

        &:first-child {
            display: none;
        }

        .pages {
            border-bottom: 1px solid $color-gray79;
            display: block;
            padding: $indent__base 0;
            text-align: center;
        }

        .limiter {
            display: none;
        }
    }

    &-add {
        .block-title {

            strong {
                @extend .debondt-title;
                font-size: 36px;
                font-weight: 300;

            }
        }
    }

    &-form {
        .action.submit.primary {
            @include lib-button-revert-secondary-color();
        }
    }

    .fieldset &-legend.legend {
        @extend .abs-reset-left-margin;
        margin: $indent__base 0;
        font-size: 18px;
        text-transform: uppercase;

        strong {
            margin-left: 5px;
            font-weight: 900;
        }
    }

    &-field-rating {
        .control {
            margin-bottom: 1.2 * $indent__xl;
            margin-top: $indent__s;
        }
    }

    &-list {
        margin-bottom: $indent__l;

        .block-title strong {
            @include lib-heading(h2);
        }
    }

    &-item {
        @include lib-clearer();
        margin: 0;
        padding: $indent__base 0;

        &:last-child {
            border-width: 0;
        }

        .rating-summary {

            .rating-result {
                width: 140px;

                &:before {
                    font-size: 30px;
                    letter-spacing: -3px;
                }

                > span:before {
                    font-size: 30px;
                    letter-spacing: -3px;
                }
            }
        }
    }

    &-ratings {
        display: table;
        margin-bottom: $indent__s;
        width: 100%;
    }

    &-author {
        display: inline-block;
        width: 100%;
        text-align: right;
        font-style: italic;
        color: #706c87;
        font-size: 18px;

        strong {
            font-weight: 400;
        }
    }

    &-title {
        // @include lib-heading(h3);
        @extend .debondt-title;
        margin: 0 0 0;
        font-size: 20px;
        font-weight: 900;
    }

    &-content {
        margin-bottom: $indent__base;
        margin-top: 10px;
        float: left;
        font-size: 18px;
    }
}

@include max-screen(910px) {
    #product-review-container {
        margin-right: 5%;
    }
    .review-add {
        width: 45%;
    }
}

@include max-screen(900px) {
    #product-review-container {
        margin-right: 0%;
        width: 100%;
    }
    .review-add {
        width: 100%;

        .block-title {
            text-align: center;
        }
    }

    .review-form {
        max-width: none;

        .actions-toolbar.review-form-actions {
            float: none;
            position: relative;
            left: 50%;
            display: inline-block;
            @include translate(-50%, 0);
        }

        .fieldset {
            .review-legend.legend {
                display: inline-block;
                width: 100%;
                text-align: center;
            }
        }
    }

    .review-list {
        .block-title {
            text-align: center;
        }
    }
}



//
//  Desktop
//  _____________________________________________

@include min-screen($screen__s) {
    .review {
        &-form {
            // max-width: 500px;
        }

        &-ratings {
            float: left;
            margin-bottom: 0;
        }

        &-ratings ~ &-content,
        &-ratings ~ &-details {
        }

        &-toolbar {
            margin: 0 0 $reviews-indent-desktop;

            .pages {
                padding: $reviews-indent-desktop 0;
            }
        }

        .fieldset &-legend.legend {
            margin-bottom: $reviews-indent-desktop;
        }

        &-item {
            padding: 10px 0;
        }

        &-title {
        }
    }
}
