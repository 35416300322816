.webforms {margin-bottom:1.5rem;}

/* Success text */
.webforms-success-text{border:1px solid #ccc; padding:1rem 2rem;background:rgba(255, 212, 96, 0.26);margin-bottom: 2rem;}

/* Starry script */

div.starry {
    width: auto;
    margin: -4px 0 10px 0;
    padding: 5px;
    width: auto;
}

div.standard_star {
    background-repeat: no-repeat;
    cursor: pointer;
    float: left;
    display: inline;
    background-image: url(../images/stars.gif);
}

div.feedback {
    border: 1px solid #ddd;
    padding: 5px 3px 3px;
    display: inline;
}

/* Calendar */

.webforms-calendar {
    position: relative
}

.webforms-calendar .ui-datepicker-trigger {
    margin-left: -3.2rem;
    height:2.8rem;
    position: absolute;
    top:0;
    right:0.5rem;
}

/* Date of birth */

.customer-dob .dob-month,
.customer-dob .dob-day,
.customer-dob .dob-year { float:left; width:8.5rem; }
.customer-dob input.input-text { display:block; width:7.4rem; }
.customer-dob label { font-size:10px; font-weight:normal; color:#888; }
.customer-dob .dob-day,
.customer-dob .dob-month { width:6rem; }
.customer-dob .dob-day input.input-text,
.customer-dob .dob-month input.input-text { width:4.6rem; }
.customer-dob .dob-year { width:14rem; }
.customer-dob .dob-year input.input-text { width:13.4rem; }

/* Tooltip */

.ot-content {
    text-align: left;
}

.tooltip-icon {
    background-image: url('../images/tooltip.png');
    background-size: 1.5rem 1.5rem;
    width: 1.5rem;
    height: 1.5rem;
    cursor: pointer;
    display: inline-block;
    vertical-align: middle;
    margin: 0 0 0.2rem 0.5rem;
}

/* Select images */
.webforms figcaption {padding:5px 0;margin:5px;}


/* Form */

.webforms-description-text{
    margin-bottom: 4rem;
}

.webforms-fields-comment{
    font-size:90%;
}

.please-wait {
    padding-top: 0.5rem;
}

.please-wait .loading-indicator {
    background: #fff url(../images/ajax-loader-big.gif) no-repeat;
    background-size: 1.8rem;
    display: inline-block;
    width: 1.8rem;
    height: 1.8rem;
    vertical-align: bottom;
    margin-bottom: 0.2rem;
}

/* reCaptcha */

.recaptcha {
    float: left;
    clear: both;
    width: 100%;
    margin-bottom: 3rem;
    margin-top: -2rem;
}

/* Validation */

.validation-advice{
    margin-top:0.7rem;
    font-size: 1.2rem;
    color: #e02b27;
    clear:both;
}

input.validation-failed, textarea.validation-failed{
    border-color: #ed8380;
}

/* Customer Account */

.webforms-customer-account-form {
    margin-top: 3rem;
    border: 1px solid #eee;
    padding: 2rem;
}

.webforms-customer-account-message{
    background:rgba(255, 212, 96, 0.26);
    padding:3rem;
}

.block-webforms-form .actions-toolbar{
    margin: 17px 0;
}

/* Results */

.webforms-results-block{
    padding-bottom: 10px;
    margin-bottom: 10px;
    border-bottom: 1px dotted #cccccc;
    float:left;
    width:100%;
    clear:both;
}
.webforms-results-field{
    margin-bottom:5px;
    width:100%;
}
.webforms-results-label{
    font-weight: bold;
    width:100%;
}
.webforms-results-value{
    padding:5px 0;
}

/* Star rating */

.stars {
    padding:0 5px;
    width:80px;
    display: inline;
    float: left;
}

.stars-bar {
    list-style:none;
    margin: 0px;
    padding:0px !important;
    height: 16px;
    position: relative;
    background: url(../images/results_stars.png) top left repeat-x;
}

.stars-bar li{
    padding:0px;
    margin:0px;
    /*\*/
    float: left;
    /* */
}

.stars-bar li.stars-value {
    background: url(../images/results_stars.png) left bottom;
    position: absolute;
    height: 16px;
    display: block;
    z-index: 1;
}

.webforms-rating-summary {
    margin-bottom:15px;
}

.webforms-results-table .col.id {width:15%;}




.opentip-container,
.opentip-container * {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}
.opentip-container {
    position: absolute;
    max-width: 300px;
    z-index: 100;
    -webkit-transition: -webkit-transform 1s ease-in-out;
    -moz-transition: -moz-transform 1s ease-in-out;
    -o-transition: -o-transform 1s ease-in-out;
    -ms-transition: -ms-transform 1s ease-in-out;
    transition: transform 1s ease-in-out;
    pointer-events: none;
    -webkit-transform: translateX(0) translateY(0);
    -moz-transform: translateX(0) translateY(0);
    -o-transform: translateX(0) translateY(0);
    -ms-transform: translateX(0) translateY(0);
    transform: translateX(0) translateY(0);
}
.opentip-container.ot-fixed.ot-hidden.stem-top.stem-center,
.opentip-container.ot-fixed.ot-going-to-show.stem-top.stem-center,
.opentip-container.ot-fixed.ot-hiding.stem-top.stem-center {
    -webkit-transform: translateY(-5px);
    -moz-transform: translateY(-5px);
    -o-transform: translateY(-5px);
    -ms-transform: translateY(-5px);
    transform: translateY(-5px);
}
.opentip-container.ot-fixed.ot-hidden.stem-top.stem-right,
.opentip-container.ot-fixed.ot-going-to-show.stem-top.stem-right,
.opentip-container.ot-fixed.ot-hiding.stem-top.stem-right {
    -webkit-transform: translateY(-5px) translateX(5px);
    -moz-transform: translateY(-5px) translateX(5px);
    -o-transform: translateY(-5px) translateX(5px);
    -ms-transform: translateY(-5px) translateX(5px);
    transform: translateY(-5px) translateX(5px);
}
.opentip-container.ot-fixed.ot-hidden.stem-middle.stem-right,
.opentip-container.ot-fixed.ot-going-to-show.stem-middle.stem-right,
.opentip-container.ot-fixed.ot-hiding.stem-middle.stem-right {
    -webkit-transform: translateX(5px);
    -moz-transform: translateX(5px);
    -o-transform: translateX(5px);
    -ms-transform: translateX(5px);
    transform: translateX(5px);
}
.opentip-container.ot-fixed.ot-hidden.stem-bottom.stem-right,
.opentip-container.ot-fixed.ot-going-to-show.stem-bottom.stem-right,
.opentip-container.ot-fixed.ot-hiding.stem-bottom.stem-right {
    -webkit-transform: translateY(5px) translateX(5px);
    -moz-transform: translateY(5px) translateX(5px);
    -o-transform: translateY(5px) translateX(5px);
    -ms-transform: translateY(5px) translateX(5px);
    transform: translateY(5px) translateX(5px);
}
.opentip-container.ot-fixed.ot-hidden.stem-bottom.stem-center,
.opentip-container.ot-fixed.ot-going-to-show.stem-bottom.stem-center,
.opentip-container.ot-fixed.ot-hiding.stem-bottom.stem-center {
    -webkit-transform: translateY(5px);
    -moz-transform: translateY(5px);
    -o-transform: translateY(5px);
    -ms-transform: translateY(5px);
    transform: translateY(5px);
}
.opentip-container.ot-fixed.ot-hidden.stem-bottom.stem-left,
.opentip-container.ot-fixed.ot-going-to-show.stem-bottom.stem-left,
.opentip-container.ot-fixed.ot-hiding.stem-bottom.stem-left {
    -webkit-transform: translateY(5px) translateX(-5px);
    -moz-transform: translateY(5px) translateX(-5px);
    -o-transform: translateY(5px) translateX(-5px);
    -ms-transform: translateY(5px) translateX(-5px);
    transform: translateY(5px) translateX(-5px);
}
.opentip-container.ot-fixed.ot-hidden.stem-middle.stem-left,
.opentip-container.ot-fixed.ot-going-to-show.stem-middle.stem-left,
.opentip-container.ot-fixed.ot-hiding.stem-middle.stem-left {
    -webkit-transform: translateX(-5px);
    -moz-transform: translateX(-5px);
    -o-transform: translateX(-5px);
    -ms-transform: translateX(-5px);
    transform: translateX(-5px);
}
.opentip-container.ot-fixed.ot-hidden.stem-top.stem-left,
.opentip-container.ot-fixed.ot-going-to-show.stem-top.stem-left,
.opentip-container.ot-fixed.ot-hiding.stem-top.stem-left {
    -webkit-transform: translateY(-5px) translateX(-5px);
    -moz-transform: translateY(-5px) translateX(-5px);
    -o-transform: translateY(-5px) translateX(-5px);
    -ms-transform: translateY(-5px) translateX(-5px);
    transform: translateY(-5px) translateX(-5px);
}
.opentip-container.ot-fixed .opentip {
    pointer-events: auto;
}
.opentip-container.ot-hidden {
    display: none;
}
.opentip-container .opentip {
    position: relative;
    font-size: 13px;
    line-height: 120%;
    padding: 9px 14px;
    color: #4f4b47;
    text-shadow: -1px -1px 0px rgba(255,255,255,0.2);
}
.opentip-container .opentip .header {
    margin: 0;
    padding: 0;
}
.opentip-container .opentip .ot-close {
    pointer-events: auto;
    display: block;
    position: absolute;
    top: -12px;
    left: 60px;
    color: rgba(0,0,0,0.5);
    background: rgba(0,0,0,0);
    text-decoration: none;
}
.opentip-container .opentip .ot-close span {
    display: none;
}
.opentip-container .opentip .ot-loading-indicator {
    display: none;
}
.opentip-container.ot-loading .ot-loading-indicator {
    width: 30px;
    height: 30px;
    font-size: 30px;
    line-height: 30px;
    font-weight: bold;
    display: block;
}
.opentip-container.ot-loading .ot-loading-indicator span {
    display: block;
    -webkit-animation: otloading 2s linear infinite;
    -moz-animation: otloading 2s linear infinite;
    -o-animation: otloading 2s linear infinite;
    -ms-animation: otloading 2s linear infinite;
    animation: otloading 2s linear infinite;
    text-align: center;
}
.opentip-container.style-dark .opentip,
.opentip-container.style-alert .opentip {
    color: #f8f8f8;
    text-shadow: 1px 1px 0px rgba(0,0,0,0.2);
}
.opentip-container.style-glass .opentip {
    padding: 15px 25px;
    color: #317cc5;
    text-shadow: 1px 1px 8px rgba(0,94,153,0.3);
}
.opentip-container.ot-hide-effect-fade {
    -webkit-transition: -webkit-transform 0.5s ease-in-out, opacity 1s ease-in-out;
    -moz-transition: -moz-transform 0.5s ease-in-out, opacity 1s ease-in-out;
    -o-transition: -o-transform 0.5s ease-in-out, opacity 1s ease-in-out;
    -ms-transition: -ms-transform 0.5s ease-in-out, opacity 1s ease-in-out;
    transition: transform 0.5s ease-in-out, opacity 1s ease-in-out;
    opacity: 1;
    -ms-filter: none;
    filter: none;
}
.opentip-container.ot-hide-effect-fade.ot-hiding {
    opacity: 0;
    filter: alpha(opacity=0);
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
}
.opentip-container.ot-show-effect-appear.ot-going-to-show,
.opentip-container.ot-show-effect-appear.ot-showing {
    -webkit-transition: -webkit-transform 0.5s ease-in-out, opacity 1s ease-in-out;
    -moz-transition: -moz-transform 0.5s ease-in-out, opacity 1s ease-in-out;
    -o-transition: -o-transform 0.5s ease-in-out, opacity 1s ease-in-out;
    -ms-transition: -ms-transform 0.5s ease-in-out, opacity 1s ease-in-out;
    transition: transform 0.5s ease-in-out, opacity 1s ease-in-out;
}
.opentip-container.ot-show-effect-appear.ot-going-to-show {
    opacity: 0;
    filter: alpha(opacity=0);
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
}
.opentip-container.ot-show-effect-appear.ot-showing {
    opacity: 1;
    -ms-filter: none;
    filter: none;
}
.opentip-container.ot-show-effect-appear.ot-visible {
    opacity: 1;
    -ms-filter: none;
    filter: none;
}
@-moz-keyframes otloading {
    0% {
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@-webkit-keyframes otloading {
    0% {
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@-o-keyframes otloading {
    0% {
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@-ms-keyframes otloading {
    0% {
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@keyframes otloading {
    0% {
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
