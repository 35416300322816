//
//  Variables
//  _____________________________________________

$minicart__border-color      : $color-gray80;
$minicart__padding-horizontal: $indent__base;

$minicart-qty__height        : 24px;

//
//  Minicart
//  ---------------------------------------------

.block-minicart {
    .items-total {
        float: left;
        width: 100%;
        margin: 0 $indent__s;

        .count {
            font-weight: $font-weight__bold;
        }
    }

    .subtotal {
        margin: 0 $indent__s;
        text-align: left;

        .label {
            @extend .abs-colon;
        }
    }

    .amount {
        .price-wrapper {
            &:first-child {
                .price {
                    font-size: $font-size__l;
                    font-weight: $font-weight__bold;
                }
            }
        }
    }

    .subtitle {
        display: none;

        &.empty {
            display: block;
            font-size: 14px;
            padding: $indent__l 0 $indent__base;
            text-align: center;
        }
    }

    .text {
        &.empty {
            text-align: center;
        }
    }

    .block-content {
        > .actions {
            margin-top: 15px;
            text-align: center;

            > .primary {
                margin: 0 $indent__s 15px;

                .action {
                    &.primary {
                        @extend .abs-button-l;
                        display: block;
                        margin-bottom: 15px;
                        width: 100%;
                        font-size: 16px;
                        border-radius: 5px;
                        color: #333;
                        background: $quaternary__color;
                        border-color: $quaternary__color;

                        &:last-child {
                            margin-bottom: 0;
                        }
                    }
                }
            }

            > .secondary {
                .action {
                    &.viewcart {
                        color: $secondary__color;
                        font-size: 16px;
                        text-transform: uppercase;
                        font-weight: 900;
                    }
                }
            }
        }
    }

    .block-category-link,
    .block-product-link,
    .block-cms-link,
    .block-banners {
        margin: 15px 0 0;
        text-align: center;
    }
}

.minicart-wrapper {
    @include lib-dropdown(
        $_icon-font                          : 'debondt',
        $_toggle-selector                    : '.action.showcart .icon-container',
        $_options-selector                   : '.block-minicart',
        $_dropdown-toggle-icon-content       : '\e903',
        $_dropdown-toggle-active-icon-content: '\e903',
        $_dropdown-list-item-padding         : false,
        $_dropdown-list-item-hover           : false,
        $_icon-font-position                 : before,
        $_icon-font-size                     : 40px,
        $_icon-font-line-height              : 42px,
        $_icon-font-color                    : $primary__color,
        $_icon-font-color-hover              : $primary__color,
        $_icon-font-color-active             : $primary__color
    );
    .action.showcart .icon-container {
        width: 40px;
    }
    .action.showcart .icon-container.filled:before {
        display: block;
        color: $primary__color;
        content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xml:space='preserve' viewBox='0 0 512 512'%3E%3Cpath fill='%23190262' d='M352 96V80c0-44.2-35.8-80-80-80-18 0-34.6 6-48 16-13.4-10-30-16-48-16-44.2 0-80 35.8-80 80v54.4L32 144v336h64l32 32 320-48V128l-96-32zm-80-64c26.5 0 48 21.5 48 48v20.8l-64 9.6V80c0-14.5-3.9-28.1-10.7-39.9C253 35 262.1 32 272 32zM128 80c0-26.5 21.5-48 48-48 9.9 0 19 3 26.7 8.1C195.9 51.9 192 65.5 192 80v40l-64 9.6V80z'/%3E%3C/svg%3E");
    }

    float: right;

    .block-minicart {
        padding: 25px $minicart__padding-horizontal;
        right: 0;
        width: 320px;
        box-shadow: none;

        .block-title {
            display: none;
        }

        &:after {
            left: auto;
            right: 25px;
        }

        &:before {
            left: auto;
            right: 26px;
        }
    }

    .product {
        .actions {
            float: right;
            margin: -24px 0 0;
            text-align: right;

            > .primary,
            > .secondary {
                display: inline;
            }
        }
    }

    .action {
        &.close {
            @include lib-button-icon(
                $icon-remove,
                $_icon-font-size       : 32px,
                $_icon-font-line-height: 32px,
                $_icon-font-text-hide  : true
            );
            @include lib-button-reset();
            height: 40px;
            position: absolute;
            right: 0;
            top: 0;
            width: 40px;
        }

        &.showcart {
            white-space: nowrap;
            width: 40px;

            .text {
                @extend .abs-visually-hidden;
            }

            .counter.qty {
                color: white;
                height: $minicart-qty__height;
                line-height: $minicart-qty__height;
                border-radius: 2px;
                display: inline-block;
                margin: 13px 0 0 2px;
                min-width: 25px;
                overflow: hidden;
                padding: 0 3px;
                position: absolute;
                text-align: center;
                white-space: normal;
                top: 0;
                left: 50%;
                font-weight: 600;
                transform: translateX(-50%);

                &.empty {
                    display: none;
                }

                .loader {
                    > img {
                        max-width: $minicart-qty__height;
                    }
                }
            }

            .counter-label {
                @extend .abs-visually-hidden;
            }
        }
    }

    .minicart-widgets {
        margin-top: 15px;
    }
}

.minicart-items-wrapper {
    border-top: 1px dashed $primary__color__darker;
    margin: 0 (-$minicart__padding-horizontal);
    border-left: 0;
    max-height: 285px;
    border-right: 0;
    overflow-x: auto;
    padding: 15px;
}

.minicart-items {
    @include lib-list-reset-styles();

    .product-item {
        padding: 10px 0;


        &:first-child {
            padding-top: 0;
        }

        > .product {
            @extend .abs-add-clearfix;
        }
    }

    .product-image-wrapper {
        @extend .abs-reset-image-wrapper;
    }

    .product-item-pricing {
        .label {
            display: inline-block;
            width: 4.5rem;
        }
    }

    .price-minicart {
        margin-bottom: $indent__xs;
    }

    .product {
        > .product-item-photo,
        > .product-image-container {
            float: left;
        }

        .toggle {
            @include lib-icon-font(
                $_icon-font-content    : $icon-down,
                $_icon-font-size       : 28px,
                $_icon-font-line-height: 16px,
                $_icon-font-text-hide  : false,
                $_icon-font-position   : after,
                $_icon-font-display    : block
            );
            cursor: pointer;
            position: relative;

            &:after {
                position: static;
            }
        }

        &.active {
            > .toggle {
                @include lib-icon-font-symbol(
                    $_icon-font-content : $icon-up,
                    $_icon-font-position: after
                );
            }
        }
    }

    .product-item-name {
        font-weight: 900;
        margin: 0 0 $indent__s;

        a {
            color: $primary__color__darker;
            font-size: 15px;
        }
    }



    .product-item-details {
        padding-left: 88px;

        .price-container {
            float: right;
        }

        .price {
            font-weight: 900;
            font-size: 19px;
        }

        .price-including-tax,
        .price-excluding-tax {
            margin: $indent__xs 0 0;
        }

        .weee[data-label] {
            font-size: 11px;
            .label {
                @extend .abs-no-display;
            }
        }

        .details-qty {
            margin-top: $indent__s;
        }
    }

    .product.options {
        .tooltip.toggle {
            @include lib-icon-font(
                $icon-down,
                $_icon-font-size       : 28px,
                $_icon-font-line-height: 28px,
                $_icon-font-text-hide  : true,
                $_icon-font-margin     : -3px 0 0 7px,
                $_icon-font-position   : after
            );

            .details {
                display: none;
            }
        }
    }

    .details-qty,
    .price-minicart {
        .label {
            @extend .abs-colon;
        }
    }

    .item-qty {
        margin-right: $indent__s;
        text-align: center;
        width: 40px;
    }

    .update-cart-item {
        font-size: 11px;
        vertical-align: top;
    }

    .subtitle {
        display: none;
    }

    .action {
        &.edit,
        &.delete {
            @include lib-icon-font(
                $icon-settings,
                $_icon-font-size        : 28px,
                $_icon-font-line-height : 28px,
                $_icon-font-text-hide   : true,
                $_icon-font-color       : $primary__color__darker,
                $_icon-font-color-hover : $secondary__color,
                $_icon-font-color-active: $secondary__color
            );
        }

        &.delete {
            @include lib-icon-font(
                $_icon-font: debondt,
                $_icon-font-content: "\e9ac",
                $_icon-font-size        : 20px,
                $_icon-font-line-height : 20px,
                $_icon-font-color: $primary__color__darker,
                $_icon-font-color-hover : $secondary__color,
                $_icon-font-color-active: $secondary__color
            );
        }
    }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__xs) {
    .minicart-wrapper .block-minicart {
        width: 290px;
    }
}

@include max-screen($screen__s) {
    .minicart-wrapper {
        margin-top: $indent__s;
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .minicart-wrapper {
        top: 10px;
        .block-minicart {
            right: -15px;
            width: 390px;
        }
    }
}

