//
//  Actions-toolbar
//  _____________________________________________

@mixin lib-actions-toolbar(
    $_actions-toolbar-actions-position: $actions-toolbar-actions__position,
    $_actions-toolbar-actions-reverse: $actions-toolbar-actions__reverse,
    $_actions-toolbar-margin: $actions-toolbar__margin,
    $_actions-toolbar-padding: $actions-toolbar__padding,
    $_actions-toolbar-actions-margin: $actions-toolbar-actions__margin,
    $_actions-toolbar-primary-actions-margin: $actions-toolbar-actions-primary__margin,
    $_actions-toolbar-secondary-actions-margin: $actions-toolbar-actions-secondary__margin,
    $_actions-toolbar-actions-links-margin-top: $actions-toolbar-actions-links__margin-top,
    $_actions-toolbar-primary-actions-links-margin-top: $actions-toolbar-actions-links-primary__margin-top,
    $_actions-toolbar-secondary-actions-links-margin-top: $actions-toolbar-actions-links-secondary__margin-top
) {
    @include lib-clearfix();
    margin: $_actions-toolbar-margin;
    padding: $_actions-toolbar-padding;
    clear: both;
    @include _lib-actions-toolbar-align($_actions-toolbar-actions-position);
    @include _lib-actions-toolbar-floats($_actions-toolbar-actions-position, $_actions-toolbar-actions-reverse);
    .primary,
    .secondary {
        display: inline-block;

        .action {
            margin: $_actions-toolbar-actions-margin;
        }

        a.action {
            display: inline-block;
            margin-top: $_actions-toolbar-actions-links-margin-top;
        }
    }

    .primary {
        .action {
            background: none;
            border: 1px solid $secondary__color;
            background: $secondary__color;
            line-height: 50px;
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
            border-radius: 5px;
            font-size: 16px;
            padding: 0 33px;
            color: #333;
            margin-top: 0;
        }
    }

    .secondary {
        .action {
            background: none;
            border: 1px solid $tertiary__color;
            background: $tertiary__color;
            line-height: 50px;
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
            border-radius: 5px;
            font-size: 16px;
            padding: 0 33px;
            color: #fff;
            margin-top: 20px;
        }
    }
}

//
//  Actions-toolbar-clear-floats
//  ---------------------------------------------

@mixin lib-actions-toolbar-clear-floats() {
    .primary,
    .secondary {
        display: block;
        float: none;
    }
}

//
//  Internal use mixins
//  ---------------------------------------------

@mixin _lib-actions-toolbar-align($_actions-toolbar-actions-position) {
    @if $_actions-toolbar-actions-position != justify {
        text-align: $_actions-toolbar-actions-position;
    }
}

@mixin _lib-actions-toolbar-floats(
    $_actions-toolbar-actions-position,
    $_actions-toolbar-actions-reverse
) {
    @if $_actions-toolbar-actions-position == justify {
        @if $_actions-toolbar-actions-reverse {
            .primary {
                float: right;
            }
            .secondary {
                float: left;
            }
        }
        @else {
            .primary {
                float: left;
            }
            .secondary {
                float: right;
            }
        }
    }
    @else if $_actions-toolbar-actions-position == left {
        @if $_actions-toolbar-actions-reverse {
            .secondary {
                float: left;
            }
        }
        @else {
            .primary {
                float: left;
            }
        }
    }
    @else if $_actions-toolbar-actions-position == right {
        @if $_actions-toolbar-actions-reverse {
            .primary {
                float: right;
            }
        }
        @else {
            .secondary {
                float: right;
            }
        }
    }
    @else if $_actions-toolbar-actions-position == center {
        .primary,
        .secondary {
            vertical-align: top;
        }
    }
}
