//
//  Variables
//  _____________________________________________

$active-nav-indent: 90px;

//
//  Common
//  _____________________________________________

.panel.header {
    @include max-screen(768px) {
        display: none;
    }

    .links,
    .switcher {
        display: inline-block;
        float: right;
        //margin-left: 20px;

        [class^="view-"] {
            span {
                font-size: 0;
            }

            a {
                display: block;
                height: 20px;
                min-width: 150px;
                line-height: 20px;
            }
        }
    }
}

.nav-sections {
    background: $primary__color;

    @include max-screen(768px) {
        .page-header & .switcher {
            display: block;
            font-size: 14px;
            padding: 0;
            margin: 0;

            .options.actions .switcher-trigger.action.toggle {
                width: 100%;
                padding: 15px;
                box-sizing: border-box;

            }

            [class^="view-"] {
                span {
                    font-size: 18px;
                    line-height: 18px;
                }

                a {
                    display: block;
                    height: 20px;
                    min-width: 150px;
                    line-height: 20px;
                }
            }

            .switcher-trigger strong:after {
                top: 0;
                right: 0;
                line-height: 18px;
            }

            .options ul.dropdown {
                background: transparent;
                border-width: 0;
                margin-top: 0;
                position: relative;
                box-shadow: none;

                a {
                    padding: 0;
                    color: $primary__color__darker;
                    border-top: 1px solid #d1d1d1;
                    font-size: 18px;
                    line-height: 16px;
                    padding: 15px;
                }

                &:after,
                &:before {
                    content: none;
                }

                li:hover {
                    background: transparent;
                }
            }

            .options .action.toggle.active:before,
            .options .action.toggle:before {
                content: none;
            }

        }
    }
}

.nav-toggle {
    @include lib-icon-font(
            $icon-menu,
        $_icon-font: 'debondt',
        $_icon-font-size: 38px,
        $_icon-font-color: $primary__color__darker,
        $_icon-font-color-hover: $primary__color__darker
    );
    @include lib-icon-text-hide();
    cursor: pointer;
    display: block;
    font-size: 0;
    left: 20px;
    position: absolute;
    top: 35px;
    z-index: 14;

    &:before {
        content: '\e90f'
    }

    @media only screen and (max-width: 440px) {
        top: 30px;
    }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__m) {
    .navigation {
        padding: 0;

        .parent {
            .level-top {
                position: relative;
                @include lib-icon-font(
                    $_icon-font-content: $icon-down,
                    $_icon-font-size: 42px,
                    $_icon-font-position: after,
                    $_icon-font-display: block
                );

                &:after {
                    position: absolute;
                    right: 7px;
                    top: -8px;
                }

                &.ui-state-active {
                    @include lib-icon-font-symbol(
                        $_icon-font-content: $icon-up,
                        $_icon-font-position: after
                    );
                }
            }
        }
    }

    .nav-sections {
        -webkit-overflow-scrolling: touch;
        transition: left 0.3s;
        height: 100%;
        left: -80%;
        left: calc(-1 * (100% - #{$active-nav-indent}));
        overflow: auto;
        position: fixed;
        top: 0;
        width: 80%;
        width: calc(100% - #{$active-nav-indent});

        .switcher {
            border-top: 1px solid $color-gray82;
            font-size: 1.6rem;
            font-weight: 700;
            margin: 0;
            padding: 0.8rem 3.5rem 0.8rem 2rem;

            .label {
                display: block;
                margin-bottom: $indent__xs;
            }

            &:last-child {
                border-bottom: 1px solid $color-gray82;
            }
        }

        .switcher-trigger {
            strong {
                display: flex;
                position: relative;
                @include lib-icon-font(
                    $_icon-font-content: $icon-down,
                    $_icon-font-size: 42px,
                    $_icon-font-position: after,
                    $_icon-font-display: block
                );

                &:after {
                    position: absolute;
                    right: -40px;
                    top: -16px;
                }
            }

            &.active strong {
                @include lib-icon-font-symbol(
                    $_icon-font-content: $icon-up,
                    $_icon-font-position: after
                );
            }
        }

        .switcher-dropdown {
            @include lib-list-reset-styles();
            padding: $indent__s 0;
        }

        .header.links {
            @include lib-list-reset-styles();
            border-bottom: 1px solid $color-gray82;

            li {
                font-size: 1.6rem;
                margin: 0;

                &.greet.welcome {
                    border-top: 1px solid $color-gray82;
                    font-weight: 700;
                    padding: 0.8rem $indent__base;
                }

                & > a {
                    border-top: 1px solid $color-gray82;
                }
            }

            a,
            a:hover {
                color: $navigation-level0-item__color;
                text-decoration: $navigation-level0-item__text-decoration;
                display: block;
                font-weight: 700;
                padding: 0.8rem $indent__base;
            }

            .header.links {
                border: 0;
            }
        }
    }

    .nav-before-open {
        height: 100%;
        overflow-x: hidden;
        width: 100%;

        .page-wrapper {
            transition: left 0.3s;
            height: 100%;
            left: 0;
            overflow: hidden;
            position: relative;
        }

        body {
            height: 100%;
            overflow: hidden;
            position: relative;
            width: 100%;
        }
    }

    .nav-open {

        ul.header.links {
            border-bottom: 0px;

            li.toplink-usp {
                display: none;
            }

            li {

                a {
                    text-transform: uppercase;
                    color: $primary__color__darker;
                    font-weight: 900;
                    font-size: 24px;
                    letter-spacing: $default__letter-spacing;
                }


                &:hover a {
                    color: $secondary__color;
                    font-weight: 900;
                }
            }

        }

        .mini-login-responsive {
            margin: 0 20px;

            .actions-toolbar {
                text-align: right;

                button.primary {
                    background: $secondary__color;
                    border-color: $secondary__color__dark;
                    text-transform: uppercase;
                    font-size: 19px;
                    height: 50px;
                    min-width: 180px;

                    &:hover {
                        background: lighten($secondary__color, 5%)
                    }
                }
            }

            .forgot-links {
                margin-top: 30px;

                a {
                    display: block;
                    line-height: 24px;
                    font-size: 18px;
                    margin-top: 15px;
                }
            }
        }

        .navigation {
            .level0 {
                &.active {
                    > a:not(.ui-state-active) {
                        border-width: 0;

                        span:not(.ui-menu-icon) {
                            margin-left: 0;
                        }
                    }
                }
            }
        }

        .ves-menu {
            .ves-megamenu {
                background: none;
                padding: 0;
            }
        }

        .page-wrapper {
            left: 80%;
            left: calc(100% - #{$active-nav-indent});
        }

        .nav-sections {
            $_shadow: 0 0 5px 0 rgba(0, 0, 0, 0.8);
            background: #fff;
            box-shadow: $_shadow;
            left: 0;
            z-index: 99;
        }

        .nav-toggle {
            &:before {
                color: #fff;
                z-index: 2;
                position: absolute;
                content: "\e5cd";
            }

            &:after {
                background: rgba(58, 56, 70, 0.8);
                content: '';
                display: block;
                height: 100%;
                position: fixed;
                right: 0;
                top: 0;
                width: 100%;
                z-index: 1;
            }
        }
    }

    .nav-sections-items {
        @include lib-clearfix();
        position: relative;
        z-index: 1;
    }

    .nav-sections-item-title {
        background: $primary__color;
        border: none;
        box-sizing: border-box;
        float: left;
        height: 71px;

        padding-top: 24px;
        text-align: center;
        width: 33.333%;

        a {
            color: #fff;
            font-size: 16px;
            font-weight: 900;
        }

        &.active {
            background: transparent;
            border-bottom: 0;

            a {
                color: $primary__color__darker;
            }
        }

        .nav-sections-item-switch {
            &:hover {
                text-decoration: none;
            }
        }
    }

    .nav-sections-item-content {
        @include lib-clearfix();
        box-sizing: border-box;
        float: right;
        margin-left: -100%;
        margin-top: 71px;
        width: 100%;

        &.active {
            display: block;
        }

        padding: $indent__m 0;
    }

    @include lib-main-navigation();
}

//
//  Desktop
//  _____________________________________________

@include min-screen(769px) {
    .nav-toggle {
        display: none;
    }

    .nav-sections {
        flex-shrink: 0;
        flex-basis: auto;
        margin-bottom: 0;
    }

    .nav-sections-item-title {
        display: none;
    }

    .nav-sections-item-content {
        display: block !important;
    }

    .nav-sections-item-content > * {
        display: none;
    }

    .nav-sections-item-content {
        > .navigation {
            display: block;
        }
    }

    @include lib-main-navigation-desktop(
        $_nav-level0-item-color: $primary__color__darker,
        $_nav-level0-item-border-hover: $primary__color__darker,
        $_nav-font-size: 20px
    );

    .navigation {
        height: 38px;
        position: static;
        margin-top: 0;

        ul {
            position: static;

        }

        li {
            &.level0 {
                margin: 0 40px 0 0;

                a {
                    color: #fff;
                    text-transform: uppercase;
                    font-weight: 900;
                    text-decoration: none;
                    display: inline-block;
                    line-height: 36px;
                    border-bottom: 2px solid transparent;

                    &:hover {
                        text-decoration: none;
                    }
                }

                &.menu-open {
                    a {
                        border-bottom-color: #fff;
                    }
                }


                .submenu {
                    a {
                        color: $primary__color;
                        padding-left: 10px;
                        text-transform: none;
                        font-size: 14px;
                        line-height: 25px;
                        font-weight: normal;
                    }

                    .submenu {
                        left: 0 !important;
                        display: block;
                        position: relative;

                        a {
                            font-size: 18px;
                            font-weight: 400;
                            text-transform: none;
                        }

                    }

                    .active > a {
                        border-color: $primary__color__darker;
                        padding-left: 7px;
                    }
                }
            }

            &.active a {
                border-color: #fff;
            }
        }
    }

    .panel.header {
        .links {
            display: inline-block;
        }

        .switcher {
            //display: none;
        }

    }
}

@include max-screen(1244px) {
    .navigation {
        li {
            &.level0 {
                margin: 0 50px 0 0;
            }
        }
    }
}

@include max-screen(1004px) {
    .navigation {
        li {
            &.level0 {
                margin: 0 30px 0 0;
            }
        }
    }
}

@include max-screen(884px) {
    .navigation {
        font-size: 17px;

        li {
            &.level0 {
                margin: 0 27px 0 0;
            }
        }
    }
}

.flag-drop-down {
    float: left;
    margin-right: 5px;
}

@media only screen and (min-width: 769px) {
    .navigation li.level0 .submenu .menu-title a {
        padding-left: 0;
    }
}

@media only screen and (max-width: 1023px) {
    .ves-megamenu img {
        display: none;
    }
}

@media only screen and (max-width: 768px) {
    .ves-megamenu .menu-title {
        display: none;
    }
}
