
.scan_invoer-index-index {
    #scanner-input {
        height: 0;
        width: 0;
        border: 0;
        position: absolute;
        overflow: hidden;
        box-shadow: none;
    }

    #scanner-input:checked + .focus-field {
        .not-focused {
            display: none;
        }

        .focused {
            display: block;
            background: #75d57e;
            border-color: #64b56c;
        }
    }

    #scanner-input:not(:checked) + .focus-field {
        .focused {
            display: none;
        }

        .not-focused {
            display: block;
            background: #ff9d67;
            border-color: #d67e4e;
        }
    }

    .focus-field {
        margin-top: 30px;
        display: inline-block;
        position: relative;
        left: 50%;
        transform: translateX(-50%);

        > div {
            width: 200px;
            height: 100px;
            border-radius: 10px;
            padding: 20px;
            box-sizing: border-box;
            text-align: center;
            color: #fff;
            border: 2px solid;

            > div {
                position: relative;
                top: 50%;
                transform: translateY(-50%);

                > div {
                    font-size: 18px;
                    font-weight: 900;
                    text-transform: uppercase;
                }
            }
        }
    }

    .focus-text {
        font-size: 18px;
        font-weight: 700;
        margin-top: 20px;
        text-align: center;
    }

    .scanner-status {
        position: fixed;
        margin-top: auto;
        background: white;
        top: 10px;
        left: 10px;
        color: #fff;
        width: calc(100% - 20px);
        box-sizing: border-box;
        padding: 20px;
        z-index: 99999;
        display: none;
        font-size: 15px;
        box-shadow: 0 0 4px rgba(0, 0, 0, 0.4);

        &.error {
            display: block;
            background: red;
        }

        &.warning {
            display: block;
            background: #ff9d67;
        }
    }

    .scanner-status span {
        display: block;
        text-align: center;
    }

    #status-close {
        float: right;
        cursor: pointer;
    }

    .scanner-cart-products {
        background: #333;
        border-top: 5px solid #333;
        padding: 20px;
        margin-top: 20px;
        color: #fff;
        font-size: 15px;

        td {
            vertical-align: center;
        }

        button {
            touch-action: manipulation;
        }

        .amount {
            font-weight: bolder;
        }
        .sku {
            color: #75d57e;
        }
        .barcode-not-found {
            color: #f66;
        }
        .name {
            color: #fff;
        }
        .qty {
            display: inline-block;
            color: #ffffff;

            &.highlight {
                background-color: #a2a2a2;
            }
        }
        .error {
            color: #ff0000;
        }
        .ok {
            color: #75d57e;
        }
        .warning {
            color: orange;
        }
        .item-message {
            font-style: italic;
        }
        .back-in-stock {
            font-weight: bold;
            color: #b0adff;
            cursor: pointer;
        }

        li {
            transition: background-color 0.5s;
        }
    }

    #interactive.viewport {
        position: relative;
    }

    #interactive.viewport > canvas, #interactive.viewport > video {
        max-width: 100%;
        width: 100%;
    }

    canvas.drawing, canvas.drawingBuffer {
        position: absolute;
        left: 0;
        top: 0;
    }

    @media only screen and (max-width: 768px) {

        h2 {
            font-size: 16px;
        }

        .header-main-usp,
        .block-search {
            display: none;
        }



        .scanner-cart-products {
            display: block;
            box-sizing: border-box;

            tbody {
                display: block;
                box-sizing: border-box;

                tr{
                    display: grid;
                    grid-template-columns: 0 calc(57% - 66px) calc(43% - 66px) 66px 66px;
                    grid-template-rows: 50% 50%;

                    td:nth-child(3) {
                        grid-row-start: 2;
                        grid-row-end: 2;
                        grid-column-start: 1;
                        grid-column-end: 6;
                        padding-top: 5px;
                    }

                    td:last-child {
                        display: none;
                    }

                    .right,
                    .qty,
                    .sku {
                        padding-bottom: 2px;
                    }

                    td {
                        box-sizing: border-box;
                    }
                }
            }
        }

        #interactive.viewport {
            width: 100%;
            height: auto;
            overflow: hidden;
        }

    }

    @media only screen and (min-width: 769px) {
        .scanner-cart-products td {
            &:last-child,
            &.image {
                display: none;
            }
        }
    }

}
