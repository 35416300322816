//
//  Variables
//  _____________________________________________

$autocomplete__background-color : $color-white;
$autocomplete__border           : 1px solid $form-element-input__border-color;
$autocomplete-item__border      : 1px solid $color-gray90;
$autocomplete-item__hover__color: $color-gray91;
$autocomplete-item-amount__color: $color-gray60;

//
//  Common
//  _____________________________________________

.block-search {
    margin-bottom: 0;

    input {
        height: 32px;
        padding-left: 15px;

        &::-webkit-input-placeholder,
        &:-ms-input-placeholder,
        &:-moz-placeholder,
        &::-moz-placeholder {
            color: $primary__color;
            font-size: 12px;
        }
    }

    .block {
        &-title {
            display: none;
        }
    }

    .block-content {
        margin-bottom: 0;
    }

    .label {
        @include lib-icon-font(
            $_icon-font-content     : $icon-search,
            $_icon-font-size        : 35px,
            $_icon-font-line-height : 33px,
            $_icon-font-color       : $minicart-icons-color,
            $_icon-font-color-hover : $minicart-icons-color-hover,
            $_icon-font-color-active: $minicart-icons-color-hover,
            $_icon-font-text-hide   : true,
            $_icon-font-display     : block
        );
        float: right;

        &.active {
            + .control {
                input {
                    position: static;
                }
            }
        }
    }

    .action.search {
        display: none;
    }

    .control {
        clear: both;
        margin: 0 (-$layout__width-xs-indent) -1px;
        padding: 0;
    }

    .nested {
        display: none;
    }
}

.search-autocomplete {
    @extend .abs-add-box-sizing;
    display: none;
    margin-top: -15px;
    overflow: hidden;
    position: absolute;
    z-index: 3;

    ul {
        @include lib-list-reset-styles();

        li {
            border-top: $autocomplete-item__border;
            cursor: pointer;
            margin: 0;
            padding: $indent__xs $indent__xl $indent__xs $indent__s;
            position: relative;
            text-align: left;
            white-space: normal;

            &:not(:empty) {
                border-top: 0;
                border: $autocomplete__border;
                background: $autocomplete__background-color;
            }

            &:first-child {
                border-top: none;
            }

            &:hover,
            &.selected {
                background: $autocomplete-item__hover__color;
            }

            .amount {
                color: $autocomplete-item-amount__color;
                position: absolute;
                right: 7px;
                top: $indent__xs;
            }
        }
    }
}

.form.search.advanced {
    .fields.range {
        .field {
            &:first-child {
                position: relative;

                .control {
                    padding-right: 25px;

                    &:after {
                        content: ' \2013 ';
                        display: inline-block;
                        position: absolute;
                        right: 0;
                        text-align: center;
                        top: 6px;
                        width: 25px;
                    }
                }
            }

            &:last-child {
                position: relative;

                div.mage-error[generated] {
                    left: 0;
                    position: absolute;
                    top: 32px;
                }
            }

            &.with-addon {
                .control {
                    padding-right: 45px;
                }
            }
        }
    }

    .group.price {
        .addon {
            .addafter {
                background: none;
                border: 0;
                padding-top: 6px;
                position: absolute;
                right: 0;
                top: 0;
            }
        }
    }
}

.search.summary {
    margin-bottom: $indent__s;
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__m) {
    .block-search {
        float: left;
        margin-top: 0;
        position: relative;
        .block-content {
            margin-bottom: 0;
        }

        input {
            margin: 10px 20px 5px;
            width: calc(100% - 40px);
        }

        .label {
            right: 10px;
            top: 10px;

            &:before {
                font-family: 'debondt';
                content: "\e902";
                font-size: 18px;
                color: $primary__color;

                &:hover {
                    color: $primary__color__darker;
                }
            }
        }

        .label {
            position: absolute;
        }
    }
}

@include max-screen($screen__s) {
    .block-search {
        margin-top: $indent__s;
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen(769px) {
    .block-search {
        float: right;
        padding-left: 15px;
        position: relative;
        margin-top: 15px;
        width: 383px;
        z-index: 4;
        margin-right: 40px;

        .control {
            border-top: 0;
            margin: 0;
            padding: 0;
        }

        .nested {
            display: block;
            padding-top: 5px;
            position: absolute;
        }

        input {
            @include lib-input-placeholder() {
                color: $primary__color__darker;
            }
            margin: 0;
            padding-right: 35px;
            color: $primary__color;
            position: static;
        }

        .action.search {
            @include lib-button-reset();
            padding: 0;
            position: absolute;
            right: 0px;
            top: 0;
            z-index: 1;
            opacity: 1!important;
            border-left: 1px solid $primary__color;
            display: inline-block;
            font-size: 0;
            height: 32px;
            width: 36px;
            text-align: center;

            &:before {
              font-family: 'debondt';
              content: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 576 576'%3E%3Cpath fill='%23190262' d='M224.369 96c70.172 0 127.266 57.422 127.266 128 0 26.578-8.156 52.164-23.594 73.984l-12.531 17.696-17.594 12.586c-21.687 15.531-47.125 23.734-73.547 23.734-70.188 0-127.266-57.422-127.266-128s57.079-128 127.266-128zM224.369 32c-105.437 0-190.906 85.961-190.906 192s85.469 192 190.906 192c41.203 0 79.25-13.258 110.438-35.586l153.312 154.195c6.234 6.266 14.391 9.391 22.547 9.391s16.312-3.125 22.531-9.391c12.453-12.518 12.453-32.812 0-45.328l-153.312-154.195c22.203-31.375 35.375-69.648 35.375-111.086 0-106.039-85.469-192-190.891-192v0z'%3E%3C/path%3E%3C/svg%3E%0A");
                position: relative;
                top: 6px;
                left: -2px;
              color: $primary__color;

            }

            &:focus {
                &:before {
                    color: $color-gray20;
                }
            }
        }
    }

    .search-autocomplete {
        margin-top: 0;
    }
}

@media only screen and (min-width: 768px) {
    .block-search .label {
        border: 0;
        clip: rect(0,0,0,0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
    }
}

#modes-label {
    display: none;
}
