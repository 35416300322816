.page-layout-account-aanvraag {
    .page-main {
        overflow: hidden;

        .account-aanvraag-block {
            width: 40%;
            float: left;
            background: $primary__color__lighter;
            border: 1px solid $primary__color;
            box-sizing: border-box;
            border-radius: 3px;
            padding: 20px;

            @include max-screen(850px) {
                width: 100%;
                margin-bottom: 40px;
            }

            h3 {
                font-family: $font-family__base;
                letter-spacing: $default__letter-spacing;
                font-size: 20px;
                font-weight: 900;
                text-transform: uppercase;
                margin-bottom: 20px;

                span {
                    font-weight:100;
                }

            }

            p {
                font-size: 18px;
                line-height: 24px;
            }
        }
    }
    .webforms {
        box-sizing: border-box;
        float: left;
        width: 60%;
        padding-right: 50px;

        @include max-screen(450px) {
            margin-top: 10px;
        }

        @include max-screen(850px) {
            padding-right: 0;
            width: 100%;
        }

        form.form {
            legend,
            br  {
                display: none;
            }

            .actions-toolbar {
                .action.primary {
                    display: block;
                    float: right;
                    width: 240px;
                    height: 52px;
                    margin-top: 15px;
                    background: $secondary__color;
                    border-color: $secondary__color__dark;
                    font-size: 19px;
                    text-transform: uppercase;

                    @include max-screen(450px) {
                        width: 100%;
                    }

                    &:hover {
                        background: lighten($secondary__color, 5%);
                    }
                }
            }
        }

        .fieldset {
            margin-bottom: 0;

            > .field:not(.choice) {
                width: 100%;
                margin-bottom: 20px;

                &.webforms-fields-straatnaam {
                    width: calc(75% - 10px);
                    float: left;
                }

                &.webforms-fields-land-selector {
                    float: left;
                    width: 100%;
                }

                &.webforms-fields-huisnummer {
                    width: calc(25% - 10px);
                    margin-left: 20px;
                    float: left;
                }

                &.webforms-fields-postcode,
                &.webforms-fields-plaatsnaam,
                &.webforms-fields-voornaam,
                &.webforms-fields-achternaam {
                    width: calc(50% - 10px);
                    float: left;
                }

                &.webforms-fields-plaatsnaam,
                &.webforms-fields-achternaam {
                    margin-left: 20px;
                }

                &.webforms-fields-land-selector {
                    label {
                        display: none;
                    }
                }

                @include max-screen(450px) {
                    float: left;
                    &.webforms-fields-postcode,
                    &.webforms-fields-plaatsnaam,
                    &.webforms-fields-voornaam,
                    &.webforms-fields-achternaam,
                    &.webforms-fields-straatnaam,
                    &.webforms-fields-huisnummer {
                        margin-left: 0;
                        width: 100%;

                    }
                }

                > .control {
                    width: 100%;
                    position: relative;


                    input {
                        height: 50px;
                        width: 100%;
                    }

                    label {
                        position: absolute;
                        left: 1px;
                        top: 14px;
                        font-size: 13px;
                        line-height: 25px;
                        padding: 0 10px;
                        background: #fff;
                        transition: all 0.2s ease-out;
                        pointer-events: none;
                    }

                    input:focus ~ label,
                    input.focused ~ label {
                        top: -13px;
                        left: 20px;
                        font-weight: 700;
                    }
                }
            }
        }
    }
}