$primary__color: #1B0166;
$primary__color__dark: $primary__color;
$primary__color__darker: $primary__color;
$primary__color__darkest: $primary__color;
$primary__color__lighter: #fafafa;

$secondary__color: #FFEAC3;
$secondary__color__dark: $secondary__color;

$tertiary__color: #004939;
$quaternary__color: #F0F0F0;

$positive__confirmation__color: #54c728;
$negative__confirmation__color: #f79e20;

$_nav-level0-item__active__border-width: 0 0 7px;

$form-element-input__border-color: $primary__color;

$default__letter-spacing: -0.05em;
$buggy__letter-spacing: -0.06em;
$condensed__letter-spacing: -0.1em;
$oposit__letter-spacing: 0.05em;

$active-nav-indent : 120px;
