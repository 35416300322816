#discountContainer {
    order: -1;
    width: 100%;
    text-align: right;
    overflow: hidden;
    color: $primary__color__darker;

    @media only screen and (max-width: 768px) {
        text-align: right;
    }

    .discount-text {
        font-size: 18px;
        font-weight: 400;
        margin-right: 10px;
    }

    .discount-percentage {
        font-size: 24px;
        font-weight: 900;
        color: #54c728;
    }
}
